import {useState} from "react";
import {
    convertCamelCaseToSpaceDelimited,
    isNullOrUndefined,
    isTrimmedStringEmpty
} from "../../Utilities/commonUtilities";
import {isArrayNullOrEmpty, optionIndexInArray} from "../../Utilities/Types/arrayUtilities";
import {createListFromObject} from "../../Utilities/Types/listUtilities";
import {deepCopyObject, isObjectNullOrEmpty, objectHasProperty} from "../../Utilities/Types/objectUtilities";

import {inputStyles, otherTextSeparator, YesOrNo} from "./inputConstants";

export function useInput(initialValue) {
    const [value, setValue] = useState(initialValue);

    function handleChange(e) {
        const value = e && e.target ? e.target.value : e;
        setValue(value);
    }

    return {
        onChange: handleChange,
        setValue,
        value,
    };
}

export function useInputFormNoStateForTesting(initialValue, callbackAfterChange) {
    let items = {...initialValue};
    const setItems = (value) => items = value;
    return useInputFormAbstract(callbackAfterChange, items, setItems);
}

export function useInputForm(initialValue, callbackAfterChange) {
    const [items, setItems] = useState(deepCopyObject(initialValue));
    return useInputFormAbstract(callbackAfterChange, items, setItems);
}

export function useInputFormAbstract(callbackAfterChange, items, setItems) {

    function handleChange(e, id) {
        const name = id ? id : e.target.name;
        const item = this ? this : items[name];
        item.setValue(e.target.value);

        if (callbackAfterChange) callbackAfterChange();
    }

    function setValue(value) {
        const result = {...items};
        if (value !== result[this.name].value) {
            result[this.name].value = value;
            setItems(result);
        }
    }

    function setError(error) {
        const result = {...items};
        if (error !== result[this.name].error) {
            result[this.name].error = error;
            setItems(result);
        }
    }

    for (let property in items) {
        if (!objectHasProperty(items, property)) break;
        const item = items[property];
        if (isNullOrUndefined(item) || typeof item !== "object" || Array.isArray(item))
            items[property] = {
                value: item,
                onChange: handleChange,
                setValue,
                setError,
                name: property,
                error: "",
                label: convertCamelCaseToSpaceDelimited(property, true),
            };
    }

    return {
        ...items,
        setValue: setItems
    };
}

export const clearInputFormFields = (object) => {

    let convertedObject = convertInputFormToObject(object);

    for (let property in convertedObject) {
        if (objectHasProperty(convertedObject, property) && String(property) !== `setValue` && !isMethod(convertedObject, property)) {
            object[property].setValue("");
        }
    }
};

function isMethod(obj, propName) {
    return obj[propName] instanceof Function;
}

export const clearInputFormErrorFields = (object) => {
    for (let property in object) {
        if (objectHasProperty(object, property) && String(property) !== `setValue`) {
            object[property].setError && object[property].setError("");
        }
    }
};

export const reinitializeInputFormFields = (object, initialValues) => {
    for (let property in object) {
        if (objectHasProperty(object, property) && String(property) !== `setValue`) {
            const newValue = objectHasProperty(initialValues, property) ? initialValues[property] : "";
            if (!isObjectNullOrEmpty(object[property])) {
                object[property].setValue(newValue);
                object[property].setError("");
            }
        }
    }
};

export const reinitializeSpecificInputFormFields = (object, fieldsToReinitialize, newValue = "") => {
    Object.keys(object).forEach(property => {
        if (fieldsToReinitialize.includes(property) && object[property] && typeof object[property].setValue === 'function') {
            object[property].setValue(newValue);
            object[property].setError("");
        }
    });
};


export const inputFormIsValid = (object) => {
    let isValid = true;

    for (let property in object) {
        if (objectHasProperty(object, property)) {
            if (!isTrimmedStringEmpty(object[property].error) && isValid) {
                isValid = false;
                focusOnFormElement(property);
            }
        }
    }

    return isValid;
};

export function focusOnFormElement(elementId) {
    let element = document.getElementById(elementId);

    if (!element) {
        element = document.getElementById(`${elementId}_0`);
    }

    if (!element) {
        element = document.getElementsByClassName(elementId);
        if (!element || element.length === 0)
            element = null;
        else
            element = element[0];
    }

    if (!element)
        return;

    element.focus();
    if (element.scrollIntoView)
        element.scrollIntoView(false);
}

export function convertInputFormToObject(form) {
    let object = deepCopyObject(form);
    if (!object) return null;
    for (let property in object) {
        if (objectHasProperty(object, property)) {
            const item = object[property];

            if (objectHasProperty(item, "onChange"))
                object[property] = item.value;
        }
    }
    delete object.setValue;
    return object;
}

export function createFakeEvent(value, id, object = {}, targetObject = {}) {
    return {
        target: {...{value, id, name: id}, ...targetObject},
        ...object,
        ...{
            preventDefault: () => {
            },
        },
    };
}

export function createYesNoObject(isBoolean = true) {
    return {
        Yes: isBoolean ? "true" : YesOrNo.YES,
        No: isBoolean ? "false" : YesOrNo.NO,
    };
}

export function createIsActiveObject() {
    return {
        Yes: "true",
        No:  "false",
        All: "",
    };
}

export function createYesNoList(isBoolean = true) {
    const options = createYesNoObject(isBoolean);
    return createListFromObject(options);
}

export function createIsActiveList()
{
    const options = createIsActiveObject();
    return createListFromObject(options);
}

export function isValueInArray(array, value, wholeWord = true) {
    return optionIndexInArray(array, value, wholeWord) > -1;
}

export function removeValueInArray(array, value, wholeWord = true) {
    const index = optionIndexInArray(array, value, wholeWord);
    array.splice(index, 1);
}

export function updateCheckboxListArray(
    optionChanged,
    currentOptions,
    allOptions,
) {
    const isChecked =
        optionIndexInArray(currentOptions, optionChanged, false) > -1;
    let optionsSelected = [];

    if (isChecked) {
        optionsSelected = currentOptions.reduce((agg, option) => {
            if (!option.startsWith(optionChanged)) agg.push(option);

            return agg;
        }, []);
    } else {
        for (let i = 0; i < allOptions.length; i++) {
            if (allOptions[i] === optionChanged) optionsSelected.push(optionChanged);
            else {
                let index = optionIndexInArray(currentOptions, allOptions[i], false);
                if (index > -1) optionsSelected.push(currentOptions[index]);
            }
        }
    }

    return optionsSelected;
}

export function updateCheckboxOtherValue(
    arrayOfSelected,
    selectedValue,
    otherValue,
) {
    const index = optionIndexInArray(arrayOfSelected, selectedValue, false);
    arrayOfSelected[index] = generateOtherValue(
        selectedValue,
        otherValue,
    );

    if (isValueInArray(arrayOfSelected, selectedValue))
        removeValueInArray(arrayOfSelected, selectedValue);
}

export function generateOtherValue(
    selectedValue,
    otherValue,
    separator = otherTextSeparator,
) {
    return `${selectedValue}${separator}${otherValue}`;
}

export function appendedOtherValue(value, inputText, separator = otherTextSeparator) {
    const otherText = value.split(inputText + separator);
    if (otherText.length === 2) return otherText[1];
    return "";
}

export function appendedCheckboxOtherValue(
    arrayOfSelected,
    checkboxText,
    separator = otherTextSeparator,
) {
    const otherIndex = optionIndexInArray(
        arrayOfSelected,
        checkboxText + separator,
        false,
    );
    if (otherIndex > -1) {
        const otherText = arrayOfSelected[otherIndex].split(checkboxText + separator);
        if (otherText.length === 2) return otherText[1];
    }

    return "";
}

export function appendedCheckboxOtherValueExists(
    checkboxText,
    arrayOfSelected,
    separator = otherTextSeparator,
) {
    const otherIndex = optionIndexInArray(arrayOfSelected, checkboxText, false);
    if (otherIndex > -1) {
        const otherText = arrayOfSelected[otherIndex].split(separator);
        if (
            otherText.length === 1 ||
            (otherText.length === 2 && otherText[1].trim() === "")
        )
            return false;
    }

    return true;
}

export function getButtonClasses({
                                     className = "",
                                     isTertiary,
                                     isExpanded,
                                     isLarge,
                                     isLink,
                                     isPrimary,
                                     isRibbon,
                                     buttonType,
                                     notCentered
                                 }) {
    if (isLink)
        return inputStyles.button.isLink;

    className += inputStyles.button.theme;
    className += isRibbon ? inputStyles.button.isRibbon : "";
    className += isLarge ? inputStyles.button.isLarge : "";
    className += isPrimary ? inputStyles.button.isPrimary :
        isTertiary ? inputStyles.button.isTertiary : inputStyles.button.isSecondary;
    className += isExpanded ? inputStyles.button.isExpanded : inputStyles.button.isCollapsed;
    className += buttonType || "";
    className += notCentered ? inputStyles.button.notCentered : "";

    return className;
}

export function isValidEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}


export function checkAndSetError(fields, message) {
    if (isArrayNullOrEmpty(fields)) return;
    fields.forEach((field) => {
        if (isObjectNullOrEmpty(field)) return;
        const fieldLabel = field.label?.toString() || "Field";
        const fieldValue = field.value?.toString() || "";
        const isFieldEmpty = isTrimmedStringEmpty(fieldValue);
        if (isFieldEmpty && !message) {
            field.setError(`${fieldLabel} is required.`);
        } else if (isFieldEmpty && message) {
            field.setError(message);
        } else if (field.name === "email" && !isValidEmail(fieldValue)) {
            field.setError("Email is not valid.");
        }
    });
}