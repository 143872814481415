import {config, mockResolveApiCall, requestOptions, requestTypes, sendRequest} from "../../Utilities/Api";
import {studentData} from "./studentMockData";

class ServerStudentApi {
    static getStudentDetails(studentId) {
        return sendRequest(
            requestOptions(config.URL + `students/${studentId}`,
                requestTypes.GET,
            ),
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************

class MockStudentApi {
    static getStudentDetails() {
        return mockResolveApiCall( studentData[0], "");
    }

}

const StudentApi = config.environment.MODE === config.modes.SERVER ? ServerStudentApi : MockStudentApi;
export default StudentApi;