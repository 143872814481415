import PropTypes from "prop-types";
import React from "react";
import { getButtonClasses } from "../Inputs/inputUtility";

export const ExternalLink = (props) => <ExternalLinkAdapter {...props} />;

export const EmailLink = (props) => {
    const { className, label, address } = props;
    const classes = className ? className : undefined;
    const newLabel = label ? label : address;

    return <ExternalLinkAdapter url={`mailto:${address}`} label={newLabel} opensInNewTab={false} hasNoIcon
        className={classes} />;
};

export const ExternalLinkButton = (props) => {
    let classes = getButtonClasses(props);

    return <ExternalLinkAdapter {...props} className={classes} />;
};
export const ExternalTertiaryLinkButton = (props) => {
    let classes = getButtonClasses({ ...props, isTertiary: true });

    return <ExternalLinkAdapter {...props} className={classes} />;
};

const getUrlLabel = (url) => <span aria-hidden='true'>{url.split('://')[1]}</span>;

export const ExternalLinkNoLabel = ({ className = "", title, url }) => {
    if (!title) return <></>;
    let label = getUrlLabel(url);

    return <ExternalLinkAdapter label={label} title={title} url={url} className={`break-all ${className}`} />;
}

const ExternalLinkAdapter = ({ className = "", describedById, hasNoIcon, isDownload, label, url,
    opensInNewTab = true, title, handleClick = () => { } }) => {
    if (label && !url) return <>{label}</>;
    if (!label && !url) return <></>;

    const newTabTarget = opensInNewTab ? "_blank" : null;
    const newTabRel = opensInNewTab ? "noopener noreferrer" : null;
    let classes = className ? className : undefined;
    classes = hasNoIcon ? `${classes || ""} noIcon` : classes;

    return <span>  <a href={url} className={classes} target={newTabTarget} rel={newTabRel}
    title={title} aria-describedby={describedById} download={isDownload} onClick={handleClick}>{label}</a> </span>;
};

const sharedProps = {
    className: PropTypes.string,
    describedById: PropTypes.string,
    url: PropTypes.string.isRequired,
    handleClick: PropTypes.func
};

const buttonProps = {
    isDownload: PropTypes.bool,
    isExpanded: PropTypes.bool,
    isPrimary: PropTypes.bool,
};

ExternalLink.propTypes = {
    hasNoIcon: PropTypes.bool,
    label: PropTypes.any.isRequired,
    ...sharedProps,
};

EmailLink.propTypes = {
    address: PropTypes.string.isRequired,
    className: PropTypes.string,
    label: PropTypes.any,
};

ExternalLinkButton.propTypes = {
    label: PropTypes.any.isRequired,
    ...buttonProps,
    ...sharedProps,
};

ExternalTertiaryLinkButton.propTypes = {
    label: PropTypes.any.isRequired,
    isExpanded: PropTypes.bool,
    ...sharedProps,
};

ExternalLinkNoLabel.propTypes = {
    title: PropTypes.string.isRequired,
    ...sharedProps,
};