import PropTypes from "prop-types";
import React from "react";
import {ButtonMock} from ".";
import {HtmlDisplay} from "../Display";
import {notificationSuffix} from "./inputConstants";

export const Notification = ({
								 error,
								 name,
							 }) => {
	return (
		<div className="notification" id={`${name}${notificationSuffix}`} role="alert">
			{
				error &&
				<>
					<ButtonMock
						className={`notification__button`}
						label={error}
						hideLabel
						name={`${name}${notificationSuffix}_button`}
					/>
					<HtmlDisplay className={"notification__message"} html={error} />
				</>
			}
		</div>
	);
};

Notification.propTypes = {
	error: PropTypes.string,
	name: PropTypes.string.isRequired,
};