import {
    config,
    mockResolveApiCall,
    requestOptions,
    requestTypes,
    sendRequest,
} from "../Utilities/Api";
import {dynamicSort} from "../Utilities/Sort";
import { districts } from "./districtsMockData";


//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerDistrictsApi {
    static getDistricts() {
        return sendRequest(
            requestOptions(
                config.URL + `districts/`,
                requestTypes.GET
            ),
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************

class MockDistrictsApi {
    static getDistricts() {
        return mockResolveApiCall(districts.sort(dynamicSort("regionName")));
    }
}

export const districtsApi = config.environment.MODE === config.modes.SERVER ? ServerDistrictsApi : MockDistrictsApi;