import PropTypes from "prop-types";
import {useEffect} from "react";
import {getCurrentLocation, locationBehaviors, locations} from "../../Utilities/Location";
import {isValidUser} from "../../Containers/User/userUtilities";

const Authorize = ({
                       fetchUser,
                       isAdmin = false,
                       isLoggedIn,
                       path,
                       pathUrl,
                       redirect,
                       returnUrl,
                       setReturnUrl,
                       user,
                   }) => {

    useEffect(() => {
        const currentLocation = getCurrentLocation(path);
        if (!isLoggedIn) {
            if (currentLocation[locationBehaviors.requiresAuthentication] || currentLocation[locationBehaviors.requiresAdminAuthentication]) {
                if (!currentLocation[locationBehaviors.doNotRedirectBack]) {
                    setReturnUrl(pathUrl);
                }
                redirect(locations.SIGN_IN.path);
            }
            return;
        }

        if (currentLocation[locationBehaviors.requiresAdminAuthentication] && !isAdmin) {
            redirect(locations.NOT_AUTHORIZED.path);
            return;
        }

        if (returnUrl) {
            redirect(returnUrl);
            setReturnUrl(undefined);
        } else if (currentLocation[locationBehaviors.redirectToDashboard]) {
            const homePath = locations.DASHBOARD.path;
            redirect(homePath);
        }

        // eslint-disable-next-line
    }, [isLoggedIn, path]);

    useEffect(() => {
        if (isLoggedIn && !isValidUser(user))
            fetchUser();
        // eslint-disable-next-line
    }, [user, isLoggedIn]);


    return null;
};

Authorize.propTypes = {
    fetchUser: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool,
    isLoggedIn: PropTypes.bool.isRequired,
    path: PropTypes.string.isRequired,
    pathUrl: PropTypes.string.isRequired,
    redirect: PropTypes.func.isRequired,
    returnUrl: PropTypes.string,
    setReturnUrl: PropTypes.func,
    user: PropTypes.object.isRequired,
};

export default Authorize;