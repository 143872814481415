import {NotificationFactory} from "../../Components/Notifications/NotificationFactory/NotificationFactory";
import {api as config} from "../../SiteConfig/siteConstants";
import {serialize} from "object-to-formdata";
import AjaxBlobRequest from "./Requests/ajaxBlobRequest";
import AjaxRequest from "./Requests/ajaxRequestWithRetry";

const notificationFactory = new NotificationFactory();
const isAuthorizedUrl = config.URL + "accounts/is-authorized";

function validateOptions(ajaxRequestOptions) {
    if (!ajaxRequestOptions) throw new Error("Requests options are required to configure the http request.");
}

// API bridge
export function sendRequest(ajaxRequestOptions) {
    validateOptions(ajaxRequestOptions);

    return AjaxRequest(notificationFactory, isAuthorizedUrl).send(ajaxRequestOptions);
}

export function downloadBlob(ajaxRequestOptions) {
    validateOptions(ajaxRequestOptions);

    return AjaxBlobRequest(notificationFactory).send(
        ajaxRequestOptions,
    );
}

function mockApiCall(message, resolveResult = undefined) {
    return new Promise((resolve, reject) => {
        if (resolveResult) {
            if (message) {
                notificationFactory.createSuccess(message).notify();
            }
            resolve(resolveResult);
        } else {
            const errorString = message ? message : "No result given to test.";
            notificationFactory.createError(errorString).notify();
            reject(errorString);
        }
    });
}

export function mockResolveApiCall(result, successMessage) {
    return mockApiCall(successMessage, result);
}

export function mockRejectApiCall(errorMessage) {
    return mockApiCall(errorMessage);
}

export function convertObjectToFormData(obj, fileData) {
    return serialize(obj, {indices: true}, fileData);
}