import PropTypes from "prop-types";
import React from "react";
import userApi from "../User/userApi";
import SignInView from "./SignInView";
import LayoutHero from "../../Components/Layout/LayoutHero";
import {PageTitle} from "../../Components/Landmarks";
import {LayoutMedium} from "../../Components/Layout";

export const SignInContainer = ({
                             handleApiCall,
                             isLoading,
                             setLoggedInTrue,
                             setWarnings
                         }) => {

    const handleSignIn = (signInModel) => {
        handleApiCall(() => userApi.attemptLogin(signInModel), setLoggedInTrue);
    };

    return (
        <LayoutHero>
            <LayoutMedium>
                <PageTitle h1={`Sign In`}/>
                <SignInView handleSignIn={handleSignIn} isLoading={isLoading} setWarnings={setWarnings}/>
            </LayoutMedium>
        </LayoutHero>
    );

};

SignInContainer.propTypes = {
    handleApiCall: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    setLoggedInTrue: PropTypes.func.isRequired,
    setWarnings: PropTypes.func.isRequired,
};
