import { NotificationFactory } from "../NotificationFactory/NotificationFactory";
import { convertToArray, isArrayNullOrEmpty } from "../../../Utilities/Types/arrayUtilities";

const notificationFactory = new NotificationFactory();

export function clear(clearErrorWarningToastsOnly = false) {
	return notificationFactory.clear(clearErrorWarningToastsOnly);
}

export function error(message) {
	return notificationFactory.createError(message).notify();
}

export function success(message) {
	return notificationFactory.createSuccess(message).notify();
}

export function info(message) {
	return notificationFactory.createInfo(message).notify();
}

export function warning(message) {
	return notificationFactory.createWarning(message).notify();
}

export function processNotify(array, callback, clearOutNotification) {
	array = convertToArray(array);
	if (isArrayNullOrEmpty(array)) return;

	clear();

	array.map((item) => {
		return callback(item);
	});

	clearOutNotification([]);
}

export default warning;
