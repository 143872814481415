import {PageTitle} from "../../../Components/Landmarks";
import React, {useEffect, useState} from "react";
import {locations} from "../../../Utilities/Location";
import PropTypes from "prop-types";
import {GridCell} from "../../../Components/Grid";
import {emptyResourceModel} from "../../Library/libraryFactory";
import {handleApiCall} from "../../../Utilities/Api/callbackUtilities";
import {createListFromArrayOfObjects} from "../../../Utilities/Types/listUtilities";
import {orderRequestModel} from "./orderRequestFactory";
import {isGuidEmpty, isNullOrUndefined} from "../../../Utilities/commonUtilities";
import {ORDER_TYPE} from "./orderRequestConstants";
import {OrderRequestView} from "./OrderRequestView";
import OrderRequestApi from "./orderRequestApi";

export const OrderRequestContainer = ({
                                          catalogFormatId,
                                          catalogItemId,
                                          redirect,
                                          userId,
                                      }) => {

    const [orderRequestDetails, setOrderRequestDetails] = useState(null);
    const [students, setStudents] = useState();
    const [shippingLocations, setShippingLocations] = useState();
    const [publishers, setPublishers] = useState();
    const title = orderRequestDetails?.orderType === ORDER_TYPE.Order ? `Order` : `Special Order Request`;

    const handleReturn = () => redirect(locations.LIBRARY_CATALOG.path);

    const handleSubmitOrderRequest = async (orderRequest) => {

        const sanitizedModel = orderRequestModel(orderRequest);

        handleApiCall(() => OrderRequestApi.addOrder(sanitizedModel), () => {
            handleReturn();
        });
    };

    const loadOrderRequestDetailsResult = (result) => {

        const {
            copies,
            format,
            publisherId,
            numberOfCopiesAvailable
        } = result.formats.find(f => f.catalogFormatId === catalogFormatId);

        const orderRequestResult = orderRequestModel({
            ...result,
            authors: result.author,
            catalogFormatId: catalogFormatId,
            format: format,
            gradeLevel: result.gradeLevel,
            isbn: result.isbn13 ? result.isbn13 : (result.isbn10 ? result.isbn10 : result.previewIsbn),
            itemName: result.title,
            orderType: copies.length === 0 ? ORDER_TYPE.SpecialOrderRequest : copies.length !== 0 && numberOfCopiesAvailable === 0 ? ORDER_TYPE.Order : ORDER_TYPE.SpecialOrderRequest,
            publisherId: publisherId,
        });

        setOrderRequestDetails(orderRequestResult);
    };

    useEffect(() => {

        const getResource = () => {
            handleApiCall(() => OrderRequestApi.getResource(catalogItemId), (results = emptyResourceModel) => loadOrderRequestDetailsResult(results));
        }

        const getStudents = () => {
            handleApiCall(() => OrderRequestApi.getStudents({
                userId
            }), (results) => {
                setStudents(createListFromArrayOfObjects(results, `studentId`, `fullName`));
            });
        };

        const getShippingLocations = () => {
            handleApiCall(() => OrderRequestApi.getShippingLocations(), (results) => {
                setShippingLocations(createListFromArrayOfObjects(results, `shippingLocationId`, `name`));
            });
        }

        const getPublishers = () => {
            handleApiCall(() => OrderRequestApi.getPublishers(), (results) => {
                setPublishers(createListFromArrayOfObjects(results, `publisherId`, `name`));
            });
        }

        if (catalogItemId && !isGuidEmpty(catalogItemId) && !orderRequestDetails)
            getResource();
        else if (isGuidEmpty(catalogItemId))
            setOrderRequestDetails(orderRequestModel());

        if (!shippingLocations)
            getShippingLocations();
        if (!publishers)
            getPublishers();
        if (!students)
            getStudents();

    }, [catalogItemId]);

    console.log(orderRequestDetails );
    return <>
        <PageTitle h1={title}/>{
        !isNullOrUndefined(orderRequestDetails) &&
        <>
            <GridCell>
                {orderRequestDetails.orderType === ORDER_TYPE.SpecialOrderRequest &&
                    <p>If you have searched for a title and you have not found the item you are looking for, you can
                        fill out this special order request form and the FIMC-VI staff will then research the book and
                        inform
                        you of the results.
                    </p>
                }
                {
                    orderRequestDetails.orderType !== ORDER_TYPE.SpecialOrderRequest &&
                    <p>
                        We are currently out of copies for this item, you can fill out this form
                        to request copies and the FIMC-VI staff will inform you of the results.
                    </p>
                }

            </GridCell>
            {
                shippingLocations && students &&
                <OrderRequestView
                    shippingLocations={shippingLocations}
                    handleReturn={handleReturn}
                    handleSubmitOrderRequest={handleSubmitOrderRequest}
                    publishers={publishers}
                    orderRequestDetails={orderRequestDetails}
                    students={students}
                />
            }
        </>
    }
    </>;
};

OrderRequestContainer.propTypes = {
    catalogFormatId: PropTypes.string.isRequired,
    catalogItemId: PropTypes.string.isRequired,
    redirect: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
};