import React, {useState} from 'react';
import {InputAdapter, inputProps, numberProps} from "./InputAdapter";
import {numberOfDecimalPlaces, validateDecimal} from "../../Utilities/commonUtilities";

const AbstractNumberField = (props) => {

    const {
        decimalPlaces = 2,
        isInline = true,
        isPositiveNumberOnly,
        isWholeNumberOnly,
        maxValue,
        minValue,
        onChange,
        value
    } = props;
    const [error, setError] = useState("");

    const minNumber = minValue ? minValue
        : isPositiveNumberOnly ? 0
            : undefined;

    const step = isWholeNumberOnly ? "1" : ".01";

    const onChangeValidation = (e) => {
        const event = {...e};
        const newValue = event.target.value;
        const numberOfDigits = isWholeNumberOnly ? 0 : decimalPlaces;
        const foundDigits = numberOfDecimalPlaces(newValue);
        const shouldValidate = foundDigits > numberOfDigits;

        setError(event.target.validationMessage);

        if (shouldValidate)
            event.target.value = validateDecimal(Number(newValue), numberOfDigits);

        onChange(event);
    };

    return <InputAdapter
        {...props}
        error={error || props.error}
        inputType={`number`}
        isInline={isInline}
        max={maxValue}
        min={minNumber}
        onChange={onChangeValidation}
        step={step}
        value={value}
    />;
};

export const AbstractNumberFieldProps = {
    ...inputProps,
    ...numberProps,
};

AbstractNumberField.propTypes = AbstractNumberFieldProps;

export default AbstractNumberField;