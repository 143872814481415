import React from "react";
import {BrowserRouter} from "react-router-dom";
import {SiteRoutes} from "./SiteRoutes";

export const AppRouter = () => {
    return (
        <BrowserRouter>
            <SiteRoutes/>
        </BrowserRouter>
    );
};