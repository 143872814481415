import PropTypes from "prop-types";
import React from "react";

const GridTable = ({className="", children, id="", parentClass=""}) => {
    return <div aria-live="polite" className={`table-scroll ${parentClass}`} id={id}>
        <table className={className}>{children}</table>
    </div>;
};

GridTable.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    className: PropTypes.string,
    id: PropTypes.string,
    parentClass: PropTypes.string,
};

export default GridTable;
