import React from "react";
import PropTypes from "prop-types";
import {GridTable} from "../../../Components/Grid";
import UserApprovalRow from "./UserApprovalRow";
import {isArrayNullOrEmpty} from "../../../Utilities/Types/arrayUtilities";

const UserApprovalView = ({
                              handleSetUserSearchCriteria,
                              onApprovalStatusClick,
                              users,
                              redirect,
                              userSearchCriteria
                          }) => {

    const handleOrderClick = (event) => {
        event.preventDefault();
        let criteria = {...userSearchCriteria};
        criteria.UpdateSorting(event.target.dataset.id);
        handleSetUserSearchCriteria(criteria);
    };

    const noResults = isArrayNullOrEmpty(users);

    return (
        <>
            <GridTable parentClass={`margin--topNone`}>
                <thead>
                <tr>
                    <th onClick={handleOrderClick} className="is-clickable" data-id="email">Email</th>
                    <th onClick={handleOrderClick} className="is-clickable" data-id="firstName">First Name</th>
                    <th onClick={handleOrderClick} className="is-clickable" data-id="lastName">Last Name</th>
                    <th onClick={handleOrderClick} className="is-clickable" data-id="districtName">District /
                        Institution
                    </th>
                    <th onClick={handleOrderClick} className="is-clickable" data-id="patronRoleName">Job Title</th>
                    <th onClick={handleOrderClick} className="is-clickable" data-id="organization">Organization</th>
                    <th onClick={handleOrderClick} className="is-clickable" data-id="approvalStatusName"
                        scope={"col"}>Approval Status
                    </th>
                    <th scope={"col"} className={"align-center"}>
                    Actions
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    users.map((user, index) => {
                            return (<UserApprovalRow
                                key={index}
                                redirect={redirect}
                                onApprovalStatusClick={onApprovalStatusClick}
                                user={user}
                            />);
                        }
                    )
                }
                {
                    noResults &&
                    <tr data-testid={'no-users-row'}>
                        <td colSpan={8} className={`padding--all text-center`}>No search results found.</td>
                    </tr>
                }
                </tbody>
            </GridTable>
        </>
    );
}

UserApprovalView.propTypes = {
    handleSetUserSearchCriteria: PropTypes.func.isRequired,
    onApprovalStatusClick: PropTypes.func.isRequired,
    users: PropTypes.array,
    redirect: PropTypes.func,
    userSearchCriteria: PropTypes.object,
}

export default UserApprovalView;





