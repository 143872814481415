import React from "react";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";

export const Link = ({
                         children,
                         className,
                         describedby,
                         label,
                         to
                     }) => {

    if (!label && !children) return React.Fragment;

    return (
        <NavLink to={to} className={className} aria-describedby={describedby} end>
            {label}
            {children}
        </NavLink>
    );
};

Link.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    describedby: PropTypes.string,
    label: PropTypes.any,
    to: PropTypes.string,
};