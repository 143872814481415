import {isObjectNullOrEmpty} from "../../Utilities/Types/objectUtilities";
import {userModel} from "./userFactory";
import {userSearchModel} from "./userFactorySearch";
import {districts} from "../../SiteConfig/districtsMockData";
import {ROLES} from "../../Components/Authorize/roleConstants";
import {APPROVAL_STATUS} from "../Constants/approvalConstants";
import {gridConstants} from "../../Components/Grid";


export const userData = [
    {
        userId: "15560759-503c-4d49-a377-94859c0ae290",
        firstName: "Ricardo",
        middleName: "",
        lastName: "Olavarrieta",
        fullName: "Ricardo Olavarrieta",
        districtId: districts[0].id,
        districtName: districts[0].districtName,
        email: "ricardo@fsu.edu",
        password: null,
        confirmPassword: null,
        isActive: true,
        createDate: "02/21/2024",
        updatePasswordToken: null,
        updatePasswordExpiration: null,
        patronRole: 6,
        patronSubType: 6,
        charterType: null,
        organization: "fimc",
        approvalStatus: APPROVAL_STATUS.Pending,
        roleId: ROLES.SystemAdmin,
    },
    {
        userId: "7229aa1a-1a29-4e26-abed-b3da72c50608",
        firstName: "John",
        middleName: "",
        lastName: "Hopkins",
        fullName: "John Hopkins",
        districtId: districts[1].id,
        districtName: districts[1].districtName,
        email: "john@fsu.edu",
        password: null,
        confirmPassword: null,
        isActive: true,
        createDate: "02/21/2024",
        updatePasswordToken: null,
        updatePasswordExpiration: null,
        patronRole: 2,
        patronSubType: 5,
        charterType: null,
        organization: "fimc",
        approvalStatus: APPROVAL_STATUS.Pending,
        roleId: ROLES.SystemAdmin,
    },
    {
        userId: "0f754154-dfe1-4186-8a4b-36b6cb12178u",
        firstName: "Silpa",
        middleName: "",
        lastName: "Mankurussi",
        fullName: "Silpa Mankurussi",
        districtId: districts[2].id,
        districtName: districts[2].districtName,
        email: "sm22ed@fsu.edu",
        password: null,
        confirmPassword: null,
        isActive: true,
        createDate: "02/21/2024",
        updatePasswordToken: null,
        updatePasswordExpiration: null,
        patronRole: 6,
        patronSubType: 6,
        charterType: null,
        organization: "fimc",
        approvalStatus: APPROVAL_STATUS.Approved,
        roleId: ROLES.SystemAdmin,
     }
   
];

export const userDataFromFactory = userData.map(user => userModel(user));

export const user = userModel(userData[0]);

export const userId = user.userId;

export function isValidUser(user) {
    return !isObjectNullOrEmpty(user) && !!user.userId && user.userId > 0;
}

export const getAccount = (query) => {
    let account = userData.find((account) => account.userId.toString() === query.toString());

    if (isValidUser(account)) return account;

    return userData.find((account) => account.email === query);
};

export const accountExists = (email) => {
    return !!getAccount(email);
};

export const isLinkExpired = (updatePasswordToken) => {
    return isObjectNullOrEmpty(updatePasswordToken);
};

export const userSearchCriteria = userSearchModel(0, "", "", "", "", "","","");

export const buttonSharedSettings = {
    medium_columns: gridConstants.column.THREE,
    small_columns: gridConstants.column.SIX,
};


