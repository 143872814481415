import {emptyFunction, handleApiCall} from "../../Utilities/Api/callbackUtilities";

export const isLoggedIn = false;
export const isAdmin = false;
export const path = "/";
export const redirect = emptyFunction;
const setIsAdmin = emptyFunction;

const layoutMockExports = {isLoggedIn, isAdmin, path, redirect, handleApiCall, setIsAdmin};
export default layoutMockExports;
