import React from "react";
import {FieldsetAdapter, fieldsetAdapterProps} from "./FieldsetAdapter";
import {inputType} from "./inputConstants";
import {createFakeEvent} from "./inputUtility";

export const RadioList = (props) => {

    const {onChange, value=""} = props;

    const handleChange = (event) => {
        const {name, value} = event.target;

        return onChange(createFakeEvent(value, name));
    };
    return <FieldsetAdapter {...props} onChange={handleChange} inputType={inputType.RADIO} value={value.toString()}/>;
};

RadioList.propTypes = {
    ...fieldsetAdapterProps,
};