import React from "react";
import PropTypes from "prop-types";
import {HtmlDisplay} from "../../Components/Display";
import {Button, ButtonLinkStyle} from "../../Components/Inputs";
import {GridCell, GridContainer, GridTable} from "../../Components/Grid";
import {Allow, policyEvents} from "../../Components/Authorize";
import {isGuidEmpty} from "../../Utilities/commonUtilities";

export const LibraryCatalogItem = ({
                                       catalogId,
                                       description,
                                       details,
                                       formats,
                                       handleAddToQueue,
                                       handleDelete,
                                       handleEdit,
                                       handleOrderRequest,
                                       title,
                                   }) => {

    const handleClickEdit = (catalogId, format) => handleEdit(catalogId, format);
    const handleClickOrderRequest = (catalogId, catalogFormatId) => handleOrderRequest(catalogId, catalogFormatId);


    return <>
        <GridContainer className={`catalog__item`} hasMarginX>
            <GridCell>
                <h3 id={`title${catalogId}`}>
                    {title + " "}
                    {
                        <Allow policyEvent={policyEvents.MANAGE_CATALOG}>
                            <ButtonLinkStyle isCollapsed label={`(Edit Item)`}
                                             name={`btnEdit${catalogId}`}
                                             onClick={() => handleClickEdit(catalogId)}/>
                        </Allow>
                    }
                </h3>

                <HtmlDisplay html={description}/>

                <HtmlDisplay html={details}/>
                {
                    handleDelete &&
                    <Button isCollapsed label={`Remove`} name={`btnDelete${catalogId}`} onClick={handleDelete}/>
                }

                {
                    formats &&
                    <GridTable>
                        <thead>
                        <tr>
                            <th>Format</th>
                            <th colSpan={2}>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            formats.map(format => {
                                const {catalogFormatId, formatName, numberOfCopiesAvailable, copies} = format;
                                const suffix = `${catalogFormatId}`;
                                const key = `${title}-${catalogFormatId}`;
                                const handleClickAddToQueue = () => handleAddToQueue(catalogFormatId);
                                const isEmptyGuid = isGuidEmpty(catalogFormatId);

                                return <tr key={key}>
                                    <td>{formatName}</td>
                                    {
                                        !isEmptyGuid && copies.length !== 0 && numberOfCopiesAvailable !== 0 &&
                                        <td>
                                            <ButtonLinkStyle isCollapsed isPrimary label={`Add to Cart`}
                                                             name={`btnAddQueue${suffix}`}
                                                             onClick={handleClickAddToQueue}/>
                                        </td>
                                    }
                                    {
                                        !isEmptyGuid && copies.length === 0 &&
                                        <td>

                                            <ButtonLinkStyle isCollapsed
                                                             isPrimary
                                                             label={`Special Order Request`}
                                                             name={`btnSpecialOrderRequest${suffix}`}
                                                             onClick={() => handleClickOrderRequest(catalogId, catalogFormatId)}/>
                                        </td>
                                    }
                                    {
                                        !isEmptyGuid && copies.length !== 0 && numberOfCopiesAvailable === 0 &&
                                        <td>
                                            <ButtonLinkStyle isCollapsed
                                                             isPrimary
                                                             label={`Order`}
                                                             name={`btnOrderRequest${suffix}`}
                                                             onClick={() => handleClickOrderRequest(catalogId, catalogFormatId)}/>
                                        </td>
                                    }
                                    <Allow policyEvent={policyEvents.MANAGE_CATALOG}>
                                        <td>
                                            <ButtonLinkStyle isCollapsed label={`Edit Item`}
                                                             name={`btnEdit${catalogId}`}
                                                             onClick={() => handleClickEdit(catalogId, catalogFormatId)}/>
                                        </td>
                                    </Allow>
                                </tr>
                            })
                        }
                        </tbody>
                    </GridTable>
                }

            </GridCell>
        </GridContainer>
    </>;
};

LibraryCatalogItem.propTypes = {
    description: PropTypes.string,
    details: PropTypes.string,
    catalogId: PropTypes.string,
    formats: PropTypes.array,
    handleAddToQueue: PropTypes.func,
    handleDelete: PropTypes.func,
    handleEdit: PropTypes.func,
    handleOrderRequest: PropTypes.func,
    title: PropTypes.string,
};