import PropTypes from "prop-types";
import React from "react";
import { GridCell, gridConstants, GridContainer } from "../Grid";
import hero from "../../images/FIMC-VI-logo.svg";

const LayoutHero = ({ children }) => {
    return (
        <GridContainer hasMarginX hasMarginY>
            <GridCell medium_columns={gridConstants.column.FOUR} className={gridConstants.class.HIDE_SMALL}>
                <div className="logo__container">
                    <img src={hero} title={`FIMC-VI logo`}  alt={`FIMC-VI`}/>
                </div>
            </GridCell>
            <GridCell medium_columns={gridConstants.column.EIGHT}>
                {children}
            </GridCell>
        </GridContainer>
    );
};

LayoutHero.propTypes = {
    children: PropTypes.any,
};

export default LayoutHero;
