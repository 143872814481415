import PropTypes from "prop-types";
import React from "react";
import {GridCell} from "../../Components/Grid";
import {
    Button,
    clearInputFormErrorFields,
    InputForm,
    inputFormIsValid,
    PasswordField,
    useInputForm
} from "../../Components/Inputs";
import {PageTitle} from "../../Components/Landmarks";
import {LayoutMedium} from "../../Components/Layout";
import {isTrimmedStringEmpty} from "../../Utilities/commonUtilities";
import {Link} from "../../Components/Link";
import {locations} from "../../Utilities/Location";

const PasswordUpdateView = ({isLoading, handleUpdatePassword, setWarnings, isLinkExpired}) => {

    const form = useInputForm({password: "", confirmPassword: ""});

    const isFormValid = () => {
        clearInputFormErrorFields(form);
        if (isTrimmedStringEmpty(form.password.value)) {
            form.password.setError("Password is required.");
        }
        if (isTrimmedStringEmpty(form.confirmPassword.value)) {
            form.confirmPassword.setError("Confirm Password is required.");
        }

        if (form.confirmPassword.value !== form.password.value) {
            form.password.setError("Password fields must match.");
        }

        let isValid = inputFormIsValid(form);

        if (!isValid)
            setWarnings(["Missing required items.  Please review your responses and try submitting again."]);

        return isValid;
    };

    const handleClickSubmit = async () => {
        if (isFormValid()) {
            handleUpdatePassword(form.password.value);
        }
    };

    return <>
        <PageTitle h1={`Update Password`}/>

        {
            isLinkExpired &&
            <p className={`text-center`}>Your password reset link is expired. <br/>
                Please request a <Link {...locations.FORGOT_PASSWORD.link} label={`new link`}/>.
            </p>
        }
        {
            !isLinkExpired &&
            <LayoutMedium>
                <InputForm name={"updatePw"}>
                    <PasswordField {...form.password} />

                    <PasswordField {...form.confirmPassword} />

                    <GridCell>
                        <Button
                            isPrimary
                            name="btnSubmit"
                            label="Submit"
                            onClick={handleClickSubmit}
                            disabled={isLoading}
                        />
                    </GridCell>
                </InputForm>
            </LayoutMedium>
        }

    </>;
};

PasswordUpdateView.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    handleUpdatePassword: PropTypes.func.isRequired,
    setWarnings: PropTypes.func.isRequired,
    isLinkExpired: PropTypes.bool.isRequired,
};

export default PasswordUpdateView;
