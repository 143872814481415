import { NotificationAdapter } from "../Notification/NotificationAdapter";
import toastr from "./toastr";
import toastrOptions from "./toastrOptions";
let openedToasts = [];

export class ToastrAdapter extends NotificationAdapter {
	clear(clearErrorWarningToastsOnly = false) {
		if(!clearErrorWarningToastsOnly)
			toastr.clear();
		else {
			for (let i = openedToasts.length - 1; i >= 0; i--)
				toastr.clear(openedToasts[i]);
		}

		openedToasts = [];
	}
	error(message, title = "") {
		let toast = toastr.error(message, title, toastrOptions.Error);
		openedToasts.push(toast);
		return this.adjustToast(toast);
	}
	success(message, title = "", overrideOptions = {}) {
		let options = { ...toastrOptions.Success, ...overrideOptions };
		let toast = toastr.success(message, title, options);
		return this.adjustToast(toast);
	
	}
	info(message, title = "") {
		let toast = toastr.info(message, title, toastrOptions.Info);
		return this.adjustToast(toast);
	}
	warning(message, title = "") {
		let toast = toastr.warning(message, title, toastrOptions.Warning);
		openedToasts.push(toast);
		return this.adjustToast(toast);
	}
	adjustToast(toast) {
		if(!toast) return toast;

		return toast;
	}
}
