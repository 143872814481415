import {createListFromObject} from "../../Utilities/Types/listUtilities";
import {GRADE_LEVEL_LIST} from "../Student/studentConstants";
export {GRADE_LEVEL_LIST};

export const AVAILABILITY = {
    "In Stock": 1,
    "Obtainable": 2,
    "Special Order Request": 3,
};

export const AVAILABILITY_LIST = createListFromObject(AVAILABILITY);

export const FORMAT = {
    "Braille (UEB)": 1,
    "Braille (Nemeth)": 2,
    "Braille (EBAE)": 3,
    "Uncontracted": 4,
    "Large Print": 5,
    "Digital": 6,
};

export const FORMAT_LIST = createListFromObject(FORMAT);

export const FUNDING_SOURCE = {
    "0816": 1,
    "0817": 2,
    "Quota": 3,
    "Internal Production": 4,
};

export const FUNDING_SOURCE_LIST = createListFromObject(FUNDING_SOURCE);

export const LOCATION = {
    "OutOnLoan": 1,
    "ShelfLocation": 2,
    "Purged": 3,
};

export const LOCATION_LIST = createListFromObject(LOCATION);

export const COPY_STATUS = {
    "Available": 0,
    "Reserved": 1,
    "CheckedOut": 2,
    "Damaged": 3,
    "Lost": 4
};

export const COPY_STATUS_LIST = createListFromObject(COPY_STATUS);
