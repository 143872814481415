import React from "react";
import PropTypes from "prop-types";
import {
    Button,
    convertInputFormToObject,
    DateTimePickerField,
    InputForm,
    SelectField,
    TextField,
    useInputForm
} from "../../../Components/Inputs";
import {clearInputFormFields, createYesNoList} from "../../../Components/Inputs/inputUtility";
import {GridCell, gridConstants, GridContainer} from "../../../Components/Grid";
import SearchableSingleSelectField from "../../../Components/Inputs/SearchableSingleSelectField";
import {createListFromArrayOfObjects} from "../../../Utilities/Types/listUtilities";
import {
    GRADE_LEVEL_LIST,
    LANGUAGE_LIST,
    STUDENT_APPROVAL_STATUS_LIST_FOR_FIMC_ADMIN_SEARCH,
    STUDENT_APPROVAL_STATUS_LIST_FOR_SEARCH,
    STUDENT_INACTIVE_REASON_LIST,
    STUDENT_STATUS_LIST
} from "../studentConstants";
import {Allow, policyEvents} from "../../../Components/Authorize";
import {isFimcAdmin} from "../../../Utilities/LocalStorage/storageUtilities";

const StudentApprovalSearchFormView = ({
                                           buttonSharedSettings,
                                           districts,
                                           handleSetStudentSearchCriteria,
                                           isLoading,
                                           studentSearchCriteria
                                       }) => {

    const searchForm = useInputForm(studentSearchCriteria);

    const handleClearClick = (event) => {
        event.preventDefault();
        clearInputFormFields(searchForm);
        const updatedStudentSearchCriteria = {...studentSearchCriteria};
        updatedStudentSearchCriteria.ClearStudentSearch();
    };

    const handleSearchClick = (event) => {
        event.preventDefault();
        let criteria = convertInputFormToObject(searchForm);
        const updatedStudentSearchCriteria = {...studentSearchCriteria};
        updatedStudentSearchCriteria.Search(criteria);
        handleSetStudentSearchCriteria(updatedStudentSearchCriteria);
    };

    const inputSharedSettings = {
        large_columns: gridConstants.column.THREE,
        medium_columns: gridConstants.column.SIX,
    };

    return <>
        <InputForm name={"userSearch"}>

            <TextField
                {...searchForm.studentName}
                {...inputSharedSettings}
                label={`Name`}
            />

            <DateTimePickerField
                {...searchForm.dateOfBirth}
                {...inputSharedSettings}
            />

            <Allow policyEvent={policyEvents.ADVANCE_SEARCH}>
                <SearchableSingleSelectField
                    {...searchForm.districtId}
                    {...inputSharedSettings}
                    defaultOption={"All"}
                    includeDefaultOption
                    label={'District'}
                    options={createListFromArrayOfObjects(districts, "id", "districtName")}
                />
            </Allow>

            <SelectField
                {...searchForm.gradeLevel}
                {...inputSharedSettings}
                defaultOptionValue={""}
                defaultOption={"All"}
                includeDefaultOption
                options={GRADE_LEVEL_LIST}
            />

            <SelectField
                {...searchForm.language}
                {...inputSharedSettings}
                defaultOptionValue={""}
                defaultOption={"All"}
                includeDefaultOption
                options={LANGUAGE_LIST}
            />

            <TextField
                {...searchForm.patronName}
                {...inputSharedSettings}
            />

            <SelectField
                {...searchForm.isActive}
                {...inputSharedSettings}
                defaultOptionValue={""}
                defaultOption={"All"}
                includeDefaultOption
                label={"Active Account"}
                options={createYesNoList(true)}
            />

            <SelectField
                {...searchForm.studentApprovalStatus}
                {...inputSharedSettings}
                defaultOptionValue={""}
                defaultOption={"All"}
                includeDefaultOption
                options={isFimcAdmin()? STUDENT_APPROVAL_STATUS_LIST_FOR_FIMC_ADMIN_SEARCH :STUDENT_APPROVAL_STATUS_LIST_FOR_SEARCH}
            />

            <SelectField
                {...searchForm.studentStatus}
                {...inputSharedSettings}
                includeDefaultOption
                options={STUDENT_STATUS_LIST}
            />

            <SelectField
                {...searchForm.studentInactiveReason}
                {...inputSharedSettings}
                includeDefaultOption
                options={STUDENT_INACTIVE_REASON_LIST}
            />

            <GridCell>
                <GridContainer isReverseOrder isFlush hasPaddingX>

                    <GridCell {...buttonSharedSettings}>
                        <Button
                            disabled={isLoading}
                            label="Search"
                            name="btnSearchStudents"
                            onClick={handleSearchClick}
                            isPrimary
                        />
                    </GridCell>

                    <GridCell {...buttonSharedSettings} medium_offset={gridConstants.column.SIX}>
                        <Button
                            disabled={isLoading}
                            name="btnClearStudentSearch"
                            onClick={handleClearClick}
                            label="Clear"
                        />
                    </GridCell>

                </GridContainer>
            </GridCell>

        </InputForm>
    </>;
};

StudentApprovalSearchFormView.propTypes = {
    buttonSharedSettings: PropTypes.object.isRequired,
    districts: PropTypes.array,
    handleSetStudentSearchCriteria: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    studentSearchCriteria: PropTypes.object.isRequired,
};

export default StudentApprovalSearchFormView;
