import React from "react";
import PropTypes from "prop-types";
import {
    Button, CheckBoxField,
    convertInputFormToObject,
    InputForm,
    SelectField,
    TextField,
    useInputForm
} from "../../../Components/Inputs";
import gridConstants from "../../../Components/Grid/gridConstants";
import GridCell from "../../../Components/Grid/GridCell";
import {clearInputFormFields, createIsActiveList} from "../../../Components/Inputs/inputUtility";
import {createListFromArrayOfObjects} from "../../../Utilities/Types/listUtilities";
import {jobTitles, PatronRoleSubtypesMap, createSubtypeListFromPatronRole} from "../../../SiteConfig/jobTitles";
import SearchableSingleSelectField from "../../../Components/Inputs/SearchableSingleSelectField";
import {Allow, policyEvents} from "../../../Components/Authorize";
import {GridContainer} from "../../../Components/Grid";
import {APPROVAL_STATUS_LIST} from "../../Constants/approvalConstants";
import { isAdmin } from "../../../Utilities/LocalStorage/storageUtilities";

const UserApprovalSearchFormView = ({
                                        buttonSharedSettings,
                                        districts,
                                        handleSetUserSearchCriteria,
                                        isLoading,
                                        userSearchCriteria,
                                        user
                                    }) => {

    const searchForm = useInputForm(userSearchCriteria);


    const handleClearClick = (event) => {
        event.preventDefault();
        clearInputFormFields(searchForm);
        const updatedUserSearchCriteria = {...userSearchCriteria};
        updatedUserSearchCriteria.ClearUserSearch();
    };

    const handleSearchClick = (event) => {
        event.preventDefault();
        let criteria = convertInputFormToObject(searchForm);
        const updatedUserSearchCriteria = {...userSearchCriteria};
        updatedUserSearchCriteria.Search(criteria);
        handleSetUserSearchCriteria(updatedUserSearchCriteria);
    };

    const inputSharedSettings = {
        large_columns: gridConstants.column.THREE,
        medium_columns: gridConstants.column.SIX,
    };

    return <>
        <InputForm name={"userSearch"}>

            <TextField
                {...searchForm.fullName}
                {...inputSharedSettings}
                label={`Name`}
            />

            <TextField
                {...searchForm.email}
                {...inputSharedSettings}
            />

            <Allow policyEvent={policyEvents.ADVANCE_SEARCH}>
                <TextField
                    {...searchForm.organization}
                    {...inputSharedSettings}
                />
            </Allow>

            <Allow policyEvent={policyEvents.ADVANCE_SEARCH}>
                <SearchableSingleSelectField
                    {...searchForm.districtId}
                    {...inputSharedSettings}
                    defaultOption={"All"}
                    includeDefaultOption
                    label={'District'}
                    options={createListFromArrayOfObjects(districts, "id", "districtName")}
                />
            </Allow>

            <SelectField
                {...searchForm.isActive}
                {...inputSharedSettings}
                includeDefaultOption={false}
                options={createIsActiveList()}
            />
            <Allow policyEvent={policyEvents.ADVANCE_SEARCH}>
            <SelectField
                {...searchForm.patronRole}
                {...inputSharedSettings}
                defaultOptionValue={""}
                defaultOption={"Select a Job Title"}
                includeDefaultOption
                label={'Job Title'}
                options={createListFromArrayOfObjects(jobTitles, "jobID", "jobTitle")}
            />
            </Allow>
            <SelectField
                {...searchForm.patronSubType}
                {...inputSharedSettings}
                defaultOptionValue={""}
                defaultOption={"Select a patron role"}
                includeDefaultOption
                label={'Patron Role'}
                options={isAdmin() ? createListFromArrayOfObjects(PatronRoleSubtypesMap, "key", "value")  : createSubtypeListFromPatronRole(user.patronRole)}
            />

           

            <SelectField
                {...searchForm.approvalStatus}
                {...inputSharedSettings}
                includeDefaultOption
                options={APPROVAL_STATUS_LIST}
                defaultOptionValue={""}
                defaultOption={"Select an approval status"}
            />

            <GridCell>
                <GridContainer isReverseOrder isFlush hasPaddingX>
                    <Allow policyEvent={policyEvents.APPROVE_USERS}>
                        <GridCell>
                            <CheckBoxField
                                {...searchForm.showOnlyDelegates}
                                checked={!!searchForm.showOnlyDelegates.value}
                                label={
                                    "Show only delegates"
                                }
                                value={searchForm.showOnlyDelegates.value ? "" : "true"}
                            />
                        </GridCell>
                    </Allow>
                    <GridCell {...buttonSharedSettings}>
                        <Button
                            disabled={isLoading}
                            label="Search"
                            name="btnSearchUsers"
                            onClick={handleSearchClick}
                            isPrimary
                        />
                    </GridCell>
                    <GridCell {...buttonSharedSettings}>
                        <Button
                            disabled={isLoading}
                            name="btnClearUserSearch"
                            onClick={handleClearClick}
                            label="Clear"
                        />
                    </GridCell>
                </GridContainer>
            </GridCell>
        </InputForm>
    </>;
};

UserApprovalSearchFormView.propTypes = {
    buttonSharedSettings: PropTypes.object.isRequired,
    districts: PropTypes.array,
    handleSetUserSearchCriteria: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    userSearchCriteria: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};

export default UserApprovalSearchFormView;
