import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {
    Button,
    checkAndSetError,
    clearInputFormErrorFields,
    convertInputFormToObject,
    InputForm,
    inputFormIsValid,
    reinitializeInputFormFields,
    TextField,
    useInputForm,
} from "../../Components/Inputs";
import {GridCell, gridConstants} from "../../Components/Grid";
import {publisherModel} from "./libraryFactory";

export const LibraryPublisherView = ({
                                    handleSavePublisher,
                                    handleReturn,
                                    publisher,
                                }) => {
    const publisherForm = useInputForm(publisherModel(publisher));

    const handleSubmitClick = (event) => {
        event.preventDefault();
        clearInputFormErrorFields(publisherForm);

        checkAndSetError([
            publisherForm.name,
        ]);

        if (inputFormIsValid(publisherForm)) {
            const dirtyPublisher = convertInputFormToObject(publisherForm);
            handleSavePublisher(dirtyPublisher);
        }
    }

    useEffect(() => {
        if (publisher.publisherId !== publisherForm.publisherId.value)
            reinitializeInputFormFields(publisherForm, publisher);
    }, [publisher]);

    return <>
        <InputForm name={`publisherForm`}>
            <TextField {...publisherForm.name} medium_columns={gridConstants.column.SIX}/>

            <hr/>
            <GridCell className={`text-center`}>
                <Button isPrimary isCollapsed label={'Submit'} name={`btnSubmit`} onClick={handleSubmitClick}/>
                <Button isCollapsed label={'Cancel'} name={`btnCancel`} onClick={handleReturn}/>
            </GridCell>
        </InputForm>
    </>;
};

LibraryPublisherView.propTypes = {
    handleSavePublisher: PropTypes.func.isRequired,
    handleReturn: PropTypes.func.isRequired,
    publisher: PropTypes.object.isRequired,
};