import PropTypes from "prop-types";
import * as policyEvents from "./policyEventsConstants";
import {DefaultPolicy} from "./DefaultPolicy";
import {getRoleId} from "../../Utilities/LocalStorage/storageUtilities";

export const allow = (policyEvent = policyEvents.VIEW, roleId = getRoleId()) => {
    return Allow({policyEvent: policyEvent, roleId: roleId});
};

export const Allow = ({children, policyEvent = policyEvents.VIEW, roleId = getRoleId()}) => {

    const policy = new DefaultPolicy(roleId);
    const isAllowed = policy.permit(policyEvent);

    if (!children)
        return isAllowed;

    if (isAllowed)
        return children;

    return null;
};

Allow.propTypes = {
    children: PropTypes.any.isRequired,
    policyEvent: PropTypes.string,
    roleId: PropTypes.number
};