import {
    EDUCATIONAL_FACILITY,
    GRADE_LEVEL,
    LANGUAGE, PERIPHERAL_FIELD_LOSS,
    COMPLETE_READING_MEDIUMS,
    VISUALLY_IMPAIRED_STUDENT_PLAN, STUDENT_APPROVAL_STATUS
} from "./studentConstants";
import {studentSearchModel} from "./studentSearchFactory";
import {districts} from "../../SiteConfig/districtsMockData";
import {studentModel} from "./StudentApproval/studentApprovalFactory";

export const studentData = [
    {
        studentId: "15560759-503c-4d49-a377-94859c0ae280",
        fimcStudentId: "15560759-503c-4d49-a377-94859c0ae281",
        studentApprovalStatus: STUDENT_APPROVAL_STATUS.Pending,
        certification: true,
        dateOfBirth: "02/21/2024",
        distanceVisualAcuityLeft: "20",
        distanceVisualAcuityRight: "20",
        districtId: districts[0].id,
        districtName: districts[0].districtName,
        educationalFacility: EDUCATIONAL_FACILITY.Charter.id,
        firstName: "Ricardo",
        fullName: "Ricardo Olavarrieta",
        gradeLevel: GRADE_LEVEL.KG,
        hasInstructionalHours: true,
        hasHomeSchoolRequirementAgreement: true,
        isDualSensoryImpaired: true,
        isFaviRegistered: true,
        isFlviRegistered: true,
        language: LANGUAGE.English,
        lastName: "Olavarrieta",
        middleName: "",
        noteToFimc: "note to fimc",
        otherReadingMedium: COMPLETE_READING_MEDIUMS.NotApplicable.id,
        peripheralFieldLoss: PERIPHERAL_FIELD_LOSS.VisualField1,
        primaryReadingMedium: COMPLETE_READING_MEDIUMS.PreReader.id,
        secondaryReadingMedium: COMPLETE_READING_MEDIUMS.AuditoryReader.id,
        hasNeurologicalImpairment: false,
        hasVisualImpairment: false,
        hasSignedParentalConsent: true,
        understandsNoAphAccess: true,
        understandsNotCertified: true,
        writtenPlan: VISUALLY_IMPAIRED_STUDENT_PLAN.Plan504,
        userId: "0f754154-dfe1-4186-8a4b-36b6cb12178u",
        userFullName: "Silpa Mankurussi",
    },
    {
        studentId: "15560759-503c-4d49-a377-94859c0ae281",
        fimcStudentId: "15560759-503c-4d49-a377-94859c0ae282",
        studentApprovalStatus: STUDENT_APPROVAL_STATUS.Pending,
        certification: true,
        dateOfBirth: "03/21/2024",
        distanceVisualAcuityLeft: "20",
        distanceVisualAcuityRight: "20",
        districtId: districts[1].id,
        districtName: districts[1].districtName,
        educationalFacility: EDUCATIONAL_FACILITY.Charter.id,
        firstName: "John",
        fullName: "John Hopkins",
        gradeLevel: GRADE_LEVEL.Eleventh,
        hasInstructionalHours: true,
        hasHomeSchoolRequirementAgreement: true,
        isDualSensoryImpaired: true,
        isFaviRegistered: true,
        isFlviRegistered: true,
        language: LANGUAGE.Spanish,
        lastName: "Hopkins",
        middleName: "",
        noteToFimc: "note to fimc",
        otherReadingMedium: COMPLETE_READING_MEDIUMS.NotApplicable.id,
        peripheralFieldLoss: PERIPHERAL_FIELD_LOSS.VisualField1,
        primaryReadingMedium: COMPLETE_READING_MEDIUMS.PreReader.id,
        secondaryReadingMedium: COMPLETE_READING_MEDIUMS.AuditoryReader.id,
        hasNeurologicalImpairment: false,
        hasVisualImpairment: false,
        hasSignedParentalConsent: true,
        understandsNoAphAccess: true,
        understandsNotCertified: true,
        writtenPlan: VISUALLY_IMPAIRED_STUDENT_PLAN.Plan504,
        userId: "0f754154-dfe1-4186-8a4b-36b6cb12178u",
        userFullName: "Silpa Mankurussi",
    }
];

export const studentDataFromFactory = studentData.map(student => studentModel(student));

export const studentSearchCriteria = studentSearchModel(0, "", "", "", "", "", "");


