import React from "react";
import {HashRouter as Router} from "react-router-dom";
import props from "./layoutMockData";
import {LayoutMedium} from "./LayoutMedium";
import {LayoutPrint} from "./LayoutPrint";
import {LayoutSmall} from "./LayoutSmall";
import {LayoutFullBleed} from "./LayoutFullBleed";
import {TemplateBasicHeader} from "./TemplateBasicHeader";

const TemplateMockBasicHeaderNav = (args) => <Router><TemplateBasicHeader {...props} {...args} /></Router>;

export {LayoutFullBleed, LayoutMedium, LayoutPrint, LayoutSmall, TemplateBasicHeader, TemplateMockBasicHeaderNav};
