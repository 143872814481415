import { createArrayFromObject } from "../../../../Utilities/Types/arrayUtilities";

export const buttonIconType = {
    ADD: "buttonIcon--add",
    ADD_OPTION: "buttonIcon--addOption",
    CANCEL: "buttonIcon--cancel",
    CERTIFICATE: "buttonIcon--certificate",
    COPY_COMPONENT: "buttonIcon--copyComponent",
    CLEAR: "buttonIcon--clear",
	CONTENT: "buttonIcon--content",
	DELETE: "buttonIcon--delete",
	DELETE_COMPONENT: "buttonIcon--deleteComponent",
    DOWNLOAD_FOLLOWUP: "buttonIcon--downloadFollowup",
    EDIT: "buttonIcon--edit",
    DONE_EDITING: "buttonIcon--doneEditing",
    EXIT: "buttonIcon--exit",
    ENROLLMENTS: "buttonIcon--enrollments",
    IMPERSONATE: "buttonIcon--impersonate",
    MENU: "buttonIcon--menu",
    MODULES: "buttonIcon--modules",
    ORDER: "buttonIcon--order",
	PROFILE: "buttonIcon--profile",
	REMOVE: "buttonIcon--remove",
	SAVE: "buttonIcon--save",
	SEARCH: "buttonIcon--search",
    UPLOAD: "buttonIcon--upload",
    HISTORY: "buttonIcon--history",
    VIEW: "buttonIcon--view",
}

export const buttonIconTypeList = createArrayFromObject(buttonIconType);
