import PropTypes from "prop-types";
import React from "react";
import UserDetails from "../../../Components/User/UserDetails";
import {jobTitles} from "../../../SiteConfig/jobTitles";
import {locations} from "../../../Utilities/Location";
import {allow, policyEvents} from "../../../Components/Authorize";

export const UserProfileView = ({
                                    districts,
                                    handleEditUser,
                                    isLoading,
                                    patronDetails,
                                    redirect,
                                    setWarnings,
                                }) => {


    const handleCancel = () => {
        allow(policyEvents.APPROVE_USERS) ? redirect(locations.USER_MANAGEMENT.path) : redirect(locations.DASHBOARD.path);
    }

    return <>
        <UserDetails
            districts={districts}
            handleCancel={handleCancel}
            handleManageUser={handleEditUser}
            isEditing={true}
            isLoading={isLoading}
            jobTitles={jobTitles}
            patron={patronDetails}
            setWarnings={setWarnings}
        />
    </>
};

UserProfileView.propTypes = {
    districts: PropTypes.arrayOf(PropTypes.object),
    handleEditUser: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    patronDetails: PropTypes.object.isRequired,
    redirect: PropTypes.func.isRequired,
    setWarnings: PropTypes.func.isRequired,
};


