import {
    apiConstants,
    config,
    mockRejectApiCall,
    mockResolveApiCall,
    requestOptions,
    requestTypes,
    sendRequest
} from "../../Utilities/Api";
import {isObjectNullOrEmpty} from "../../Utilities/Types/objectUtilities";
import {accountExists, isLinkExpired} from "../User/userMockData";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerPasswordApi {

    static forgotPassword(email) {

        return sendRequest(
            requestOptions(`${config.URL}passwords/forgot/`,
                requestTypes.POST,
                JSON.stringify(email),
                apiConstants.successMessage.user.FORGOT_PASSWORD,
            ),
        );
    }
    static updatePassword(updatePasswordViewModel) {
        return sendRequest(
            requestOptions(`${config.URL}passwords/update/`,
                requestTypes.PUT,
                JSON.stringify(updatePasswordViewModel),
                apiConstants.successMessage.user.UPDATE_PASSWORD,
            ),
        );
    }

    static validateUpdatePasswordLink(updatePasswordToken) {
        return sendRequest(
            requestOptions(`${config.URL}passwords/tokens/${updatePasswordToken}/validate`,
                requestTypes.GET,
            ),
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************

class MockPasswordApi {
    static forgotPassword(email) {
        if (!accountExists(email)) {
            return mockRejectApiCall(apiConstants.errorMessage.user.FORGOT_PASSWORD_INVALID_EMAIL);
        }
        return mockResolveApiCall(true, apiConstants.successMessage.user.FORGOT_PASSWORD);
    }

    static updatePassword(updatePasswordViewModel) { 
        if (isObjectNullOrEmpty(updatePasswordViewModel)) {
            return mockRejectApiCall("There was an error while attempting to update your password.");
        }
        return mockResolveApiCall(true, apiConstants.successMessage.user.UPDATE_PASSWORD);
    }

    static validateUpdatePasswordLink(updatePasswordToken) {
        if (isLinkExpired(updatePasswordToken)) {
            return mockRejectApiCall("Reset password link is expired");
        }
        return mockResolveApiCall(true); 
    }
}

const PasswordApi = config.environment.MODE === config.modes.SERVER ? ServerPasswordApi : MockPasswordApi; 
export default PasswordApi;
