import PropTypes from "prop-types";
import {
    Button,
    checkAndSetError,
    clearInputFormErrorFields, convertInputFormToObject,
    InputForm,
    inputFormIsValid,
    useInputForm
} from "../../../Components/Inputs";
import {GridCell} from "../../../Components/Grid";
import React from "react";
import {OrderRequestForm} from "./OrderRequestForm";
import {isStartBeforeEnd} from "../../../Utilities/Types/dateUtilities";
import {ORDER_TYPE} from "./orderRequestConstants";

export const OrderRequestView = ({
                                            shippingLocations,
                                            publishers,
                                            handleReturn,
                                            handleSubmitOrderRequest,
                                            students,
                                            orderRequestDetails
                                        }) => {

    const orderRequestForm = useInputForm(orderRequestDetails);

    const isEditing = false;
    const handleSubmitClick = () => {
        clearInputFormErrorFields(orderRequestForm);

        const isNeedFromAfterNeedToDate = !isStartBeforeEnd(orderRequestForm.needFrom.value, orderRequestForm.needTo.value)
        const verifyFromAndToDate = orderRequestForm.needFrom.value && orderRequestForm.needTo.value;
        if (verifyFromAndToDate && isNeedFromAfterNeedToDate) {
            orderRequestForm.needFrom.setError("Item need from date must be before Item need to date.");
            orderRequestForm.needTo.setError("Item need to date must be after Item need from date.");
        }

        checkAndSetError([
            orderRequestForm.shippingLocationId,
            orderRequestForm.studentId,
            orderRequestForm.itemName,
            orderRequestForm.isbn,
            orderRequestForm.format,
            orderRequestForm.copyright,
        ]);

        if (orderRequestDetails.orderType === ORDER_TYPE.SpecialOrderRequest)
            checkAndSetError([orderRequestForm.needFrom]);

        if (inputFormIsValid(orderRequestForm)) {
            const orderRequest = convertInputFormToObject(orderRequestForm);
            handleSubmitOrderRequest(orderRequest);
        }
    }

    return <>
        <InputForm name={`orderRequestForm`}>
            <OrderRequestForm
                orderRequestForm={orderRequestForm}
                shippingLocations={shippingLocations}
                publishers={publishers}
                students={students}/>
            <GridCell className={`text-center`}>
                <Button
                    disabled={isEditing}
                    isPrimary isCollapsed
                    label={'Save'}
                    name={`btnSubmit`}
                    onClick={handleSubmitClick}/>
                <Button
                    disabled={isEditing}
                    isCollapsed
                    label={'Cancel'}
                    name={`btnCancel`}
                    onClick={handleReturn}/>
            </GridCell>
        </InputForm>
    </>;

};

OrderRequestView.propTypes = {
    shippingLocations: PropTypes.array.isRequired,
    publishers: PropTypes.array.isRequired,
    handleReturn: PropTypes.func.isRequired,
    handleSubmitOrderRequest: PropTypes.func.isRequired,
    orderRequestDetails: PropTypes.object.isRequired,
    students: PropTypes.array.isRequired
};