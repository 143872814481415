import React, {useState} from "react";
import PropTypes from "prop-types";
import {
    Button,
    checkAndSetError,
    clearInputFormErrorFields,
    convertInputFormToObject,
    InputForm,
    inputFormIsValid,
    NumberField,
    SelectField,
    TextField,
    useInputForm,
} from "../../Components/Inputs";
import {GridCell, gridConstants} from "../../Components/Grid";
import {AVAILABILITY_LIST, FORMAT_LIST} from "./libraryConstants";
import {sortArrayOfObjectsByProperty} from "../../Utilities/Types/arrayUtilities";
import {LibraryItemCopy} from "./LibraryItemCopy";
import {emptyFunction} from "../../Utilities/testingUtilities";

export const LibraryItemFormatView = ({
                                          handleSaveResource,
                                          handleReturn,
                                          format,
                                          publishers,
                                          title,
                                          vendors,
                                      }) => {

    const formatForm = useInputForm(format);
    const [editCopyId, setEditCopyId] = useState("");
    const isEditing = !!editCopyId;

    const handleDone = (data) => {

        let updatedCopies = [...formatForm.copies.value]
            .filter(c => c.copyId !== data.copyId);
        updatedCopies.push(data);

        updatedCopies = sortArrayOfObjectsByProperty(updatedCopies, "number");
        formatForm.copies.setValue(updatedCopies);

        setEditCopyId("");
    }

    const handleSubmitClick = () => {
        clearInputFormErrorFields(formatForm);

        checkAndSetError([
            formatForm.numberOfVolumes,
        ]);

        if (inputFormIsValid(formatForm)) {
            const dirtyResource = convertInputFormToObject(formatForm);
            handleSaveResource(dirtyResource);
        }
    }

    return <>
        <InputForm name={`formatForm`}>
            <TextField disabled label={"Title"} medium_columns={gridConstants.column.SIX} name={"title"}
                       onChange={emptyFunction} value={title}/>
            <SelectField {...formatForm.format} disabled medium_columns={gridConstants.column.SIX} options={FORMAT_LIST}/>
            <SelectField {...formatForm.availability} medium_columns={gridConstants.column.SIX}
                         options={AVAILABILITY_LIST}/>
            <NumberField isPositiveNumberOnly isWholeNumberOnly {...formatForm.numberOfVolumes}
                         medium_columns={gridConstants.column.SIX} minValue={1}/>


            <hr/>


            <SelectField {...formatForm.publisherId} medium_columns={gridConstants.column.SIX} label={`Publisher`}
                         options={publishers}/>
            <SelectField {...formatForm.vendorId} medium_columns={gridConstants.column.SIX} label={`Vendor`}
                         options={vendors}/>
            <NumberField {...formatForm.cost} isPositiveNumberOnly medium_columns={gridConstants.column.SIX}/>

            <hr/>
            <NumberField {...formatForm.numberOfCopiesToAdd} isPositiveNumberOnly isWholeNumberOnly
                         medium_columns={gridConstants.column.SIX}/>
            <GridCell>
                <LibraryItemCopy
                    editCopyId={editCopyId}
                    handleDone={handleDone}
                    isEditing={isEditing}
                    itemForm={formatForm}
                    setEditCopyId={setEditCopyId}
                />
            </GridCell>

            <hr/>
            <GridCell className={`text-center`}>
                <Button disabled={isEditing} isPrimary isCollapsed label={'Save'} name={`btnSubmit`}
                        onClick={handleSubmitClick}/>
                <Button disabled={isEditing} isCollapsed label={'Cancel'} name={`btnCancel`} onClick={handleReturn}/>
            </GridCell>
        </InputForm>
    </>;
};

LibraryItemFormatView.propTypes = {
    handleSaveResource: PropTypes.func.isRequired,
    handleReturn: PropTypes.func.isRequired,
    format: PropTypes.object.isRequired,
    publishers: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    vendors: PropTypes.array.isRequired,
};