import PropTypes from "prop-types";
import {Button, DateTimePickerField, SelectField, TextArea, TextField} from "../../../Components/Inputs";
import {GridCell, gridConstants} from "../../../Components/Grid";
import React from "react";
import {FORMAT_LIST, GRADE_LEVEL_LIST} from "../../Library/libraryConstants";

export const OrderRequestForm = ({
                                            shippingLocations,
                                            orderRequestForm,
                                            publishers,
                                            students
                                        }) => {

    const handleAddOrRemoveLocationsClick = () => {
    }

    return <>
        <SelectField
            {...orderRequestForm.shippingLocationId}
            medium_columns={gridConstants.column.SIX}
            label={`Active Location List`}
            options={shippingLocations}/>

        <SelectField
            {...orderRequestForm.studentId}
            medium_columns={gridConstants.column.SIX}
            label={`Student`}
            options={students}/>

        <GridCell medium_columns={gridConstants.column.SIX}>
            <Button
                label="Add/Remove Locations"
                name="btnAddOrRemoveLocations"
                onClick={handleAddOrRemoveLocationsClick}
                isPrimary
            />
        </GridCell>

        <hr/>

        <TextField
            {...orderRequestForm.itemName}
            medium_columns={gridConstants.column.SIX}
            helpText={`<p>Please enter a book title, device, or supply description.</p>`}
        />

        <TextField
            {...orderRequestForm.isbn}
            medium_columns={gridConstants.column.SIX}
        />

        <DateTimePickerField
            {...orderRequestForm.needFrom}
            medium_columns={gridConstants.column.SIX}/>

        <DateTimePickerField
            {...orderRequestForm.needTo}
            medium_columns={gridConstants.column.SIX}/>

        <SelectField
            {...orderRequestForm.format}
            medium_columns={gridConstants.column.SIX}
            label={`Medium Requested`}
            options={FORMAT_LIST}/>

        <SelectField
            {...orderRequestForm.publisherId}
            medium_columns={gridConstants.column.SIX}
            label={`Publisher`}
            options={publishers}/>

        <DateTimePickerField
            {...orderRequestForm.copyright}
            medium_columns={gridConstants.column.SIX}/>

        <TextField
            {...orderRequestForm.author}
            medium_columns={gridConstants.column.SIX}/>

        <TextField
            {...orderRequestForm.seriesTitle}
            medium_columns={gridConstants.column.SIX}/>

        <DateTimePickerField
            {...orderRequestForm.alternateCopyright}
            medium_columns={gridConstants.column.SIX}/>

        <SelectField
            {...orderRequestForm.gradeLevel}
            medium_columns={gridConstants.column.SIX}
            options={GRADE_LEVEL_LIST}/>

        <SelectField
            {...orderRequestForm.alternateFormat}
            medium_columns={gridConstants.column.SIX}
            label={`Alternate Medium`}
            options={FORMAT_LIST}/>

        <TextArea
            {...orderRequestForm.specialInstructions}
            medium_columns={gridConstants.column.SIX}/>
    </>;
};

OrderRequestForm.propTypes = {
    shippingLocations: PropTypes.array.isRequired,
    orderRequestForm: PropTypes.object.isRequired,
    publishers: PropTypes.array.isRequired,
    students: PropTypes.array.isRequired
};