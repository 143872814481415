import React from "react";
import PropTypes from "prop-types";

import UserDetails from "../../Components/User/UserDetails";
import {userDetailsModel} from "../../Components/User/userDetailsFactory";
import {PageTitle} from "../../Components/Landmarks";
import LayoutHero from "../../Components/Layout/LayoutHero";
import userApi from "../User/userApi";
import {locations} from "../../Utilities/Location";
import { jobTitles } from "../../SiteConfig/jobTitles";

export const RegisterContainer = ({
                               districts,
                               handleApiCall,
                               isLoading,
                               redirect,
                               setWarnings
                           }) => {
    const handleRegisterUser = (registerModel) => {
         handleApiCall(() => userApi.attemptRegister(registerModel), () => {
           redirect(locations.LANDING.path);
        })
    };

    if (!districts)
        return null;

    return (
        <LayoutHero>
            <PageTitle h1={`Register`}/>
            <UserDetails
                handleManageUser={handleRegisterUser}
                districts={districts}
                isLoading={isLoading}
                setWarnings={setWarnings}
                patron={userDetailsModel()}
                jobTitles={jobTitles}
            />
        </LayoutHero>
    );
};

RegisterContainer.propTypes = {
    handleApiCall: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    districts: PropTypes.array,
    redirect: PropTypes.func.isRequired,
    setWarnings: PropTypes.func.isRequired
};
