import PropTypes from "prop-types";
import React from "react";
import {HtmlDisplay} from "../Display";
import {helpTextSuffix} from "./inputConstants";

export const HelpText = ({
	                         helpText,
	                         responseFeedback,
	                         isCentered,
	                         name,
                         }) => {
	const responseFeedbackClass = responseFeedback ? `help-text--feedback` : ``;
	return <HtmlDisplay
		className={`help-text ${responseFeedbackClass} ${isCentered ? "text-center" : ""}`}
		isUpdatedLive
		html={responseFeedback || helpText} name={`${name}${helpTextSuffix}`}
	/>;
};

HelpText.propTypes = {
	helpText: PropTypes.string,
	responseFeedback: PropTypes.string,
	isCentered: PropTypes.bool,
	name: PropTypes.string.isRequired,
};