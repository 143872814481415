import {
    config,
    mockResolveApiCall,
    requestOptions,
    requestTypes,
    sendRequest
} from "../../../Utilities/Api";
import {studentData} from "../studentMockData";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerStudentApprovalApi {
    static getStudentApprovalRequests(studentSearchCriteria) {
        const queryParameters = new URLSearchParams(studentSearchCriteria).toString();
        return sendRequest(
            requestOptions(`${config.URL}students/patrons?${queryParameters}`,
                requestTypes.GET,
            )
        );
    }
    static approveStudents(approveAndSearchStudentsModel) {
        return sendRequest(
            requestOptions(config.URL + `students/status`,
                requestTypes.PUT,
                JSON.stringify(approveAndSearchStudentsModel),
            ),
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************

class MockStudentApprovalApi {
    static getStudentApprovalRequests() {

        return mockResolveApiCall( studentData, "");
    }

    static approveStudents() {
        return mockResolveApiCall( studentData, "");
    }
}

const StudentApprovalApi = config.environment.MODE === config.modes.SERVER ? ServerStudentApprovalApi : MockStudentApprovalApi;
export default StudentApprovalApi;
