import { animateScroll as scroll } from "react-scroll";
import shortid from "shortid";
import { isString } from "./Types/typeUtilities";

export const emptyGuid = "00000000-0000-0000-0000-000000000000";

export function isGuidEmpty(guid) {
	return !guid || guid === emptyGuid;
}

export function isTrimmedStringEmpty(value) {
	return !isString(value) || value.trim().length === 0;
}

export function isNullOrUndefined(value) {
	return value === null || value === undefined;
}

export function createRedirectUrl(pathName, searchString) {
	return searchString ? pathName + searchString : pathName;
}

export function convertCamelCaseToSpaceDelimited(string = "", makeProperCase = false) {
	if(isTrimmedStringEmpty(string))
		return "";

	if (makeProperCase) {
		string = string.charAt(0).toUpperCase() + string.substring(1);
    }

	const regex = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;
	return string.replace(regex, "$1$4 $2$3$5");
}

export function convertUnderscoreToDash(string){
	let result = string.replace(/__/gi, "- ");
	return result.replace(/_/gi, "-");
}

export function convertToNumber(value = "not a number") {
	if(isString(value))
		value = value.trim();

	const conversion = Number(value);
	return isNaN(conversion) ? undefined : conversion;
}

export function convertToBoolean(value = "not a bool") {
	if(isNullOrUndefined(value))
		return false;

	return value.toString().toLowerCase().trim() === "true";
}

export function createKey() {
    return shortid.generate();
}

export const scrollToTop = () => {
    scroll.scrollToTop({
        duration: 0,
        smooth: "easeOutQuint",
    });
};

export function getSiteProtocolDomain() {
	return `${document.location.origin}/#`;
}


export function numberOfDecimalPlaces(number = "") {
	const b = String(number).split(".");
	return b[1] ? b[1].length : 0;
}

export function validateDecimal(value = 0, digits = 2) {
	if (!value) return 0.00;
	return convertToNumber(`${value}`).toFixed(digits);
}