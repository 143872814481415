import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {PageTitle} from "../../Components/Landmarks";
import {LibraryItemFormatView} from "./LibraryItemFormatView";
import {locations} from "../../Utilities/Location";
import libraryCatalogApi from "./libraryCatalogApi";
import {createListFromArrayOfObjects} from "../../Utilities/Types/listUtilities";
import {resourceModel, formatModel} from "./libraryFactory";

export const LibraryItemFormatContainer = ({
                                               handleApiCall,
                                               catalogFormatId,
                                               catalogItemId,
                                               redirect,
                                           }) => {
    const [item, setItem] = useState();
    const [format, setFormat] = useState();
    const [vendors, setVendors] = useState();
    const [publishers, setPublishers] = useState();

    const handleReturn = () => redirect(locations.LIBRARY_CATALOG.path);

    const handleSaveResource = async (format) => {

        const shouldReturn = !format?.numberOfCopiesToAdd;

        const resetState = () => {
            setItem(null);
            setFormat(null);
        }

        return handleApiCall(() => libraryCatalogApi.saveFormat(format),
            shouldReturn ? handleReturn : resetState);
    };

    useEffect(() => {
        const getResource = () => {
            handleApiCall(() => libraryCatalogApi.getResource(catalogItemId), (results) => setItem(resourceModel(results)));
        }
        const getVendors = () => {
            handleApiCall(() => libraryCatalogApi.getVendors(), (results) =>
                setVendors(createListFromArrayOfObjects(results, "vendorId", "name")));
        }
        const getPublishers = () => {
            handleApiCall(() => libraryCatalogApi.getPublishers(), (results) =>
                setPublishers(createListFromArrayOfObjects(results, "publisherId", "name")));
        }

        const getFormat = () => {
            let result = item?.formats?.find(f => f.catalogFormatId === catalogFormatId);
            result = formatModel(result);

            setFormat(result);
        }

        if (catalogItemId && !item)
            getResource();
        if (catalogFormatId && item && !format)
            getFormat();
        if (!vendors)
            getVendors();
        if (!publishers)
            getPublishers();

    }, [catalogItemId, catalogFormatId, item]);

    if (!format) return <></>;

    return <>
        <PageTitle h1={`Library Item`}/>
        <LibraryItemFormatView
            handleSaveResource={handleSaveResource}
            handleReturn={handleReturn}
            format={format}
            publishers={publishers}
            title={item?.title}
            vendors={vendors}
        />
    </>;
};

LibraryItemFormatContainer.propTypes = {
    handleApiCall: PropTypes.func.isRequired,
    catalogFormatId: PropTypes.string.isRequired,
    catalogItemId: PropTypes.string.isRequired,
    redirect: PropTypes.func.isRequired,
};