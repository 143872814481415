import { act, render } from "@testing-library/react";
import React from "react";

export async function waitForComponentToPaint(callback = null) {
    await act(async () => {
        if(!callback)
            await new Promise((resolve) => setTimeout(resolve, 0));
        else
            callback();
    });
}

export async function renderContainer(Component, props = {}) {
    return render(<Component {...props} />);
}

export const buildTarget = (targetName, targetValue) => {
    return {
        target: { name: targetName, value: targetValue },
    };
};


export function emptyFunction() {
}

export function returnTrue() {
    return true;
}
export function returnFalse() {
    return false;
}

export function handleApiCall(apiCall, thenCallback = emptyFunction, catchCallback = emptyFunction) {
    apiCall()
        .then((result) => {
            thenCallback(result);
        })
        .catch((error) => {
            catchCallback(error);
        });
}
export function redirect() {}
export function fetchUser() {}
export function setErrors() {}
export function setNotificationBatchApiResults() {}
export function setWarnings() {}
export function setSuccesses() {}
export function setLoggedInTrue() {}
export function setLoggedInFalse() {}
export function fetchCourses() {}
export function fetchCatalog() {}
export function handleReturnRedirect() {}
export const history = {
    goBack: emptyFunction,
};
export function handleSetUserSearchCriteria() {}
export function handleSetStudentSearchCriteria() {}

