import {getFormatTextByValue, getGraveLevelTextByValue} from "./libraryUtility";

export const resourceModel = ({
                                  author = "",
                                  catalogId,
                                  copyright,
                                  description = "",
                                  formats,
                                  gradeLevel,
                                  isbn10,
                                  isbn13,
                                  numberOfCopiesToAdd = 0,
                                  previewIsbn,
                                  title = "",
                                  whatsNewEndDate,
                                  whatsNewStartDate,
                              } = {}) => ({
    author,
    catalogId,
    copyright,
    description,
    formats,
    gradeLevel,
    isbn10,
    isbn13,
    numberOfCopiesToAdd,
    previewIsbn,
    title,
    whatsNewEndDate,
    whatsNewStartDate,
});

const testResource = {
    author: "Test Author",
    copyright: "01/06/1981",
    description: "test description",
    gradeLevel: "2",
    isbn10: "1234567890",
    isbn13: "1234567890123",
    title: "test title",
};
export const testResourceModel = resourceModel(testResource);
export const emptyResourceModel = resourceModel();


export const formatModel = ({
                                availability = "",
                                catalogFormatId = "",
                                copies = [],
                                cost = 0,
                                format = 0,
                                formatName = "",
                                fundingSource = 0,
                                numberOfCopiesAvailable = 0,
                                numberOfCopiesToAdd = 0,
                                numberOfVolumes,
                                publisher = "",
                                publisherId,
                                purchaseOrderNumber = "",
                                shelfLocation = "",
                                vendor = "",
                                vendorId,
                            } = {}) => ({
    availability,
    catalogFormatId,
    copies,
    cost,
    format,
    formatName,
    fundingSource,
    numberOfCopiesAvailable,
    numberOfCopiesToAdd,
    numberOfVolumes: numberOfVolumes || 1,
    publisher,
    publisherId,
    purchaseOrderNumber,
    shelfLocation,
    vendor,
    vendorId,
});
export const emptyFormatModel = formatModel();

export const publisherModel = ({
                                   publisherId,
                                   name,
                               } = {}) => ({
    publisherId,
    name,
});
const testPublisher = {
    name: "Test Publisher",
};
export const emptyPublisherModel = publisherModel();
export const testPublisherModel = publisherModel(testPublisher);


export const vendorModel = ({
                                vendorId,
                                name,
                                email,
                                contactName,
                                phoneNumber,
                                faxNumber,
                                address,
                                city,
                                state,
                                zipCode,
                                website,
                            } = {}) => ({
    vendorId,
    name,
    email,
    contactName,
    phoneNumber,
    faxNumber,
    address,
    city,
    state,
    zipCode,
    website,
});
const testVendor = {
    name: "Test Vendor",
    email: "Vendor@vending.com",
    contactName: "Contact Vendor",
    phoneNumber: "123-456-7890",
    faxNumber: "098-765-4321",
    address: "123 Vending St",
    city: "Vendoropolis",
    state: "VN",
    zipCode: "12345",
    website: "www.vending.com",
};
export const emptyVendorModel = vendorModel();
export const testVendorModel = vendorModel(testVendor);


function createDetails({author, format, gradeLevel}) {

    let details = "";
    details += author ? `<div><b>Author:</b> ${author}</div>` : "";
    details += format ? `<div><b>Format:</b> ${getFormatTextByValue(format)}</div>` : "";
    details += gradeLevel ? `<div><b>Grade Level:</b> ${getGraveLevelTextByValue(gradeLevel)}</div>` : "";
    details += author || format || gradeLevel ? `<div class="margin--bottom"></div>` : '';
    return details ? details : '';
}

export const catalogItemModel = ({
                                     author,
                                     catalogId,
                                     description,
                                     formats,
                                     gradeLevel,
                                     title,
                                 } = {}) => ({
    catalogId,
    description: description ? `<p>${description}</p>` : "",
    details: createDetails({author, gradeLevel}),
    formats,
    title,
});


export const catalogQueueModel = ({
                                      author,
                                      catalogId,
                                      format,
                                      gradeLevel,
                                      title,
                                  } = {}) => ({
    catalogId,
    details: createDetails({author, format, gradeLevel}),
    title,
});