import {EditorTools} from "@progress/kendo-react-editor";

export const validateTelerikHtmlConversion = (value) => {
    let result = value.toString();
    result = result.replace(/<p>/gi, "");
    result = result.replace(/<\/p>/gi, "");
    result = result.replace(/<h6>/gi, "<p>");
    result = result.replace(/<\/h6>/gi, "</p>");

    return result;
};

export const convertToTelerikHtml = (value) => {
    let result = value.toString();
    result = result.replace(/<p>/gi, "<h6>");
    result = result.replace(/<\/p>/gi, "</h6>");

    return result;
};

// not included - FormatBlock, ForeColor, BackColor, FontSize, FontName, InsertImage, InsertFile, Print, Pdf,
const {
    Bold, Italic, Underline, Strikethrough, Subscript, Superscript,
    CleanFormatting,
    AlignLeft, AlignCenter, AlignRight, AlignJustify,
    Indent, Outdent, OrderedList, UnorderedList,
    Undo, Redo,
    Link, Unlink, ViewHtml,
    InsertTable,
    SelectAll,
    AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter,
    DeleteRow, DeleteColumn, DeleteTable,
    MergeCells, SplitCell
} = EditorTools;

//createToolsLayout({customFormatBlock, ContentWrapTool});
export function createToolsLayout(customTools) {
    return [
        [Bold, Italic, Underline, Strikethrough],
        [Subscript, Superscript],
        [CleanFormatting],
        [AlignLeft, AlignCenter, AlignRight, AlignJustify],
        [Indent, Outdent],
        [OrderedList, UnorderedList],
        ...customTools,
        [SelectAll],
        [Undo, Redo],
        [Link, Unlink, ViewHtml],
        [InsertTable],
        [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
        [DeleteRow, DeleteColumn, DeleteTable],
        [MergeCells, SplitCell]
    ]
}