import { isNullOrUndefined } from "../commonUtilities";
import {compareObjects, deepCopyObject, objectHasProperty} from "./objectUtilities";
import { isArray, isString } from "./typeUtilities";
import _ from "lodash";

export function isInArray(array, value) {
    return array.includes(value);
}

export function isArrayNullOrEmpty(array) {
    return isNullOrUndefined(array) || !(isArray(array) && array.length !== 0);
}

export function addUniqueValueToArray(array, value) {
    array = !array ? [] : convertToArray(array);

    if (!isInArray(array, value))
        array.push(value);

    return array;
}

export function convertToArray(unknownType, delimiter = ",") {
    return isNullOrUndefined(unknownType) ?
        [] :
        isArray(unknownType) ?
            unknownType :
            isString(unknownType) ?
                unknownType.split(delimiter) :
                [unknownType];
}

export function deepCopyArray(array=[]) {
    return deepCopyObject(array);
}

export function arraysEqual(a, b) {
    return compareObjects(a, b);
}

export function arraysOfObjectsEqual(a, b) {
    return compareObjects(a, b);
}

export function filterObjectArrayByField(array, filter, field) {
    if (!filter || isArrayNullOrEmpty(array)) return [];

    let result;
    const searchStrings = filter.toString().split(",").map((d) => d.trim());
    result = array.filter((ins) =>
        searchStrings.some(function(search) {
            return ins[field].toString() === search;
        }),
    );

    return result;
}

export function filterObjectArrayByAllFields(array, searchString) {
    if (isArrayNullOrEmpty(array)) return [];

    return array.filter(obj => {
        return Object.keys(obj).some(key => {
            if (typeof obj[key] === 'string') {
                const lowerCaseValue = obj[key].toLowerCase();
                const searchArray = searchString.toLowerCase().split(" ");
                return searchArray.every(str => lowerCaseValue.includes(str));
            }
            return false;
        });
    });
}

export function findObjectArrayByField(array, filter, field) {
    return filterObjectArrayByField(array, filter, field)[0];
}

export function createArrayFromObject(object) {
    let result = [];
    for (let property in object) {
        if (objectHasProperty(object, property)) {
            result.push(object[property]);
        }
    }
    return result;
}

export function optionIndexInArray(array, option = "", wholeWord = true) {
    const optionValue = option.toLowerCase();

    for (let i = 0; i < array.length; i++) {
        if(isNullOrUndefined(array[i]))
            continue;
        const arrayValue = array[i].toLowerCase();
        if ((wholeWord && arrayValue === optionValue) || (!wholeWord && arrayValue.startsWith(optionValue)))
            return i;
    }

    return -1;
}

export function partialOptionIndexInArray(array, option = "") {
    const optionValue = option.toLowerCase();

    for (let i = 0; i < array.length; i++) {
        const arrayValue = array[i].toLowerCase();
        if (optionValue.startsWith(arrayValue)) return i;
    }

    return -1;
}

export function getDistinctValuesByObjectProperty(array, property) {
    return [
        ...new Set(
            array.map((item) => {
                return item[property];
            }),
        ),
    ];
}

export function sortArrayOfObjectsByProperty(array, property) {
    return _.sortBy([...array], [property]);
}