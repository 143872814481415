import PropTypes from "prop-types";
import React from "react";
import { GridCell, gridConstants, GridContainer, GridFlexSpacer } from "../Grid";

export const LayoutSmall = ({ children, className }) => {
    return (
        <GridContainer>
            <GridFlexSpacer/>
            <GridCell
                className={className}
                medium_columns={gridConstants.column.EIGHT}
                large_columns={gridConstants.column.SIX}
            >
                {children}
            </GridCell>
            <GridFlexSpacer/>
        </GridContainer>
    );
};

LayoutSmall.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
};
