import React from "react";
import PropTypes from "prop-types";
import {ButtonIconEdit} from "../../Components/Inputs";
import {getCopyStatusTextByValue, getLocationTextByValue} from "./libraryUtility";

export const LibraryItemCopyDisplay = ({
                                           copy,
                                           handleEdit,
                                       }) => {
    console.log("display copy", copy);

    const isClickDisabled = !handleEdit;

    return <>
        <tr>
            <td className={`text-center`}>{copy.copyNumber}</td>
            <td>{copy.barCode}</td>
            <td>{getLocationTextByValue(copy.shelfLocation)}</td>
            <td>{getCopyStatusTextByValue(copy.status)}</td>
            <td className={`action-cell`}>
                <ButtonIconEdit
                    disabled={isClickDisabled}
                    inputDescription={`for copy ${copy.number}`}
                    onClick={()=>handleEdit(copy.copyId)}
                />
            </td>
        </tr>
    </>;
};

LibraryItemCopyDisplay.propTypes = {
    copy: PropTypes.object.isRequired,
    handleEdit: PropTypes.func,
};