import React from 'react';
import PropTypes from 'prop-types';
import {GridCell, gridConstants} from "../../Components/Grid";
import {Link} from "../Link";

export const Tile = ({
                         children,
                         medium_columns = gridConstants.column.SIX,
                         isClickable = true,
                         label,
                         to
                     }) => {

    const classes = isClickable ? `tile is-clickable` : `tile`;

    return <>
        <GridCell medium_columns={medium_columns}>
            <Link to={to} className={classes}>
                <div className={"tile__header"}>
                    {label}
                </div>
                <div className={"tile__content"}>
                    {children}
                </div>
            </Link>
        </GridCell>
    </>;
};

Tile.propTypes = {
    children: PropTypes.any,
    medium_columns : PropTypes.string,
    isClickable: PropTypes.bool,
    label: PropTypes.string,
    to: PropTypes.string
};
