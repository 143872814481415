import React from "react";
import {PageTitle} from "../../Components/Landmarks";
import LayoutHero from "../../Components/Layout/LayoutHero";
import {GridCell, gridConstants, GridContainer} from "../../Components/Grid";
import {LinkButton} from "../../Components/Link";
import {locations} from "../../Utilities/Location";
import PropTypes from "prop-types";

export const LandingContainer = ({isLoggedIn}) => {

    return <>
        <LayoutHero>
            <PageTitle h1={`Welcome to the FIMC-VI Portal`} className={`h2`}/>

            <p>The FIMC-VI Portal allows approved individuals to access the student registration system and borrow
                materials on behalf of students registered with FIMC-VI.
                {
                    !isLoggedIn &&
                    <>To request access to this Portal, use the Register button below. To access an existing account,
                        use the Sign in button.</>
                }

            </p>
            {
                !isLoggedIn &&
                <GridContainer hasMarginX hasMarginY>
                    <GridCell medium_columns={gridConstants.column.SIX}>
                        <LinkButton {...locations.REGISTER.link} isPrimary isExpanded/>
                    </GridCell>
                    <GridCell medium_columns={gridConstants.column.SIX}>
                        <LinkButton {...locations.SIGN_IN.link} isPrimary isExpanded/>
                    </GridCell>
                </GridContainer>
            }

        </LayoutHero>
    </>;
};

LandingContainer.propTypes = {
    isLoggedIn: PropTypes.bool
};
