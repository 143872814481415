import PropTypes from "prop-types";
import React, {useState, useEffect, useRef} from "react";
import GridCell from "../../Components/Grid/GridCell";
import {gridConstants} from "../Grid";
import {
    Button, CheckBoxField,
    clearInputFormErrorFields,
    convertInputFormToObject,
    EmailField,
    InputForm,
    RadioList,
    SelectField,
    SwitchFieldset,
    TextField,
    useInputForm,
} from "../Inputs";
import {reinitializeSpecificInputFormFields} from "../Inputs/inputUtility";
import {userDetailsModel} from "./userDetailsFactory";
import {createListFromArrayOfObjects} from "../../Utilities/Types/listUtilities";
import {UserDetailsPasswordFields} from "./UserDetailsPasswordFields";
import {
    canUserUpdateApprovalStatus,
    createPatronRoleId,
    displayDelegateCheckbox,
    getWarningMessageForUserDetailsForm,
    isUserDetailsFormValid
} from "./userDetailsUtilities";
import {isNullOrUndefined} from "../../Utilities/commonUtilities";
import {
    AGENCY_STAFF_MEMBER_TYPES_LIST,
    CHARTER_SCHOOL_EMPLOYEE_TYPES_LIST,
    CHARTER_TYPE,
    CHARTER_TYPE_LIST,
    DISTRICT_EMPLOYEE_TYPES_LIST,
    FIMC_SUB_TYPES_LIST,
    JOB_ROLES,
    OTHER_TYPES_LIST,
    PARENT_TYPES,
    PARENT_TYPES_LIST,
    PRIVATE_SCHOOL_EMPLOYEE_TYPES_LIST
} from "../../SiteConfig/jobTitles";
import {Allow, policyEvents} from "../Authorize";
import {APPROVAL_STATUS, APPROVAL_STATUS_LIST} from "../../Containers/Constants/approvalConstants";
import GridContainer from "../Grid/GridContainer";

const UserDetails = ({
                         askToUpdatePassword,
                         districts,
                         handleCancel,
                         handleManageUser,
                         isEditing = false,
                         jobTitles,
                         patron,
                         setWarnings,
                     }) => {

    const prevJobTitle = useRef(patron.jobTitle);
    const prevPatronSubType = useRef(patron.patronSubType);

    const userDetailsForm = useInputForm(userDetailsModel({...patron, ...{askToUpdatePassword}}));

    const [enablePasswordFields, setEnablePasswordFields] = useState(
        !userDetailsForm.askToUpdatePassword.value
    );

    const isFormValidAndCheckForWarning = () => {
        const isFormValid = isUserDetailsFormValid(userDetailsForm, setWarnings, enablePasswordFields, isEditing, patron);

        if (isFormValid && isEditing) {
            let warningMessages = getWarningMessageForUserDetailsForm(userDetailsForm, patron);

            if (warningMessages.length > 0) {
                const message = `Please note:\n\n${warningMessages.join('\n\n')} \n\nAre you sure you want to continue?`;
                return confirm(message);
            }
        }

        return isFormValid;
    };

    const handleClickSubmit = () => {
        if (isFormValidAndCheckForWarning()) {

            if (!isEditing)
                createPatronRoleId(userDetailsForm);
            else if (!displayDelegateCheckbox(userDetailsForm, patron))
                resetFormFields(["isDelegate"], "");

            const user = convertInputFormToObject(userDetailsForm);

            handleManageUser(user);
        }
    };

    const handleClickCancel = (event) => {
        event.preventDefault();
        handleCancel();
    }

    const handleUpdatePasswordCheck = () => {
        setEnablePasswordFields(!enablePasswordFields);
        userDetailsForm.updatePassword.setValue(
            !userDetailsForm.updatePassword.value
        );
    };

    const resetFormFields = (clearValuesForFields, newValue = "") => {
        reinitializeSpecificInputFormFields(userDetailsForm, clearValuesForFields, newValue);
        clearInputFormErrorFields(userDetailsForm);
    };

    useEffect(
        () => {

            if (userDetailsForm.approvalStatus.value !== APPROVAL_STATUS.Approved.toString()) {
                resetFormFields(["isDelegate"], "");
            }

            if (userDetailsForm.email.value === patron.email) {
                resetFormFields(["confirmEmail"]);
            }

            //clears the sub-forms when the job title changes
            if (!isNullOrUndefined(prevJobTitle.current) && prevJobTitle.current !== userDetailsForm.jobTitle.value) {

                resetFormFields(["patronSubType", "organization", "districtId"]);
                prevJobTitle.current = userDetailsForm.jobTitle.value;
            }

            //clears the sub-forms when the patronSubType changes
            if (!isNullOrUndefined(prevPatronSubType.current) && prevPatronSubType.current !== userDetailsForm.patronSubType.value) {

                resetFormFields(["organization", "districtId", "charterStatus"]);
                prevPatronSubType.current = userDetailsForm.patronSubType.value;
            }
        },
        [userDetailsForm.jobTitle.value, userDetailsForm.patronSubType.value, userDetailsForm.email.value, userDetailsForm.approvalStatus.value]
    );

    return (
        <InputForm name={"userDetails"}>

            <TextField {...userDetailsForm.firstName} />

            <TextField {...userDetailsForm.lastName} />

            <EmailField {...userDetailsForm.email} helpText={""}/>

            {
                isEditing && userDetailsForm.email.value !== patron.email &&
                <EmailField {...userDetailsForm.confirmEmail}
                            helpText={`<p style="color: red;">Please note: If you change your email address, you won't be able to log in using your old email. Please use your new email address to access your account.</p>`}/>
            }

            <SelectField
                {...userDetailsForm.jobTitle}
                defaultOption={"Select a Job Title"}
                includeDefaultOption={true}
                options={createListFromArrayOfObjects(jobTitles, "jobID", "jobTitle")}
                label={"Job Title"}
            />

            {
                userDetailsForm.jobTitle.value === JOB_ROLES.Parent &&
                <>
                    <SelectField
                        {...userDetailsForm.patronSubType}
                        defaultOption={"Select a Role"}
                        includeDefaultOption={true}
                        options={PARENT_TYPES_LIST}
                        label={"Role"}
                    />
                    {
                        userDetailsForm.patronSubType.value === PARENT_TYPES.Private.id || userDetailsForm.patronSubType.value === PARENT_TYPES.Charter.id
                            ? <TextField {...userDetailsForm.organization} />
                            : userDetailsForm.patronSubType.value === PARENT_TYPES.Public.id
                                ? <SelectField
                                    {...userDetailsForm.districtId}
                                    defaultOption={"Select a District"}
                                    includeDefaultOption={true}
                                    options={createListFromArrayOfObjects(
                                        districts,
                                        "id",
                                        "districtName"
                                    )}
                                    label={"District"}
                                />
                                : null
                    }
                </>
            }

            {
                userDetailsForm.jobTitle.value === JOB_ROLES.District &&
                <>
                    <SelectField
                        {...userDetailsForm.patronSubType}
                        defaultOption={"Select a Role"}
                        includeDefaultOption={true}
                        options={DISTRICT_EMPLOYEE_TYPES_LIST}
                        label={"Role"}
                    />

                    {
                        userDetailsForm.patronSubType.value
                            ? <SelectField
                                {...userDetailsForm.districtId}
                                defaultOption={"Select a District"}
                                includeDefaultOption={true}
                                options={createListFromArrayOfObjects(
                                    districts,
                                    "id",
                                    "districtName"
                                )}
                                label={"District"}
                            />
                            : null
                    }

                </>
            }

            {
                userDetailsForm.jobTitle.value === JOB_ROLES.Charter &&
                <>
                    <SelectField
                        {...userDetailsForm.patronSubType}
                        defaultOption={"Select a Role"}
                        includeDefaultOption={true}
                        options={CHARTER_SCHOOL_EMPLOYEE_TYPES_LIST}
                        label={"Role"}
                    />

                    {
                        userDetailsForm.patronSubType.value
                            ? <RadioList
                                {...userDetailsForm.charterType}
                                legend={`Please choose if you are an employee for a Charter LEA or Charter School:`}
                                options={CHARTER_TYPE_LIST}
                            />
                            : null
                    }

                    {
                        userDetailsForm.charterType.value === CHARTER_TYPE.CharterSchool.id
                            ? <>
                                <SelectField
                                    {...userDetailsForm.districtId}
                                    defaultOption={"Select a District"}
                                    includeDefaultOption={true}
                                    options={createListFromArrayOfObjects(
                                        districts,
                                        "id",
                                        "districtName"
                                    )}
                                    label={"District"}
                                />

                                <TextField
                                    {...userDetailsForm.organization}
                                />
                            </>
                            : userDetailsForm.charterType.value === CHARTER_TYPE.CharterLEA.id
                                ? <SelectField
                                    {...userDetailsForm.districtId}
                                    defaultOption={"Select a District"}
                                    includeDefaultOption={true}
                                    options={createListFromArrayOfObjects(
                                        districts,
                                        "id",
                                        "districtName"
                                    )}
                                    label={"District"}
                                />
                                : null
                    }
                </>
            }

            {
                userDetailsForm.jobTitle.value === JOB_ROLES.Private &&
                <>
                    <SelectField
                        {...userDetailsForm.patronSubType}
                        defaultOption={"Select a Role"}
                        includeDefaultOption={true}
                        options={PRIVATE_SCHOOL_EMPLOYEE_TYPES_LIST}
                        label={"Role"}
                    />

                    {
                        userDetailsForm.patronSubType.value
                            ? <TextField
                                {...userDetailsForm.organization}
                            />
                            : null
                    }
                </>
            }

            {
                userDetailsForm.jobTitle.value === JOB_ROLES.Agency &&
                <>
                    <SelectField
                        {...userDetailsForm.patronSubType}
                        defaultOption={"Select a Role"}
                        includeDefaultOption={true}
                        options={AGENCY_STAFF_MEMBER_TYPES_LIST}
                        label={"Role"}
                    />

                    {
                        userDetailsForm.patronSubType.value
                            ? <TextField
                                {...userDetailsForm.organization}
                            />
                            : null
                    }
                </>
            }

            {
                userDetailsForm.jobTitle.value === JOB_ROLES.Fimc &&
                <>
                    <SelectField
                        {...userDetailsForm.patronSubType}
                        defaultOption={"Select a Role"}
                        includeDefaultOption={true}
                        options={FIMC_SUB_TYPES_LIST}
                        label={"Role"}
                    />
                </>
            }

            {
                userDetailsForm.jobTitle.value === JOB_ROLES.Other &&
                <>
                    <SelectField
                        {...userDetailsForm.patronSubType}
                        defaultOption={"Select a Role"}
                        includeDefaultOption={true}
                        options={OTHER_TYPES_LIST}
                        label={"Role"}
                    />
                    {
                        userDetailsForm.patronSubType.value
                            ? <TextField
                                {...userDetailsForm.organization}
                            />
                            : null
                    }
                </>
            }

            {
                !isEditing &&
                <>
                    {
                        userDetailsForm.askToUpdatePassword.value &&
                        <SwitchFieldset
                            {...userDetailsForm.updatePassword}
                            onChange={handleUpdatePasswordCheck}
                            label={`enable password update`}
                            legend={`Update Password`}
                        >
                            <UserDetailsPasswordFields
                                enablePasswordFields={enablePasswordFields}
                                userDetailsForm={userDetailsForm}
                            />
                        </SwitchFieldset>
                    }
                    {
                        !userDetailsForm.askToUpdatePassword.value &&
                        <UserDetailsPasswordFields
                            enablePasswordFields={enablePasswordFields}
                            userDetailsForm={userDetailsForm}
                        />
                    }
                </>
            }

            {
                isEditing &&
                <>
                    <SelectField
                        {...userDetailsForm.approvalStatus}
                        includeDefaultOption
                        options={APPROVAL_STATUS_LIST}
                        defaultOptionValue={""}
                        defaultOption={"Select an approval status"}
                        disabled={!canUserUpdateApprovalStatus(patron)}
                    />

                    {
                        displayDelegateCheckbox(userDetailsForm, patron) &&
                        <Allow policyEvent={policyEvents.DELEGATE_USERS}>
                            <CheckBoxField
                                {...userDetailsForm.isDelegate}
                                checked={!!userDetailsForm.isDelegate.value}
                                label={"By checking this box, you authorize this user to act as your delegate. They will have the ability to approve new patrons on your behalf."}
                                value={userDetailsForm.isDelegate.value ? "" : "true"}
                            />
                        </Allow>
                    }
                </>
            }

            <GridCell>
                <GridContainer className={`horizontal-center`} hasMarginX hasMarginY>
                    <>
                        <GridCell small_columns={isEditing ? gridConstants.column.SIX : gridConstants.column.TWELVE}>
                            <Button
                                isPrimary
                                label={`Submit`}
                                name={`btnSubmit`}
                                onClick={handleClickSubmit}
                            />
                        </GridCell>

                        {
                            isEditing &&
                            <GridCell medium_columns={gridConstants.column.SIX}>
                                <Button
                                    isPrimary
                                    label={`Cancel`}
                                    name={`btnCancel`}
                                    onClick={handleClickCancel}
                                />
                            </GridCell>
                        }
                    </>
                </GridContainer>
            </GridCell>
        </InputForm>
    );
};

UserDetails.propTypes = {
    askToUpdatePassword: PropTypes.bool,
    districts: PropTypes.arrayOf(PropTypes.object),
    handleCancel: PropTypes.func,
    handleManageUser: PropTypes.func.isRequired,
    isEditing: PropTypes.bool,
    jobTitles: PropTypes.array,
    navigation: PropTypes.object,
    patron: PropTypes.object.isRequired,
    setWarnings: PropTypes.func.isRequired,
};

export default UserDetails;
