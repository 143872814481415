import {ORDER_TYPE} from "./orderRequestConstants";

export const orderRequestModel = ({
                                             shippingLocationId,
                                             alternateCopyright,
                                             alternateFormat,
                                             author,
                                             catalogFormatId,
                                             copyright,
                                             createdDate,
                                             gradeLevel,
                                             isbn,
                                             needFrom,
                                             needTo,
                                             format,
                                             orderType = ORDER_TYPE.SpecialOrderRequest,
                                             publisherId,
                                             seriesTitle,
                                             specialInstructions,
                                             studentId,
                                             itemName
                                         } = {}) => ({
    shippingLocationId,
    alternateCopyright,
    alternateFormat,
    author,
    catalogFormatId,
    copyright,
    createdDate,
    gradeLevel,
    isbn,
    needFrom,
    needTo,
    format,
    orderType,
    publisherId,
    itemName,
    seriesTitle,
    specialInstructions,
    studentId
});