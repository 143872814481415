import {searchModel} from "../../Utilities/Search";
import {isArrayNullOrEmpty} from "../../Utilities/Types/arrayUtilities";
import {dynamicSort} from "../../Utilities/Sort";

export const userSearchModel = (userId, fullName = "", email = "", organization = "", districtId = "", isActive = "true", patronRole = "", patronSubType = "", approvalStatus = "0", showOnlyDelegates = "") => {
    return Object.assign({}, searchModel(userId.toString(), "email", false, "email"), {
        fullName,
        email,
        organization,
        districtId,
        isActive,
        patronRole,
        patronSubType,
        approvalStatus,
        showOnlyDelegates,
        ClearUserSearch: clearUserSearch,
        ApplySearchAndSorting: applySearchAndSorting,
    });
};

function clearUserSearch() {
    this.fullName = "";
    this.email = "";
    this.organization = "";
    this.districtId = "";
    this.isActive = "";
    this.patronRole = "";
    this.patronSubType = "";
    this.approvalStatus = "";
    this.showOnlyDelegates = "";
    this.ClearSearch(false);
}

 function applySearchAndSorting(userSearchCriteria, users) {

    if (isArrayNullOrEmpty(users))
        return users;

    return users.sort(dynamicSort(userSearchCriteria.sortByColumn, userSearchCriteria.sortDescending));
}

