export const userDetailsModel = ({
                                     approvalStatus,
                                     askToUpdatePassword,
                                     charterType,
                                     districtId,
                                     email,
                                     firstName,
                                     isActive,
                                     isDelegate,
                                     lastName,
                                     organization,
                                     patronRole = ``,
                                     patronSubType = ``,
                                     userId
                                 } = {}) => ({

    approvalStatus: approvalStatus?.toString() ?? ``,
    askToUpdatePassword: !!askToUpdatePassword,
    charterType: charterType?.toString() ?? ``,
    confirmPassword: "",
    districtId,
    email,
    confirmEmail: ``,
    firstName,
    isActive,
    isDelegate: (isDelegate === null || isDelegate === ``) ? false: isDelegate,
    jobTitle: patronRole?.toString() ?? ``,
    lastName,
    organization,
    password: "",
    patronRole,
    patronSubType: patronSubType?.toString() ?? ``,
    updatePassword: false,
    userId
});