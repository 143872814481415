import {isNullOrUndefined, isTrimmedStringEmpty} from "../commonUtilities";

export function getTodayDate() {
    return new Date();
}

export function getTodayDateAsString() {
    const currentDate = getTodayDate();
    return `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;
}

export function convertToDate(dateString, includeTime = false) {
    if (isNullOrUndefined(dateString) || isTrimmedStringEmpty(dateString))
        return null;

    const dateParts = dateString.split('-');
    if (dateParts.length === 3 && !includeTime) {
        const datePart0 = parseInt(dateParts[0]);
        const datePart1 = parseInt(dateParts[1]);
        const datePart2 = parseInt(dateParts[2]);
        if (dateParts[0].length === 4)
            return new Date(datePart0, datePart1 - 1, datePart2, 0, 0, 0, 0);

        return new Date(datePart2, datePart0 - 1, datePart1, 0, 0, 0, 0);
    }

    return new Date(dateString);
}

export function convertDateToPrintString(date = getTodayDate()) {
    const options = {year: 'numeric', month: 'long', day: 'numeric'};
    return new Date(date).toLocaleDateString('en-US', options);
}

export function convertToUrlDateString(dateString) {
    const defaultDate = "1/1/2020";
    if (!dateString)
        return defaultDate;

    if (dateString.indexOf(":") === -1)
        dateString += " 12:00"

    const theDate = new Date(dateString);

    if (isNaN(theDate) || !dateString) return defaultDate;

    return `${theDate.getMonth() + 1}-${theDate.getDate()}-${theDate.getFullYear()}`;
}

export function convertToDateTimeString(dateString, includeTime = false, useMilitaryTime = false) {
    const theDate = convertToDate(dateString, includeTime);

    if (!theDate || isNaN(theDate) || !dateString) return "";

    const dateOutput = `${theDate.getMonth() + 1}/${theDate.getDate()}/${theDate.getFullYear()}`;

    if (!includeTime)
        return dateOutput;

    const timeString = convertDateObjectToTimeString(theDate, useMilitaryTime);

    return `${dateOutput} ${timeString}`;
}

export function convertToTimeString(timeString, useMilitaryTime) {
    if (!timeString || timeString === "")
        return "";

    const defaultDate = "1/1/2020";
    const timeStringStr = timeString.indexOf(":") === -1 ? `${timeString}:00` : timeString;

    const theDate = new Date(`${defaultDate} ${timeStringStr}`);
    if (isNaN(theDate)) return "";

    return convertDateObjectToTimeString(theDate, useMilitaryTime);
}

function convertDateObjectToTimeString(dateObj, useMilitaryTime) {
    let hours = dateObj.getHours();
    let am_pm = "AM";
    let minutes = dateObj.getMinutes();

    let minutesStr = minutes;
    if (minutes < 10) minutesStr = `0${minutes}`;

    let hourStr = hours;
    if (hours < 10) hourStr = `0${hourStr}`;

    if (useMilitaryTime)
        return `${hourStr}:${minutesStr}`;

    if (hours >= 12) am_pm = "PM";
    if (hours > 12) hours = hours - 12;
    if (hours === 0) hours = 12;

    return `${hours}:${minutesStr} ${am_pm}`;
}

export function convertToDateTimePickerFormat(dateString, enableTime = true) {
    const theDate = convertToDate(dateString, enableTime);
    if (!theDate || isNaN(theDate) || !dateString) return "";

    let time = "";
    if (enableTime) time = ` ${convertDateObjectToTimeString(theDate, true)}`;

    return `${theDate.getFullYear()}-${theDate.getMonth() + 1}-${theDate.getDate()}${time}`;
}

export function isDateBetween(date, start = new Date(), end = new Date()) {
    if (!date) return false;
    return Date.parse(date) >= Date.parse(start) && Date.parse(date) <= Date.parse(end);
}

export function isStartBeforeEnd(start, end) {
    if (!start || !end) return false;
    return Date.parse(start) <= Date.parse(end);
}

