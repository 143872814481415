import React from "react";
import PropTypes from "prop-types";
import {Tile} from "../../../Components/Tile/Tile";
import {locations} from "../../../Utilities/Location";
import {gridConstants, GridContainer} from "../../../Components/Grid";

export const StudentRegistrationDuplicateStudentsView = ({
                                                             duplicateStudents,
                                                         }) => {
    return <>
        <GridContainer hasMarginX hasMarginY className={`dashboard`}>
            {
                duplicateStudents.map((student, index) => {
                    return (
                        <Tile
                            key={index}
                            to={locations.EDIT_STUDENT.getPath(student.studentId)}
                            medium_columns={duplicateStudents.length === 1 ? `` : gridConstants.column.SIX}
                            label={student.fullName}>
                            <>
                                <p>{`Date of Birth: ${student.dateOfBirth}`}</p>
                                <p>{`Grade Level: ${student.gradeLevelDescription}`}</p>
                                <p>{`District: ${student.districtName}`}</p>
                            </>
                        </Tile>
                    );
                })
            }
        </GridContainer>
    </>;
}
StudentRegistrationDuplicateStudentsView.propTypes = {
    duplicateStudents: PropTypes.array,
}
