import {convertToBoolean, convertToNumber} from "../commonUtilities";
import {isString} from "../Types/typeUtilities";
import * as storageTypes from "./storageTypes";
import store from "store";
import {ROLES} from "../../Components/Authorize/roleConstants";

export function isLoggedIn() {
    const userIdentity = store.get(storageTypes.CURRENT_USER);
    return isString(userIdentity);
}

export function isAdmin() {
    const roleId = convertToNumber(store.get(storageTypes.ROLE_ID));
    return (roleId === ROLES.FimcAdmin) || (roleId === ROLES.SystemAdmin);
}

export function isFimcAdmin() {
    const roleId = convertToNumber(store.get(storageTypes.ROLE_ID));
    return roleId === ROLES.FimcAdmin;
}

export function getRoleId() {
    return convertToNumber(store.get(storageTypes.ROLE_ID));
}

export function getFullName() {
    return store.get(storageTypes.FULL_NAME);
}

export function getCanUserAssignDelegate() {
    return convertToBoolean(store.get(storageTypes.CAN_ASSIGN_DELEGATE));
}

export function updateFullName(firstName, lastName) {
    store.set(storageTypes.FULL_NAME, `${firstName}  ${lastName}`);
}

export function persistLogin(userIdentity, email, fullName, roleId, canAssignDelegate) {
    store.set(storageTypes.CURRENT_USER, userIdentity);
    store.set(storageTypes.EMAIL, email);
    store.set(storageTypes.FULL_NAME, fullName);
    store.set(storageTypes.ROLE_ID, roleId);
    store.set(storageTypes.CAN_ASSIGN_DELEGATE, canAssignDelegate);
}

export function desistLogin() {
    store.remove(storageTypes.CURRENT_USER);
    store.remove(storageTypes.EMAIL);
    store.remove(storageTypes.FULL_NAME);
    store.remove(storageTypes.ROLE_ID);
}

export function getLoggedInUser() {
    return store.get(storageTypes.CURRENT_USER);
}

const storageUtilitiesProps = {isLoggedIn, isAdmin, getRoleId, persistLogin, desistLogin, getLoggedInUser, updateFullName};
export default storageUtilitiesProps;