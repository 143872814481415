import {
    apiConstants,
    config,
    mockResolveApiCall,
    requestOptions,
    requestTypes,
    sendRequest
} from "../../../Utilities/Api";
import {userData} from "../userMockData";

//************************************************** STANDARD API SERVER CALLS ****************************************


class ServeUserApprovalApi {

    static getUsers(userSearchCriteria) {
        const queryParameters = new URLSearchParams(userSearchCriteria).toString();
        return sendRequest(
            requestOptions(config.URL + `patrons?${queryParameters}`,
                requestTypes.GET,
            ),
        );
    }

    static approveUsers(users) {
        return sendRequest(
            requestOptions(config.URL + `patrons`,
                requestTypes.PUT,
                JSON.stringify(users),
            ),
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************

class MockUserApprovalApi {
    static getUsers() {
        return mockResolveApiCall( userData, "");
    }

    static approveUsers() {
        return mockResolveApiCall("", apiConstants.successMessage.approval.APPROVAL_UPDATED);
    }
}

const UserApprovalApi = config.environment.MODE === config.modes.SERVER ? ServeUserApprovalApi : MockUserApprovalApi;
export default UserApprovalApi;
