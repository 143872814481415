import PropTypes from "prop-types";
import React from "react";
import {isString} from "../../Utilities/Types/typeUtilities";
import {HtmlDisplay} from "../Display";
import {DisplayLayout} from "./DisplayLayout";

export const DisplayField = ({
                                 children,
                                 displayClass,
                                 label,
                                 isScrolling,
                                 medium_columns,
                                 name,
                                 value,
                                 isDisabled = true
                             }) => {
    const htmlValue = children || value;
    const isHtml = isString(htmlValue);
    const overflowClass = isScrolling ? "input__text--scroll" : "input__text--wrap";
    const disabledClass = isDisabled ? `is-disabled` : ``;
    const classes = `input__text ${disabledClass} ${displayClass} ${overflowClass}`;

    return (
        <DisplayLayout medium_columns={medium_columns} label={label} name={`${name}_container`}>
            {
                isHtml &&
                <HtmlDisplay className={classes} name={name} html={htmlValue}/>
            }
            {
                !isHtml &&
                <div id={name} data-testid={name} className={classes}>
                    {children}
                </div>
            }
        </DisplayLayout>
    );
};

DisplayField.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.bool,
        PropTypes.object,
        PropTypes.string,
        PropTypes.number,
    ]),
    displayClass: PropTypes.string,
    isScrolling: PropTypes.bool,
    label: PropTypes.string.isRequired,
    medium_columns: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    isDisabled: PropTypes.bool
};