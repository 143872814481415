import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {locations} from "../../Utilities/Location";
import passwordApi from "./passwordApi";
import PasswordUpdateView from "./PasswordUpdateView";
import LayoutHero from "../../Components/Layout/LayoutHero";

export const PasswordUpdateContainer = ({
                                     handleApiCall,
                                     isLoading,
                                     redirect,
                                     setWarnings,
                                     updatePasswordToken,
                                 }) => {

    const [linkValidated, setLinkValidated] = useState(false);

    const handleUpdatePassword = (password) => {
        handleApiCall(() => passwordApi.updatePassword({password, updatePasswordToken: updatePasswordToken}),
            redirect(locations.SIGN_IN.path));
    };

    const validateUpdatePasswordLink = () => {
        handleApiCall(() => passwordApi.validateUpdatePasswordLink(updatePasswordToken),
            setLinkValidated,
            setLinkValidated(false));
    }

    useEffect(() => {
        if (!linkValidated)
            validateUpdatePasswordLink();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <LayoutHero>
            <PasswordUpdateView
                handleUpdatePassword={handleUpdatePassword}
                isLoading={isLoading}
                setWarnings={setWarnings}
                isLinkExpired={!linkValidated}/>
        </LayoutHero>
    );
};

PasswordUpdateContainer.propTypes = {
    handleApiCall: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    redirect: PropTypes.func.isRequired,
    setWarnings: PropTypes.func.isRequired,
    updatePasswordToken: PropTypes.string.isRequired,
};
