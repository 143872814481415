import {createObjectFromObjectPropertyNames} from "../../Utilities/Types/objectUtilities";
import {createListFromObject} from "../../Utilities/Types/listUtilities";

export const APPROVAL_STATUS = {
    Pending: 0,
    Approved: 1,
    NotApproved: 2
};

export const APPROVAL_STATUS_NAMES = createObjectFromObjectPropertyNames(APPROVAL_STATUS);

export const APPROVAL_STATUS_LIST = createListFromObject(APPROVAL_STATUS);

export function getApprovalStatusList() {
    return createListFromObject(APPROVAL_STATUS);
}

export function getApprovalStatusName(approvalStatus) {
    switch (approvalStatus) {
        case APPROVAL_STATUS.Pending:
            return `pending`;
        case APPROVAL_STATUS.Approved:
            return `approved`;
        case APPROVAL_STATUS.NotApproved:
            return "not-approved";
        default:
            return `pending`;
    }
}
