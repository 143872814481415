export const districts = [
    {id: "ca3a9a97-4ff2-4d2d-2953-08dc12bfa24d", districtName: 'Alachua'},
    {id: "12e07d86-de65-4bb2-2954-08dc12bfa24d", districtName: 'Baker'},
    {id: "ca3a9a97-4ff2-4d2d-2953-08dc12bfa24d", districtName: 'Bay'},
    {id: "ca3a9a97-4ff2-4d2d-2953-08dc12bfa24e", districtName: 'Bradford'},
    {id: "ca3a9a97-4ff2-4d2d-2953-08dc12bfa24f", districtName: 'Brevard'},
    {id: "ca3a9a97-4ff2-4d2d-2953-08dc12bfa24g", districtName: 'Broward'},
    {id: "ca3a9a97-4ff2-4d2d-2953-08dc12bfa24h", districtName: 'Calhoun'},
    {id: "ca3a9a97-4ff2-4d2d-2953-08dc12bfa24i", districtName: 'Charlotte'},
    {id: "ca3a9a97-4ff2-4d2d-2953-08dc12bfa24j", districtName: 'Citrus'},
    {id: "ca3a9a97-4ff2-4d2d-2953-08dc12bfa24dk", districtName: 'Clay'},
    {id: "ca3a9a97-4ff2-4d2d-2953-08dc12bfa24dl", districtName: 'Collier'},
    {id: "ca3a9a97-4ff2-4d2d-2953-08dc12bfa24m", districtName: 'Columbia'},
    {id: "ca3a9a97-4ff2-4d2d-2953-08dc12bfa24n", districtName: 'DeSoto'},
    {id: "ca3a9a97-4ff2-4d2d-2953-08dc12bfa24o", districtName: 'Dixie'},
    {id: "ca3a9a97-4ff2-4d2d-2953-08dc12bfa24p", districtName: 'Duval'},
    {id: "ca3a9a97-4ff2-4d2d-2953-08dc12bfa24q", districtName: 'Escambia'},
    {id: "ca3a9a97-4ff2-4d2d-2953-08dc12bfa24r", districtName: 'Flagler'},
    {id: "ca3a9a97-4ff2-4d2d-2953-08dc12bfa24s", districtName: 'Gadsden'},
    {id: "ca3a9a97-4ff2-4d2d-2953-08dc12bfa24t", districtName: 'Gulf'}
];

