import {
    apiConstants,
    config,
    mockResolveApiCall,
    requestOptions,
    requestTypes,
    sendRequest
} from "../../../Utilities/Api";
import {studentData} from "../studentMockData";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerStudentRegistrationApi {
    static attemptRegister(studentModel) {
        // todo save clears out not used options?
        return sendRequest(
             requestOptions(`${config.URL}students`,
                 requestTypes.POST,
                 JSON.stringify(studentModel),
                 apiConstants.successMessage.student.REGISTER
             ),
         );
    }

    static updateStudent(studentModel) {
        return sendRequest(
             requestOptions(`${config.URL}students`,
                 requestTypes.PUT,
                 JSON.stringify(studentModel),
                 apiConstants.successMessage.student.UPDATE
             ),
         );
    }

    static getDuplicateStudents(studentModel) {
        const queryParameters = new URLSearchParams(studentModel).toString();
        return sendRequest(
             requestOptions(`${config.URL}students/duplicates?${queryParameters}`,
                 requestTypes.GET,
             ),
         );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************

class MockStudentRegistrationApi {
    static attemptRegister() {
        return mockResolveApiCall( studentData, "");
    }

    static updateStudent() {
        return mockResolveApiCall( studentData, "");
    }

    static getDuplicateStudents() {
        return mockResolveApiCall( [], "");
    }

}

const StudentRegistrationApi = config.environment.MODE === config.modes.SERVER ? ServerStudentRegistrationApi : MockStudentRegistrationApi;
export default StudentRegistrationApi;
