import PropTypes from "prop-types";
import React from "react";
import { HeaderBasic } from "./HeaderBasic";
import { Main } from "./Main";
import { HeaderNav } from "./HeaderNav";
import { GridContainer, GridFlexSpacer } from "../Grid";

const TemplateBasic = (props) => {
    const { children } = props;
    return (
        <>
            <noscript>You need to enable JavaScript to run this app.</noscript>
            <HeaderBasic {...props} />
            <HeaderNav {...props} /> 
            <Main>
                <GridContainer hasMarginY>
                    <GridFlexSpacer>
                        {children}
                    </GridFlexSpacer>
                </GridContainer>
            </Main>
        </>
    );
};


export const TemplateBasicHeader = (props) => TemplateBasic({ ...props });


const props = {
    children: PropTypes.any,
};

TemplateBasicHeader.propTypes = props;
