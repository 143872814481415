import PropTypes from "prop-types";
import React, {useLayoutEffect} from "react";
import {locations} from "../../Utilities/Location";
import userApi from "../User/userApi";
import {handleApiCall} from "../../Utilities/Api/callbackUtilities";

export function SignOutContainer ({redirect, setLoggedInFalse}) {

    useLayoutEffect(() => {
        setLoggedInFalse();
        handleApiCall(() => userApi.attemptLogout());
        redirect(locations.LANDING.path);
    }, [redirect, setLoggedInFalse]);

    return <p>You have been successfully signed out. If you are not redirected you can close this page anytime.</p>;
}

SignOutContainer.propTypes = {
    redirect: PropTypes.func.isRequired,
    setLoggedInFalse: PropTypes.func.isRequired,
};