import FileSaver from "file-saver";
import {api as config} from "../../../SiteConfig/siteConstants";

const blobRequestExport = (notificationFactory, maxRetryCount= config.RETRIES, retryTimeout = 150) => {
	async function send(ajaxRequestOptions) {
		try {
			const fetchOptions = {
				method: ajaxRequestOptions.type,
				headers: ajaxRequestOptions.headers,
				body: ajaxRequestOptions.data
			};

			for (let i = 0; i < maxRetryCount; i++) {
				const response = await fetch(ajaxRequestOptions.url, fetchOptions);

				if (response.ok) {
					const blob = await response.blob();

					const url = new URL(ajaxRequestOptions.url);
					const pathname = url.pathname;
					const filename = pathname.substring(pathname.lastIndexOf('/') + 1);
					FileSaver.saveAs(blob, filename);

					if (ajaxRequestOptions.successMessage) {
						notificationFactory.createSuccess(ajaxRequestOptions.successMessage).notify();
					}

					return blob;
				} else {
					if (i < maxRetryCount - 1) {
						await new Promise(resolve => setTimeout(resolve, retryTimeout)); // Pause before retry
					} else {
						const errorText = await response.text();
						notificationFactory.createErrorsFromXhrObject(errorText).notify();
					}
				}
			}
		} catch (error) {
			notificationFactory.createError("Error fetching data").notify(); // Handle error
			throw error;
		}
	}

	return { send };
};

export default blobRequestExport;
