import { matchPath } from "react-router-dom";
import { createArrayFromObject } from "../Types/arrayUtilities";
import { isArray } from "../Types/typeUtilities";
import {allLocations, locationBehaviors, params} from "./locationConstants";
import {emptyGuid} from "../commonUtilities";

const exception = () => {
    throw new Error("Method getUrl not implemented.");
};

export function createLocation(getPath = exception, label, behaviors = []) {
    let result = { getPath };
    result.path = getPath();
    result.getParams = (currentPath) => getParams(currentPath, result.path);
    result.label = label;
    result.link = createLink(label, result.path);
    result.getLink = (params) => getLink(params, label, getPath);

    const allBehaviors = createArrayFromObject(locationBehaviors);
    for (let i = 0; i < allBehaviors.length; i++)
        result[allBehaviors[i]] = behaviors.includes(allBehaviors[i]);

    return result;
}

function getLink(params, label, getPath) {
    return isArray(params)
        ? createLink(label, getPath(...params))
        : createLink(label, getPath(params));
}

function getParams(currentPath, routeToMatch) {
    const matchedInfo = matchPath(currentPath, {
        path: routeToMatch,
        exact: false,
        strict: false,
    });

    return matchedInfo ? matchedInfo.params : null;
}

function createLink(label = "", path = "") {
    let to = path;

    for (let key in params) {
        const param = params[key];
        to = to.replace(param, emptyGuid);
    }

    return { label, to };
}

export function createEmptyLink() {
    return createLink();
}

export function getCurrentLocation(currentPath) {
    return allLocations.find(f => f.path === currentPath);
}