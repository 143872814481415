import {apiConstants, config, requestOptions, requestTypes, sendRequest} from "../../../Utilities/Api";

//************************************************** STANDARD API SERVER CALLS ****************************************
class serverOrderRequestApi {

    static getResource(catalogId)
    {
        return sendRequest(
            requestOptions(config.URL + `catalog/${catalogId}`,
                requestTypes.GET,
            ),
        );
    }

    static getShippingLocations()
    {
        return sendRequest(
            requestOptions(config.URL + `shipping-locations`,
                requestTypes.GET,
            ),
        );
    }

    static getStudents(studentSearchCriteria) {
        const queryParameters = new URLSearchParams(studentSearchCriteria).toString();
        return sendRequest(
            requestOptions(`${config.URL}students/patrons?${queryParameters}`,
                requestTypes.GET,
            )
        );
    }

    static getPublishers()
    {
        return sendRequest(
            requestOptions(config.URL + `catalog/publishers`,
                requestTypes.GET,
            ),
        );
    }

    static addOrder(orderRequestModel) {
        return sendRequest(
            requestOptions(`${config.URL}order`,
                requestTypes.POST,
                JSON.stringify(orderRequestModel),
                apiConstants.successMessage.order.ADD
            ),
        );
    }
}

const orderRequestApi = config.environment.MODE === config.modes.SERVER ? serverOrderRequestApi : serverOrderRequestApi;
export default orderRequestApi;