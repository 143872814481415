import {clearInputFormErrorFields, inputFormIsValid} from "../Inputs";
import {
    CHARTER_TYPE,
    DISTRICT_EMPLOYEE_TYPES,
    JOB_ROLES,
    PARENT_TYPES,
    PRIVATE_SCHOOL_EMPLOYEE_TYPES
} from "../../SiteConfig/jobTitles";
import {isNullOrUndefined, isTrimmedStringEmpty} from "../../Utilities/commonUtilities";
import {isValidEmail} from "../Inputs/inputUtility";
import {APPROVAL_STATUS} from "../../Containers/Constants/approvalConstants";
import {
    getCanUserAssignDelegate,
    getLoggedInUser
} from "../../Utilities/LocalStorage/storageUtilities";
import {allow, policyEvents} from "../Authorize";

export function isUserDetailsFormValid(userDetailsForm, setWarnings, enablePasswordFields, isEditing, patron) {
    clearInputFormErrorFields(userDetailsForm);

    if (isEditing) {
        checkAndSetError([
            userDetailsForm.email,
            userDetailsForm.lastName,
            userDetailsForm.firstName,
            userDetailsForm.jobTitle,
        ]);

        if (userDetailsForm.confirmEmail.value === '' && userDetailsForm.email.value !== patron.email)
            userDetailsForm.email.setError("Email must match confirmation field.");
        else if (userDetailsForm.confirmEmail.value !== '' && userDetailsForm.confirmEmail.value !== userDetailsForm.email.value)
            userDetailsForm.email.setError("Email must match confirmation field.");
    } else {
        checkAndSetError([
            userDetailsForm.confirmPassword,
            userDetailsForm.password,
            userDetailsForm.email,
            userDetailsForm.lastName,
            userDetailsForm.firstName,
            userDetailsForm.jobTitle,
        ]);

        if (userDetailsForm.password.value !== userDetailsForm.confirmPassword.value && enablePasswordFields)
            userDetailsForm.password.setError("Password must match confirmation field.");
    }

    if (userDetailsForm.jobTitle.value) {
        if (userDetailsForm.patronSubType.value) {
            switch (userDetailsForm.jobTitle.value) {
                case JOB_ROLES.Parent:
                    if (userDetailsForm.patronSubType.value === PARENT_TYPES.Public.id) {
                        checkAndSetError(
                            [userDetailsForm.districtId],
                            "District is required"
                        );
                        break;
                    } else if (userDetailsForm.patronSubType.value === PARENT_TYPES.Homeschool.id) {
                        break;
                    }
                    checkAndSetError([userDetailsForm.organization]);
                    break;
                case JOB_ROLES.District:
                    checkAndSetError(
                        [userDetailsForm.districtId],
                        "District is required"
                    );
                    break;
                case JOB_ROLES.Charter:
                    if (userDetailsForm.charterType.value === CHARTER_TYPE.CharterLEA.id) {
                        checkAndSetError(
                            [userDetailsForm.districtId],
                            "District is required"
                        );
                    } else {
                        checkAndSetError(
                            [userDetailsForm.districtId],
                            "District is required"
                        );
                        checkAndSetError([userDetailsForm.organization]);
                    }
                    break;
                case JOB_ROLES.Private:
                case JOB_ROLES.Agency:
                case JOB_ROLES.Other:
                    checkAndSetError([userDetailsForm.organization]);
                    break;
                default:
                    break;
            }
        } else userDetailsForm.patronSubType.setError("Role is required");
    }

    let isValid = inputFormIsValid(userDetailsForm);

    if (userDetailsForm.password.error === "Password must match confirmation field.") {
        setWarnings([
            "The password fields do not match. Please review your responses and try submitting again.",
        ]);
    } else if (!isValid)
        setWarnings([
            "Missing required items.  Please review your responses and try submitting again.",
        ]);

    return isValid;
}


export function getWarningMessageForUserDetailsForm(userDetailsForm, patron){

    let warningMessages = [];

    if (userDetailsForm.email.value !== patron.email)
        warningMessages.push(`Changing your email address will require you to log in with your new email.`);

    if (userDetailsForm.patronRole.value !== patron.patronRole)
        warningMessages.push(`Changing your job title will update your approval status to 'Pending,' and you will not be able to log back in until you are approved.`);

    if (userDetailsForm.patronSubType.value !== patron.patronSubType)
        warningMessages.push(`Changing your role will update your approval status to 'Pending,' and you will not be able to log back in until you are approved.`);

    if (userDetailsForm.organization.value !== patron.organization)
        warningMessages.push(`Changing your organization will update your approval status to 'Pending,' and you will not be able to log back in until you are approved.`);

    if (userDetailsForm.districtId.value !== patron.districtId)
        warningMessages.push(`Changing your district will update your approval status to 'Pending,' and you will not be able to log back in until you are approved.`);

    return warningMessages;
}


export function createPatronRoleId(userDetailsForm) {
    let patronRole = userDetailsForm.jobTitle.value;
    let subtypeKey = userDetailsForm.patronSubType.value;
    let charterTypeKey = userDetailsForm.charterType.value;

    if (charterTypeKey) charterTypeKey = ", " + charterTypeKey;
    patronRole = `${patronRole}, ${subtypeKey}${charterTypeKey}`;

    userDetailsForm.patronRole.setValue(patronRole);

    return userDetailsForm;
}

function checkAndSetError(fields, message) {
    fields.forEach((field) => {
        if (isTrimmedStringEmpty(field.value) && !message) {
            field.setError(`${field.label} is required.`);
        } else if (isTrimmedStringEmpty(field.value) && message) {
            field.setError(`${message}.`);
        } else if (field.name === "email" && !isValidEmail(field.value)) {
            field.setError("Email is not valid.");
        }
    });
}

function canBeADelegate(userDetailsForm) {

    const districtPersonnelPublic = (userDetailsForm.jobTitle.value === JOB_ROLES.District && userDetailsForm.patronSubType.value === DISTRICT_EMPLOYEE_TYPES.DistrictPersonnel.id);
    const schoolPersonnelPrivate = (userDetailsForm.jobTitle.value === JOB_ROLES.Private && userDetailsForm.patronSubType.value === PRIVATE_SCHOOL_EMPLOYEE_TYPES.SchoolPersonnel.id);
    const schoolPersonnelCharter = (userDetailsForm.jobTitle.value === JOB_ROLES.Charter && userDetailsForm.patronSubType.value === PRIVATE_SCHOOL_EMPLOYEE_TYPES.SchoolPersonnel.id);

    return districtPersonnelPublic || schoolPersonnelPrivate || schoolPersonnelCharter;
}

export function displayDelegateCheckbox(userDetailsForm, patron) {

    if (!isNullOrUndefined(patron) && isLoggedInUserSameAsPatron(patron))
        return false;

    return getCanUserAssignDelegate() && canBeADelegate(userDetailsForm) && (userDetailsForm.approvalStatus.value === APPROVAL_STATUS.Approved.toString());
}

function isLoggedInUserSameAsPatron(patron) {
    return patron.userId === getLoggedInUser();
}

export function canUserUpdateApprovalStatus(patron) {
    return allow(policyEvents.APPROVE_USERS) && !isLoggedInUserSameAsPatron(patron) && patron.isActive;
}