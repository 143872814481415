import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {PageTitle} from "../../Components/Landmarks";
import {LibraryVendorView} from "./LibraryVendorView";
import {locations} from "../../Utilities/Location";
import libraryCatalogApi from "./libraryCatalogApi";
import {emptyVendorModel} from "./libraryFactory";
import {createListFromArrayOfObjects} from "../../Utilities/Types/listUtilities";
import {emptyGuid} from "../../Utilities/commonUtilities";
import {SelectField} from "../../Components/Inputs";

export const LibraryVendorContainer = ({
                                         handleApiCall,
                                         libraryVendorId,
                                         redirect,
                                     }) => {
    const [vendor, setVendor] = useState();
    const [vendors, setVendors] = useState();
    const vendorList = createListFromArrayOfObjects(vendors, "vendorId", "name");

    const handleChangeVendor = (event) => {
        const path = locations.LIBRARY_VENDOR.getLink(event.target.value).to;
        redirect(path);
    }
    const handleReturn = () => redirect(locations.LIBRARY_CATALOG.path);

    const handleSaveVendor = async (vendor) => {
        return handleApiCall(() => libraryCatalogApi.saveVendor(vendor),
            handleReturn);
    };

    useEffect(() => {
        const getVendors = () => {
            handleApiCall(() => libraryCatalogApi.getVendors(), (results) => setVendors(results));
        }

        const getVendor = () => {
            const vendor = vendors?.find(v => v.vendorId === libraryVendorId) || emptyVendorModel;
            setVendor(vendor);
        }

        if (!vendors)
            getVendors();
        else if (libraryVendorId)
            getVendor();

    }, [vendors, libraryVendorId]);

    if (!vendor) return <></>;

    return <>
        <PageTitle h1={`Manage Vendors`}/>
        <SelectField
            defaultOption={"-- Create New Vendor --"}
            defaultOptionValue={emptyGuid}
            label={"Select Vendor to Manage"}
            name={'VendorList'}
            onChange={handleChangeVendor}
            options={vendorList}
            value={libraryVendorId}
        />
        <LibraryVendorView
            handleSaveVendor={handleSaveVendor}
            handleReturn={handleReturn}
            vendor={vendor}
        />
    </>;
};

LibraryVendorContainer.propTypes = {
    handleApiCall: PropTypes.func.isRequired,
    libraryVendorId: PropTypes.string.isRequired,
    redirect: PropTypes.func.isRequired,
};