import React from "react";
import { GridCell, gridConstants, GridContainer } from "../Grid";
import { ExternalLink } from "../Link";



const Footer = () => {

    return (
        <footer className={`footer`}>
            <GridContainer hasMarginX hasMarginY>
                <GridCell medium_columns={gridConstants.column.FOUR} className={`footer__info`}>
                    <div className={`h2`}>FIMC-VI</div>
                    <address>
                        <ExternalLink label={`Visit the FIMC-VI website`} url={`//fimcvi.org`} hasNoIcon /><br />
                        4716 W Montgomery Ave<br />
                        Tampa, FL 33616<br />
                        (813) 837-7826
                    </address>
                    <div className={`footer__socials`}>
                        
                        <ExternalLink label={`Facebook`} url={`//facebook.com/FIMCVI/`} className={'-icon -icon--facebook'} hasNoIcon ={false}/>
                        <ExternalLink label={`Youtube`} url={`//youtube.com/channel/UCXZVnlN1KzMPYraHAjxDtRw`}className={'-icon -icon--youtube'} hasNoIcon ={false}/>
                        <ExternalLink label={`X`} url={`//twitter.com/FIMCVI`} className={'-icon -icon--twitter'} hasNoIcon ={false}/>

                    </div>
                </GridCell>
                <GridCell medium_columns={gridConstants.column.EIGHT} className={`footer__copyright`}>
                    <p>Copyright© 2021 Florida Department of Education, Bureau of Exceptional Education and Student
                        Services (FDOE/BEESS), and the Florida Instructional Materials Center for the Visually Impaired
                        (FIMC-VI). All rights reserved.</p>
                    <p>FIMC-VI is funded by the State of Florida, Department of Education, Division of Public Schools
                        and Community Education, Bureau of Exceptional Education and Student Services through federal
                        assistance under the Individuals with Disabilities Education Act (IDEA), Part B and IDEA Part B
                        Trust funds.</p>
                </GridCell>
            </GridContainer>
        </footer>
    );
};

export default React.memo(Footer);
