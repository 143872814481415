// *********** NOTE - CSS is maintained in CDN for date picker.  Any issues, make sure that file does not need to be updated! **************
import React from "react";
import PropTypes from "prop-types";
import labelPlugin from "flatpickr/dist/plugins/labelPlugin/labelPlugin.js";
import DateTimePicker from "react-flatpickr";
import {
    convertToDateTimePickerFormat,
    convertToDateTimeString,
    convertToTimeString,
} from "../../Utilities/Types/dateUtilities";
import {DisplayField, InputClear} from ".";
import {DateTimePickerType} from "./inputConstants";
import {InputLayout} from "./InputLayout";
import {createFakeEvent} from "./inputUtility";

export const DateTimePickerField = ({
                                        ariaLabel = "",
                                        disabled = false,
                                        dateTimePickerType = DateTimePickerType.Calendar,
                                        error,
                                        label,
                                        labelClass,
                                        large_columns,
                                        medium_columns,
                                        name,
                                        onChange,
                                        showClear = true,
                                        value = "",
                                    }) => {

    const flatPickrDisplayTimeFormat = "h:i K";
    const flatPickrDisplayDateFormat = "m/d/Y";
    const invalidDate = "Invalid date";
    const isValidDate = !!value && value !== invalidDate;
    const enableTime = dateTimePickerType !== DateTimePickerType.Calendar;
    const noCalendar = dateTimePickerType === DateTimePickerType.TimeOnly;
    const altFormat = noCalendar ? flatPickrDisplayTimeFormat : enableTime ? `${flatPickrDisplayDateFormat} ${flatPickrDisplayTimeFormat}` : flatPickrDisplayDateFormat;

    const handleChange = (dateObject, dateString) => {
        dateString = !value && noCalendar
            ? getInitialTimeOnlyValueHack()
            : dateString;

        onChange(createFakeEvent(dateString, name));
    };

    const getInitialTimeOnlyValueHack = () => {
        return "12:00";
    };

    const clearDate = () => {
        onChange(createFakeEvent("", name));
    };

    const options = {
        altFormat: altFormat,
        allowInput: true,
        altInput: true,
        noCalendar: noCalendar,
        enableTime: enableTime,
        onChange: handleChange,
        onClose: handleChange,
        plugins: [labelPlugin()],
        onReady: (a, b, fp) => {
            fp.altInput.setAttribute("aria-label", ariaLabel ? ariaLabel : label);
            fp.altInput.setAttribute("autocomplete", `off`);
        }
    };

    let formatDateTime = null;
    if (isValidDate) {
        const useMilitaryTime = !disabled;
        formatDateTime = noCalendar
            ? convertToTimeString(value, useMilitaryTime)
            : disabled
                ? convertToDateTimeString(value, enableTime)
                : convertToDateTimePickerFormat(value, enableTime);
    }

    const editBar = !disabled && showClear && isValidDate
        ? <InputClear
            inputDescription={`date for ${label}`}
            onClick={clearDate}
        />
        : <></>;

    const result = disabled ?
        <DisplayField
            displayClass={`datepicker-container`}
            label={label}
            name={name}
            value={formatDateTime}
        />
        :
        <InputLayout
            disabled={disabled}
            error={error}
            label={label}
            labelClass={labelClass}
            large_columns={large_columns}
            medium_columns={medium_columns}
            name={name}
        >
            <div className={`datepicker-container`}>
                <DateTimePicker id={name} value={formatDateTime} options={options} />
                {editBar}
            </div>
        </InputLayout>

    return result;
};

DateTimePickerField.propTypes = {
    dateTimePickerType: PropTypes.number,
    disabled: PropTypes.bool,
    showClear: PropTypes.bool,
    error: PropTypes.string,
    large_columns: PropTypes.string,
    medium_columns: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    labelClass: PropTypes.string,
    ariaLabel: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default DateTimePickerField;
