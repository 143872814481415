import PropTypes from "prop-types";
import React from "react";
import { getButtonClasses } from "../Inputs/inputUtility";
import { Link } from "./Link";

export const LinkButton = ({ describedby, isExpanded, isLarge, isPrimary, label, to }) => {
    const className = getButtonClasses({ isExpanded, isPrimary, isLarge });

    return <Link describedby={describedby} to={to} label={label} className={className}/>;
};

export const TertiaryLinkButton = ({ isExpanded, isLarge, label, to }) => {
	const className = getButtonClasses({ isTertiary: true, isExpanded, isLarge });

	return <Link to={to} label={label} className={className}/>;
};

const sharedProps = {
    describedby: PropTypes.string,
    isExpanded: PropTypes.bool,
    isLarge: PropTypes.bool,
    isPrimary: PropTypes.bool,
    label: PropTypes.any,
    to: PropTypes.string.isRequired,
};


const buttonsProps = {
	isPrimary: PropTypes.bool,
}

LinkButton.propTypes = {
	...buttonsProps,
	...sharedProps
};

TertiaryLinkButton.propTypes = {
	...sharedProps,
};