import PropTypes from "prop-types";
import { useEffect } from "react";
import { error, info, processNotify, success, warning, clear } from "./notifyUserUtility";

export const NotifyUser = ({ pathUrl, errors, infos, successes, warnings, setErrors, setInfos, setSuccesses, setWarnings }) => {
	useEffect(() => {
		processNotify(errors, error, setErrors);
	}, [errors, setErrors]);

	useEffect(() => {
		processNotify(infos, info, setInfos);
	}, [infos, setInfos]);

	useEffect(() => {
		processNotify(successes, success, setSuccesses);
	}, [successes, setSuccesses]);

	useEffect(() => {
		processNotify(warnings, warning, setWarnings);
	}, [warnings, setWarnings]);

	useEffect(() => {
		clear(true);
	}, [pathUrl]);

	return null;
};

NotifyUser.propTypes = {
	pathUrl: PropTypes.string,
	errors: PropTypes.array,
	infos: PropTypes.array,
	successes: PropTypes.array,
	warnings: PropTypes.array,
	setErrors: PropTypes.func,
	setInfos: PropTypes.func,
	setSuccesses: PropTypes.func,
	setWarnings: PropTypes.func
};