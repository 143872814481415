import React from "react";
import { GridCell, GridContainer } from "../Grid";

export const HeaderBasic = () => {

    return (
        <header className="header">
            <GridContainer hasMarginX>
                <GridCell>
                    <span>FIMC-VI <span className="media-library">Portal</span></span>
                </GridCell>
            </GridContainer>
        </header>
    );
};
export default React.memo(HeaderBasic);

