import React from "react";
import { ContextPropsProvider } from "./Components/StateManager";
import { providers } from "./Components/StateManager/Context";
import "./robots.txt";
import '@progress/kendo-theme-material/dist/all.css';
import {AppRouter} from "./SiteConfig/Routes/AppRouter";

import "./sass/main.scss";


function App() {
  return (
      <ContextPropsProvider providers={providers}>
        <AppRouter/>
      </ContextPropsProvider>
  );
}

export default App;
