import PropTypes from "prop-types";
import React, {createContext, useCallback, useContext, useEffect, useState} from "react";
import {useCounter} from "../../../Utilities/Reducer";
import {emptyFunction, returnFalse, returnTrue} from "../../../Utilities/Api/callbackUtilities";
import { districtsApi } from "../../../SiteConfig";


export const AppContext = createContext([]);
export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({children}) => {
    const calls = useCounter();
    const [isLoading, setIsLoading] = useState(false);
    const [returnUrl, setReturnUrl] = useState(undefined);
    const [districts, setDistricts] = useState([]);

    const handleApiCall = useCallback((apiCall, thenCallback = returnTrue, catchCallback = returnFalse, useCounter = true) => {
        if (useCounter)
            calls.increment();

        return new Promise((resolve) => {
            const resolveCall = (result) => {
                if (useCounter)
                    calls.decrement();

                resolve(thenCallback(result));
            };
            const rejectCall = (error) => {
                if (useCounter)
                    calls.decrement();

                resolve(catchCallback(error));
            };

            apiCall()
                .then(
                    result => resolveCall(result),
                    error => rejectCall(error),
                )
                .catch(error => rejectCall(error));
        });
    },[calls]);

    const handleApiCallWithNoDim = (apiCall, thenCallback = emptyFunction, catchCallback = emptyFunction) => {
        return handleApiCall(apiCall, thenCallback, catchCallback, false);
    };

    useEffect(() => {
        if (isLoading && calls.count === 0)
            setIsLoading(false);
        if (!isLoading && calls.count > 0)
            setIsLoading(true);
        if (calls.count < 0)
            calls.reset();
    }, [calls, calls.count, isLoading]);

    useEffect(() => {
        const fetchDistricts = () => {
            return handleApiCall(districtsApi.getDistricts, setDistricts);
        };

        fetchDistricts().then(() => {});
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AppContext.Provider
            value={{
                isLoading,
                handleApiCall,
                handleApiCallWithNoDim,
                returnUrl,
                setReturnUrl,
                districts
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

AppContextProvider.propTypes = {
    children: PropTypes.any,
};
