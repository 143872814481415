import PropTypes from "prop-types";
import React, {useCallback, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Footer from "../../Components/Layout/Footer";
import {Loading} from "../../Components/Loading";
import {NotifyUser} from "../../Components/Notifications";
import {ContextPropsConsumer} from "../../Components/StateManager";
import {contexts, useAppContext, useUserContext} from "../../Components/StateManager/Context";
import {scrollToTop} from "../../Utilities/commonUtilities";
import {locations} from "../../Utilities/Location";
import {TemplateBasicHeader} from "../../Components/Layout";
import {Authorize} from "../../Components/Authorize";
import {headerNavProps} from "../../Components/Layout/HeaderNav";

export const PageSetup = (props) => {

    let {
        component: Component,
        path,
        template: Template = TemplateBasicHeader,
    } = props;

    const navigate = useNavigate();
    const location = useLocation();

    const handleReturnRedirect = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    const redirect = useCallback((route, replace = false) => {
        navigate(route, {replace});
    }, [navigate]);

    const pathUrl = location.pathname;
    const pathSearch = location.search;

    const {isLoadingCss, handleApiCall} = useAppContext();
    const {isLoggedIn, user, fetchUser} = useUserContext();


    path = !path ? locations.NOT_FOUND.path : path;

    const contextProps = {
        handleReturnRedirect,
        location,
        navigate,
        path,
        pathUrl,
        pathSearch,
        redirect,
        user,
        fetchUser,
        isLoggedIn,
        handleApiCall
    };

    useEffect(scrollToTop, [location.pathname]);

    if (isLoadingCss) return null;

    return <>
        <Template {...props} {...contextProps}>
            <ContextPropsConsumer contexts={contexts} props={contextProps}>
                {Loading}
                {NotifyUser}
                {Authorize}
                {Component}
            </ContextPropsConsumer>
        </Template>
        <Footer/>
    </>;
};

PageSetup.propTypes = {
    ...headerNavProps,
    component: PropTypes.func,
    path: PropTypes.string,
    template: PropTypes.func
};