import { useEffect } from "react";
import {DocumentTitleDefault} from "../../SiteConfig/siteConstants";

export function useDocumentTitle(title) {

    useEffect(() => {
        document.title = title ? title : DocumentTitleDefault;
    }, [title]);
}

export function refocusOnPageChange(extraScreenReaderContent) {
    const resetFocusItem = document.getElementsByTagName("h1")[0];
    if(!resetFocusItem) return;

    const extraContentSpan = document.getElementById("extraScreenReaderContent");
    extraScreenReaderContent = extraScreenReaderContent || "";
    extraContentSpan.innerHTML = ` ${extraScreenReaderContent} View Loaded`;

    resetFocusItem.style.outline = "none";
    resetFocusItem.focus();
}