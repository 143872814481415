import {isObjectNullOrEmpty} from "../../Types/objectUtilities";
import {apiConstants} from "../apiConstants";
import {requestTypes} from "./requestTypes";
import {desistLogin} from "../../LocalStorage/storageUtilities";
import {catchError} from "./requestUtilities";


const ajaxRequestExport = (notificationFactory, isAuthorizedUrl) => {

    function copyPropertyIfExists(source, target, propertyName, throwErrorIfNull = false) {
        if (
            !isObjectNullOrEmpty(source) &&
            Object.prototype.hasOwnProperty.call(source, propertyName) &&
            source[propertyName] !== null &&
            source[propertyName] !== undefined
        )
            target[propertyName] = source[propertyName];
        else if (throwErrorIfNull) throw new Error(`Property ${propertyName} is required.`);
    }

    function getAjaxRequestObject(ajaxRequestOptions) {
        let ajaxRequestObject = {};

        ajaxRequestObject.crossDomain = true;

        switch (ajaxRequestOptions.type) {
            case requestTypes.GET:
                ajaxRequestObject.type = "GET";
                break;
            case requestTypes.POST:
                ajaxRequestObject.type = "POST";
                break;
            case requestTypes.PUT:
                ajaxRequestObject.type = "PUT";
                break;
            case requestTypes.DELETE:
                ajaxRequestObject.type = "DELETE";
                break;
            default:
                throw new Error("Need to specify http verb for request.");
        }

        copyPropertyIfExists(ajaxRequestOptions, ajaxRequestObject, "url", true);
        copyPropertyIfExists(ajaxRequestOptions, ajaxRequestObject, "contentType");
        copyPropertyIfExists(ajaxRequestOptions, ajaxRequestObject, "processData");
        copyPropertyIfExists(ajaxRequestOptions, ajaxRequestObject, "requestType");
        copyPropertyIfExists(ajaxRequestOptions, ajaxRequestObject, "xhrFields");
        copyPropertyIfExists(ajaxRequestOptions, ajaxRequestObject, "xhr");
        copyPropertyIfExists(ajaxRequestOptions, ajaxRequestObject, "data");
        copyPropertyIfExists(ajaxRequestOptions, ajaxRequestObject, "enctype");
        copyPropertyIfExists(ajaxRequestOptions, ajaxRequestObject, "dataType");
        copyPropertyIfExists(ajaxRequestOptions, ajaxRequestObject, "cache");

        return ajaxRequestObject;
    }

    async function readDataFromResponse(response, ajaxRequestOptions) {
        if (response.status === 204) return;
        return ajaxRequestOptions.dataType === 'text' ? await response?.text() : await response?.json();
    }

    function configureDefaultSuccessCallback(ajaxRequestObject, ajaxRequestOptions, resolve) {
        if (typeof ajaxRequestOptions.successCallback !== "function") {
            ajaxRequestObject.success = async function (response) {
                if (ajaxRequestOptions.successMessage) {
                    notificationFactory.createSuccess(ajaxRequestOptions.successMessage).notify();
                }
                let data = null;

                if (response.ok && response.headers.get('content-length') !== '0') {
                    data = readDataFromResponse(response, ajaxRequestOptions);
                }
                resolve(data);
            };
        } else {
            ajaxRequestObject.success = async function (response) {
                const data = readDataFromResponse(response, ajaxRequestOptions);
                ajaxRequestOptions.successCallback(data, resolve, notificationFactory);
            };
        }
        return ajaxRequestObject;
    }

    function configureDefaultErrorCallback(ajaxRequestObject, ajaxRequestOptions, reject) {
        if (typeof ajaxRequestOptions.errorCallback !== "function") {
            ajaxRequestObject.error = function (errors) {

                const errorMessageNotification = (errorMessages) => {
                    const notifier = ajaxRequestOptions.notifyOnError
                        ? notificationFactory.createErrorsFromXhrObject(errorMessages)
                        : notificationFactory.createNone();
                    notifier.notify();
                }

                if (typeof errors.text === "function") {
                    errors.text().then(errorMessageNotification);
                } else {
                    errorMessageNotification(errors);
                }

                reject(errors);
            };
        } else {
            ajaxRequestObject.error = function (error) {
                ajaxRequestOptions.errorCallback(error, reject, notificationFactory);
            };
        }
        return ajaxRequestObject;
    }

    function configure401and404ErrorCallback(ajaxRequestObject, ajaxRequestOptions, reject) {
        let originalRequestObject = {};
        Object.assign(originalRequestObject, ajaxRequestObject);
        ajaxRequestObject.error = function (response) {
            if (response.status === 401 || response.status === 404) {
                sendRequest({
                    url: isAuthorizedUrl,
                    contentType: apiConstants.contentType.JSON,
                    type: "GET",
                    success: function () {
                        const badRouteBecauseIsAuthorized = !response.ok;
                        if (badRouteBecauseIsAuthorized) {
                            originalRequestObject.error(`{"responseText": "Contact technical support."}`);
                        } else {
                            desistLogin();
                            window.location = `${apiConstants.apiRedirectPath}`;
                        }
                    },
                    error: function () {
                        desistLogin();
                        window.location = `${apiConstants.apiRedirectPath}`;
                    },
                }, reject).then(() => {
                });
            } else {
                originalRequestObject.error(response);
            }
        }
        return ajaxRequestObject;
    }

    async function sendRequest(ajaxRequestOptions, reject) {
        try {
            let response = await fetch(ajaxRequestOptions.url, {
                method: ajaxRequestOptions.type,
                headers: {
                    'Content-Type': ajaxRequestOptions.contentType || 'application/json',
                    // Add authorization headers if needed
                },
                body: ajaxRequestOptions.type !== 'GET' && ajaxRequestOptions.data ? (typeof ajaxRequestOptions.data === 'object' ? JSON.stringify(ajaxRequestOptions.data) : ajaxRequestOptions.data) : null,
                credentials: 'include'

            });

            if (!response.ok) {
                ajaxRequestOptions.error(response);
            } else {
                ajaxRequestOptions.success(response);
            }

        } catch (error) {
            catchError(error);
            reject(error);
            throw error;
        }
    }

    function send(ajaxRequestOptions) {
        return new Promise((resolve, reject) => {
            let ajaxRequestObject = getAjaxRequestObject(ajaxRequestOptions);

            ajaxRequestObject = configureDefaultSuccessCallback(ajaxRequestObject, ajaxRequestOptions, resolve);
            ajaxRequestObject = configureDefaultErrorCallback(ajaxRequestObject, ajaxRequestOptions, reject);
            ajaxRequestObject = configure401and404ErrorCallback(ajaxRequestObject, ajaxRequestOptions, reject);

            sendRequest(ajaxRequestObject, reject).then();
        });

    }

    return {send};
};

export default ajaxRequestExport;