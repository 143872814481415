import React from "react";
import {PasswordField} from "../Inputs";
import PropTypes from "prop-types";

export const UserDetailsPasswordFields = ({
                         enablePasswordFields,
                         userDetailsForm,
                     }) => {
    return         <>
        <PasswordField
            {...userDetailsForm.password}
            disabled={!enablePasswordFields}
            helpText={`<p>Password must be 6 characters or longer, have at least one capital letter, one number, and one non-alphanumeric character.</p>`}
        />
        <PasswordField
            {...userDetailsForm.confirmPassword}
            disabled={!enablePasswordFields}
        />
    </>
}

UserDetailsPasswordFields.propTypes = {
    enablePasswordFields: PropTypes.bool.isRequired,
    userDetailsForm: PropTypes.object.isRequired,
};