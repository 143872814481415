import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import {isArrayNullOrEmpty} from "../../../Utilities/Types/arrayUtilities";

export const NotificationContext = createContext([]);
export const useNotificationContext = () => useContext(NotificationContext);

export const NotificationContextProvider = ({
                                                children
                                            }) => {
    const [errors, setErrors] = useState([]);
    const [infos, setInfos] = useState([]);
    const [successes, setSuccesses] = useState([]);
    const [warnings, setWarnings] = useState([]);

    function setNotificationBatchApiResults(results, successMessage, partialSuccessMessage) {
        const warningMessage = results.errors.join(`<br/>`);

        if (isArrayNullOrEmpty(results.errors) && results.numberUpdated > 0)
            setSuccesses(successMessage);
        else if (results.numberUpdated > 0 && results.errors.length > 0) {
            setSuccesses(partialSuccessMessage);
            setWarnings(warningMessage);
        } else{
            setWarnings(warningMessage);
        }
    }


    return (
        <NotificationContext.Provider
            value={{
                errors,
                infos,
                setErrors,
                setInfos,
                setNotificationBatchApiResults,
                setSuccesses,
                setWarnings,
                successes,
                warnings
        }}>
            {children}
        </NotificationContext.Provider>
    );
};


NotificationContextProvider.propTypes = {
    children: PropTypes.any
};