import {getStudentApprovalStatusName, STUDENT_STATUS} from "./studentConstants";

export const studentDetailsModel = ({
                                        certification,
                                        dateOfBirth,
                                        distanceVisualAcuityLeft,
                                        distanceVisualAcuityRight,
                                        districtId,
                                        educationalFacility,
                                        eligibility,
                                        fimcStudentId,
                                        firstName,
                                        gradeLevel,
                                        gradeAgeConfirmation,
                                        hasHomeSchoolRequirementAgreement,
                                        hasInstructionalHours,
                                        hasNeurologicalImpairment,
                                        hasSignedParentalConsent,
                                        hasVisualImpairment,
                                        isActive,
                                        isDualSensoryImpaired,
                                        isEligible = false,
                                        isFaviRegistered,
                                        isFlviRegistered,
                                        language,
                                        lastName,
                                        middleName,
                                        noteToFimc,
                                        otherReadingMedium,
                                        overrideCheck = false,
                                        peripheralFieldLoss,
                                        primaryReadingMedium,
                                        secondaryReadingMedium,
                                        studentApprovalStatus,
                                        studentChangeLogs = [],
                                        studentId,
                                        studentInactiveReason,
                                        studentStatus,
                                        understandsNoAphAccess,
                                        understandsNotCertified,
                                        userId,
                                        writtenPlan,
                                        dateCreatedUtc,
                                        dateModifiedUtc
                                    } = {}) => ({
    certification,
    dateOfBirth,
    distanceVisualAcuityLeft,
    distanceVisualAcuityRight,
    districtId,
    educationalFacility,
    eligibility: eligibility || ``,
    fimcStudentId,
    firstName,
    gradeLevel,
    gradeAgeConfirmation,
    hasHomeSchoolRequirementAgreement,
    hasInstructionalHours,
    hasNeurologicalImpairment,
    hasSignedParentalConsent,
    hasVisualImpairment,
    isActive,
    isDualSensoryImpaired,
    isEligible,
    isFaviRegistered,
    isFlviRegistered,
    language,
    lastName,
    middleName: middleName || ``,
    noteToFimc,
    otherReadingMedium,
    overrideCheck,
    peripheralFieldLoss,
    primaryReadingMedium,
    secondaryReadingMedium,
    studentApprovalStatus,
    studentApprovalStatusName: getStudentApprovalStatusName(studentApprovalStatus),
    studentChangeLogs,
    studentId,
    studentInactiveReason,
    studentStatus: studentStatus || STUDENT_STATUS.Active.id,
    understandsNoAphAccess,
    understandsNotCertified,
    userId,
    writtenPlan,
    dateCreatedUtc,
    dateModifiedUtc
});

export const logEntryModel = ({
                                  currentApprovalStatus,
                                  editLog,
                                  patronFullName,
                                  dateOfChange
                              } = {}) => ({
    currentApprovalStatus,
    editLog,
    patronFullName,
    dateOfChange
});