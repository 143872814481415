import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {GridCell} from "../../../Components/Grid";
import {ButtonIconHistory} from "../../../Components/Inputs/Buttons/ButtonIcon/ButtonIcon";
import {catchError} from "../../../Utilities/Api";
import GridTable from "../../../Components/Grid/GridTable";
import {allow, policyEvents} from "../../../Components/Authorize";
import {isArrayNullOrEmpty} from "../../../Utilities/Types/arrayUtilities";
import {logEntryModel} from "../studentDetailsFactory";

export const StudentRegistrationFormChangeLog = ({
                                                     children,
                                                     fieldName,
                                                     isEditing,
                                                     inputForm
                                                 }) => {
    const [fieldChanges, setFieldChanges] = useState([]);
    const [showChangeLog, setShowChangeLog] = useState(false);

    const changeLog = inputForm.studentChangeLogs.value;
    const canViewHistory = allow(policyEvents.VIEW_STUDENT_HISTORY);

    function handleHistoryButtonClick(event) {
        event.preventDefault();
        setShowChangeLog(!showChangeLog);
    }

    const parseChangeLog = (fieldName, changeLog) => {
        const lowerCaseFieldName = fieldName.toLowerCase();
        return changeLog.reduce((entries, logEntry) => {
            if (logEntry) {
                try {
                    const logEntryData = logEntryModel({...logEntry});
                    const editLog = JSON.parse(logEntryData.editLog);
                    const matchingField = Object.keys(editLog).find(key => key.toLowerCase() === lowerCaseFieldName);
                    if (matchingField) {
                        const gridRow =
                            <tr>
                                <td>{editLog[matchingField]}</td>
                                <td>{logEntryData.dateOfChange}</td>
                                <td>{logEntryData.patronFullName}</td>
                            </tr>;
                        entries.push(gridRow);
                    }
                } catch (error) {
                    catchError("Error parsing JSON in change log entry:", error);
                }
            }
            return entries;
        }, []);
    }

    useEffect(() => {
        if (fieldName && changeLog)
            setFieldChanges(parseChangeLog(fieldName, changeLog));
    }, [fieldName, changeLog]);


    return <>
        <GridCell>
            {
                canViewHistory && isEditing &&
                <div className={`margin--right`}>
                    {
                        !isArrayNullOrEmpty(fieldChanges) &&
                        <ButtonIconHistory
                            inputDescription={`History for ${fieldName}`}
                            onClick={handleHistoryButtonClick}
                        />
                    }
                </div>
            }
            {
                children
            }
            {
                canViewHistory && showChangeLog && isEditing && !isArrayNullOrEmpty(fieldChanges) &&
                <GridTable parentClass={`margin--allNone`}>
                    <thead>
                    <tr>
                        <th>Previous Value</th>
                        <th>Date of Change</th>
                        <th>Patron Responsible for Change</th>
                    </tr>
                    </thead>
                    <tbody>
                    {fieldChanges.map((row, index) =>
                        <React.Fragment key={index}>
                            {row}
                        </React.Fragment>
                    )}
                    </tbody>
                </GridTable>
            }
        </GridCell>
    </>;
};

StudentRegistrationFormChangeLog.propTypes = {
    children: PropTypes.any.isRequired,
    fieldName: PropTypes.string.isRequired,
    isEditing: PropTypes.bool.isRequired,
    inputForm: PropTypes.object.isRequired
};
