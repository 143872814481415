import PropTypes from "prop-types";
import React from "react";
import {PageNavigation, PageTitle} from "../../Components/Landmarks";
import {locations} from "../../Utilities/Location";
import {LayoutSmall} from "../../Components/Layout/";
import hero from "../../images/PageNotFound.png";

export const NotFoundFactory = ({
                                    header = "Page Not Found",
                                    message = "Something went wrong. Please try again.",
                                    redirect,
                                }) => {

    const handlePrimaryClick = () => redirect(locations.LANDING.path);

    return (
        <LayoutSmall className={`text-center`}>
            <PageTitle h1={header}/>
            {
                message &&
                <p className="text-center">{message}</p>
            }
            <LayoutSmall className={`margin--all`}>
                <img src={hero} alt="Pages exploding through book cover"/>
            </LayoutSmall>
            <PageNavigation
                handlePrimaryClick={handlePrimaryClick}
                labelPrimary={`Return Home`}
            />
        </LayoutSmall>
    );
};

export const NotFoundContainer = (props) => <NotFoundFactory {...props} />;

NotFoundFactory.propTypes = {
    header: PropTypes.string,
    message: PropTypes.string,
    redirect: PropTypes.func.isRequired,
};

