import * as policyEvents from "./policyEventsConstants";
import {ROLES} from "./roleConstants";

export class DefaultPolicy {
    constructor(roleId) {
        this._policy = {};
        this._roleId = roleId;

        this.initializePolicy();
    }

    permit(policyEvent) {
        if (!policyEvent)
            return false;

        if (this._policy[policyEvent])
            return this._policy[policyEvent](this._roleId);

        return false;
    }

    initializePolicy() {
        this._policy[policyEvents.VIEW] = function (roleId) {
            return roleId !== ROLES.Patron;
        };
        this._policy[policyEvents.ADVANCE_SEARCH] = function (roleId) {
            return roleId === ROLES.SystemAdmin || roleId === ROLES.FimcAdmin;
        };
        this._policy[policyEvents.APPROVE_USERS] = function (roleId) {
            return roleId === ROLES.SystemAdmin || roleId === ROLES.FimcAdmin || roleId === ROLES.Admin
                || roleId === ROLES.EseDirector || roleId === ROLES.Delegate;
        };
        this._policy[policyEvents.DELEGATE_USERS] = function (roleId) {
            return roleId === ROLES.FimcAdmin || roleId === ROLES.EseDirector || roleId === ROLES.Admin;
        };
        this._policy[policyEvents.APPROVE_STUDENTS] = function (roleId) {
            return roleId === ROLES.SystemAdmin || roleId === ROLES.FimcAdmin;
        };
        this._policy[policyEvents.DELETE] = function (roleId) {
            return roleId === ROLES.SystemAdmin || roleId === ROLES.FimcAdmin;
        };
        this._policy[policyEvents.VIEW_STUDENT_HISTORY] = function (roleId) {
            return roleId === ROLES.SystemAdmin || roleId === ROLES.FimcAdmin;
        };
        this._policy[policyEvents.MANAGE_CATALOG] = function (roleId) {
            return roleId === ROLES.SystemAdmin || roleId === ROLES.FimcAdmin;
        };
        this._policy[policyEvents.VIEW_STUDENTS] = function (roleId) {
            return roleId === ROLES.StudentViewer || roleId === ROLES.SystemAdmin || roleId === ROLES.FimcAdmin || roleId === ROLES.Admin
            || roleId === ROLES.EseDirector || roleId === ROLES.Delegate;
        };
    }
}