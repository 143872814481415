import React from "react";
import PropTypes from "prop-types";
import passwordApi from "./passwordApi";
import {locations} from "../../Utilities/Location";
import PasswordRequestView from "./PasswordRequestView";
import {PageTitle} from "../../Components/Landmarks";
import LayoutHero from "../../Components/Layout/LayoutHero";

export const PasswordRequestContainer = ({
                                            handleApiCall,
                                            isLoading,
                                            setWarnings,
                                            redirect,
                                        }) => {

    const handleEmailAsync = async (email) => {
        return handleApiCall(() => passwordApi.forgotPassword(email),
            () => redirect(locations.SIGN_IN.path));
    };

    return (
        <LayoutHero>
            <PageTitle h1={locations.FORGOT_PASSWORD.label}/>
            <PasswordRequestView
                handleEmailAsync={handleEmailAsync}
                isLoading={isLoading}
                setWarnings={setWarnings}
            />
        </LayoutHero>
    );
};

PasswordRequestContainer.propTypes = {
    handleApiCall: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    redirect: PropTypes.func.isRequired,
    setWarnings: PropTypes.func.isRequired,
};
