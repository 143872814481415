import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {isArrayNullOrEmpty} from "../../../Utilities/Types/arrayUtilities";
import {PageTitle} from "../../../Components/Landmarks";
import {GridCell, gridConstants, GridContainer} from "../../../Components/Grid";
import {Button, ButtonMock} from "../../../Components/Inputs";
import StudentApprovalView from "./StudentApprovalView";
import {isNullOrUndefined} from "../../../Utilities/commonUtilities";
import StudentApprovalApi from "./StudentApprovalApi";
import {Allow, policyEvents} from "../../../Components/Authorize";
import {
    studentModel
} from "./studentApprovalFactory";
import {locations} from "../../../Utilities/Location";
import {
    STUDENT_APPROVAL_STATUS_FOR_FIMC_ADMIN_SEARCH,
    STUDENT_APPROVAL_STATUS_FOR_SEARCH
} from "../studentConstants";
import StudentApprovalSearchFormView from "./StudentApprovalSearchFormView";
import {isFimcAdmin} from "../../../Utilities/LocalStorage/storageUtilities";

export const StudentApprovalContainer = ({
                                             districts,
                                             handleApiCall,
                                             handleSetStudentSearchCriteria,
                                             isLoading,
                                             redirect,
                                             //setNotificationBatchApiResults,
                                             studentSearchCriteria,
                                             userId
                                         }) => {

    const [students, setStudents] = useState([]);
    const filteredStudents = studentSearchCriteria !== null && studentSearchCriteria.ApplySorting(studentSearchCriteria, students);

    const handleAddStudentClick = (event) => {
        event.preventDefault();
        redirect(locations.REGISTER_STUDENT.path);
    };

   /*
    const onApprovalStatusClick = (studentId, value) => {
        handleSetStudentSearchCriteria({...studentSearchCriteria, sortByColumn: ``, loadSearchResults: false})

        setStudents(students.map(student =>
            student.studentId === studentId
                ? {
                    ...student,
                    studentApprovalStatus: convertToNumber(value),
                    studentApprovalStatusName: getStudentApprovalStatusName(convertToNumber(value))
                }
                : student
        ));
    };

   const handleApproveAllClick = (event) => {
        event.preventDefault();
        if (studentSearchCriteria)
            if (confirm(`All student Approval Statuses will be changed to Approved. Are you sure you want to continue?`))
                setStudents(students.map(student => {
                    const studentToApprove = filteredStudents.find(filteredStudent => filteredStudent.studentId === student.studentId);
                    return studentToApprove ? {
                        ...student,
                        studentApprovalStatus: STUDENT_APPROVAL_STATUS.Approved,
                        studentApprovalStatusName: getStudentApprovalStatusName(STUDENT_APPROVAL_STATUS.Approved)
                    } : student;
                }));
    };

    const handleSaveClick = (event) => {
        event.preventDefault();

        const studentsForStatusUpdate = students
            .map(approveStudentModel);

        if (studentsForStatusUpdate.length > 0) {

            const approveAndSearchStudents = approveAndSearchStudentsModel({
                students: studentsForStatusUpdate,
                searchFilters: {...studentSearchCriteria, userId: userId},
            });

            handleApiCall(() => StudentApprovalApi.approveStudents(approveAndSearchStudents), loadStudentApprovalUpdateResults);
        }
    };

    const loadStudentApprovalUpdateResults = (result) => {
        const studentApprovalUpdateResult = studentApprovalUpdateResultsModel({...result});
        setStudents(studentApprovalUpdateResult.filteredStudents.map(studentModel));

        const suffix = studentApprovalUpdateResult.numberUpdated === 1 ? `` : `s`;
        setNotificationBatchApiResults(
            studentApprovalUpdateResult,
            apiConstants.successMessage.approval.APPROVAL_UPDATED,
            `Successfully updated the approval status of ${studentApprovalUpdateResult.numberUpdated} student${suffix}.`
        );
    };*/

    const loadStudentsResults = (result) => {
        const students = result.map(studentModel);
        setStudents(students);
    };

    const loadStudents = () => {
        handleApiCall(() => StudentApprovalApi.getStudentApprovalRequests({
            ...studentSearchCriteria,
            userId
        }), loadStudentsResults);
    };

    const buttonSharedSettings = {
        medium_columns: gridConstants.column.THREE,
        small_columns: gridConstants.column.SIX,
    };

    const noResults = isArrayNullOrEmpty(filteredStudents);

    useEffect(() => {
        if (isNullOrUndefined(districts) || districts.length === 0) return;

        if (studentSearchCriteria.loadSearchResults) {
            if(!isFimcAdmin() && studentSearchCriteria.studentApprovalStatus === STUDENT_APPROVAL_STATUS_FOR_FIMC_ADMIN_SEARCH.Deleted.toString()) {
                handleSetStudentSearchCriteria({
                    ...studentSearchCriteria,
                    studentApprovalStatus: STUDENT_APPROVAL_STATUS_FOR_SEARCH.PendingNeedInfo
                });
            }
            loadStudents();
        }

        // eslint-disable-next-line
    }, [districts, studentSearchCriteria]);

    if (!studentSearchCriteria) return null;

    return <>
        <PageTitle h1={`Manage Students`}/>

        <GridContainer className={`form__header`} hasMarginX isReverseOrder>
            <GridCell {...buttonSharedSettings}>
                <Button
                    disabled={isLoading}
                    isPrimary
                    label="Add Student"
                    name="btnAddStudent"
                    onClick={handleAddStudentClick}
                />
            </GridCell>
        </GridContainer>

        <StudentApprovalSearchFormView
            buttonSharedSettings={buttonSharedSettings}
            districts={districts}
            handleSetStudentSearchCriteria={handleSetStudentSearchCriteria}
            isLoading={isLoading}
            studentSearchCriteria={studentSearchCriteria}
        />

        <Allow policyEvent={policyEvents.APPROVE_STUDENTS}>
            <GridContainer isReverseOrder hasMarginX>
               {/* {
                    !noResults &&
                    <>
                        <GridCell  {...buttonSharedSettings}>
                            <Button
                                disabled={isLoading}
                                label="Save"
                                name="btnSave"
                                onClick={handleSaveClick}
                                isPrimary
                            />
                        </GridCell>
                        <GridCell {...buttonSharedSettings}>
                            <Button
                                disabled={isLoading}
                                label="Approve all"
                                name="btnApproveAll"
                                onClick={handleApproveAllClick}
                            />
                        </GridCell>
                    </>
                }*/}
                {
                    noResults &&
                    <GridCell><ButtonMock name={`placeholder`} label={`&nbsp;`}/></GridCell>
                }
            </GridContainer>
        </Allow>
        <StudentApprovalView
            handleSetStudentSearchCriteria={handleSetStudentSearchCriteria}
            redirect={redirect}
            students={filteredStudents}
            studentSearchCriteria={studentSearchCriteria}
        />
    </>
};

StudentApprovalContainer.propTypes = {
    districts: PropTypes.array,
    handleApiCall: PropTypes.func.isRequired,
    handleSetStudentSearchCriteria: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    redirect: PropTypes.func.isRequired,
    setNotificationBatchApiResults: PropTypes.func.isRequired,
    studentSearchCriteria: PropTypes.object,
    userId: PropTypes.string.isRequired,
}