import {
    apiConstants,
    config,
    requestOptions,
    requestTypes,
    sendRequest,
} from "../../Utilities/Api";

//************************************************** STANDARD API SERVER CALLS ****************************************

class serverQueueApi {
    static addToQueue(catalogFormatId)
    {
        return sendRequest(
            requestOptions(config.URL + `queue`,
                requestTypes.POST,
                JSON.stringify({catalogFormatId}),
                apiConstants.successMessage.queue.ADD,
            ),
        );
    }

    static removeFromQueue(queueItemId)
    {
        return sendRequest(
            requestOptions(config.URL + `queue/${queueItemId}`,
                requestTypes.DELETE,
                null,
                apiConstants.successMessage.queue.DELETE,
            ),
        );
    }

    static getQueue()
    {
        return sendRequest(
            requestOptions(config.URL + `queue`,
                requestTypes.GET,
            ),
        );
    }
}

const libraryQueueApi = config.environment.MODE === config.modes.SERVER ? serverQueueApi : serverQueueApi;
export default libraryQueueApi;