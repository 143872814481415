import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {PageTitle} from "../../Components/Landmarks";
import {LayoutFullBleed} from "../../Components/Layout";
import {LibraryCatalogItem} from "./LibraryCatalogItem";
import libraryCatalogApi from "./libraryCatalogApi";
import {catalogItemModel} from "./libraryFactory";
import {locations} from "../../Utilities/Location";
import {isArrayNullOrEmpty} from "../../Utilities/Types/arrayUtilities";

export const LibraryCatalogWhatsNewContainer = ({
                                            handleApiCall,
                                            redirect,
                                        }) => {
    const [resources, setResources] = useState([]);

    const handleEdit = (id) => {
        const path = locations.LIBRARY_ITEM.getLink(id).to;
        redirect(path);
    }

    useEffect(() => {
        const getResources = () => {
            handleApiCall(libraryCatalogApi.getWhatsNew, (results) => setResources(results));
        }

        getResources();
    }, []);

    return <>
        <PageTitle h1={`Library Catalog`}/>

        <LayoutFullBleed className={`catalog`}>
            <header>
                <h2>What&apos;s New</h2>
            </header>

            {
                resources.map((resource) => {
                    const item = catalogItemModel(resource);
                    const handleClickEdit = () => handleEdit(item.catalogId);
                    return <LibraryCatalogItem
                        key={item.catalogId}
                        handleClickEdit={handleClickEdit}
                        {...item}
                    />;
                })
            }
            {
                isArrayNullOrEmpty(resources) &&
                <p className={`text-center`}>No results.</p>
            }

        </LayoutFullBleed>
    </>;
};

LibraryCatalogWhatsNewContainer.propTypes = {
    handleApiCall: PropTypes.func.isRequired,
    redirect: PropTypes.func.isRequired,
};