import React from "react";
import PropTypes from "prop-types";
import {
    Button,
    checkAndSetError,
    clearInputFormErrorFields,
    convertInputFormToObject,
    DateTimePickerField,
    InputForm,
    inputFormIsValid,
    SelectField,
    TextArea,
    TextField,
    useInputForm,
} from "../../Components/Inputs";
import {GridCell, gridConstants} from "../../Components/Grid";
import {GRADE_LEVEL_LIST} from "./libraryConstants";
import {resourceModel} from "./libraryFactory";
import {isStartBeforeEnd} from "../../Utilities/Types/dateUtilities";

export const LibraryItemView = ({
                                    handleSaveResource,
                                    handleReturn,
                                    item,
                                }) => {
    const itemForm = useInputForm(resourceModel(item));

    const handleSubmitClick = () => {
        clearInputFormErrorFields(itemForm);

        const isStartDateAfterEndDate = !isStartBeforeEnd(itemForm.whatsNewStartDate.value, itemForm.whatsNewEndDate.value)
        const verifyStartAndEndDate = itemForm.whatsNewStartDate.value && itemForm.whatsNewEndDate.value;

        if (verifyStartAndEndDate && isStartDateAfterEndDate) {
            itemForm.whatsNewStartDate.setError("Start date must be before end date.");
            itemForm.whatsNewEndDate.setError("End date must be after start date.");
        }

        checkAndSetError([
            itemForm.title,
        ]);

        if (inputFormIsValid(itemForm)) {
            const dirtyResource = convertInputFormToObject(itemForm);
            handleSaveResource(dirtyResource);
        }
    }

    return <>
        <InputForm name={`itemForm`}>
            <TextField {...itemForm.title} medium_columns={gridConstants.column.SIX}/>
            <TextField {...itemForm.author} medium_columns={gridConstants.column.SIX}/>
            <TextArea {...itemForm.description} medium_columns={gridConstants.column.SIX}/>
            <SelectField {...itemForm.gradeLevel} medium_columns={gridConstants.column.SIX} options={GRADE_LEVEL_LIST}/>
            <DateTimePickerField {...itemForm.copyright} medium_columns={gridConstants.column.SIX}/>

            <hr/>

            <TextField {...itemForm.isbn10} medium_columns={gridConstants.column.SIX}/>
            <TextField {...itemForm.isbn13} medium_columns={gridConstants.column.SIX}/>
            <TextField {...itemForm.previewIsbn} medium_columns={gridConstants.column.SIX}/>

            <hr/>
            <DateTimePickerField {...itemForm.whatsNewStartDate} medium_columns={gridConstants.column.SIX}/>
            <DateTimePickerField {...itemForm.whatsNewEndDate} medium_columns={gridConstants.column.SIX}/>

            <hr/>
            <GridCell className={`text-center`}>
                <Button isPrimary isCollapsed label={'Save'} name={`btnSubmit`}
                        onClick={handleSubmitClick}/>
                <Button isCollapsed label={'Cancel'} name={`btnCancel`} onClick={handleReturn}/>
            </GridCell>
        </InputForm>
    </>;
};

LibraryItemView.propTypes = {
    handleSaveResource: PropTypes.func.isRequired,
    handleReturn: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
};