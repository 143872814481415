import PropTypes from "prop-types";
import React from "react";

export const LayoutFullBleed = ({
                                    children,
                                    className = "",
                                }) => {

    return <>
        <div className={`l-fullbleed l-fullbleed--white ${className}`}>
            <div className={`l-fullbleed__background`}></div>
            <div><br/></div>
            {children}
        </div>
    </>;
};

LayoutFullBleed.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
};