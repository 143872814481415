import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Editor, EditorTools, EditorUtils} from '@progress/kendo-react-editor';
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {HtmlDisplay} from "../../Display";
import {ButtonIconExit} from "../";
import {DisplayLayout} from "../DisplayLayout";
import {createFakeEvent} from "../inputUtility";
import {validateTelerikHtmlConversion, convertToTelerikHtml, createToolsLayout} from "./textAreaEditorUtility";

const AbstractTextAreaEditor = ({
                                    disabled,
                                    error,
                                    isClickable = false,
                                    label,
                                    medium_columns,
                                    name,
                                    onChange,
                                    value = "",
                                }) => {
    const [rteValue, setRteValue] = useState(convertToTelerikHtml(value));
    const [html, setHtml] = useState(validateTelerikHtmlConversion(value));
    const [isEditing, setIsEditing] = useState(false);

    const handleChange = (event) => {
        const newValue = event.html;
        const html = validateTelerikHtmlConversion(newValue);
        setRteValue(newValue);
        setHtml(html);
        onChange(createFakeEvent(html, name));
    };

    const handleClick = isClickable ? () => setIsEditing(true) : null;

    const contentWrapToolSettings = {
        nodeType: 'div',
        props: {
            data: [
                {text: "Additional", class: "content__resources"},
                {text: "Handouts", class: "content__handouts"},
                {text: "Products", class: "content__products"}
            ],
            textField: "text",
            dataItemKey: "class"
        }
    };

    const ContentWrapTool = (props) => AbstractContentWrapTool({...props, settings: contentWrapToolSettings});

    const customFormatBlock = EditorTools.createFormatBlockDropDownList({
        commandName: "Custom",
        defaultItem: "p",
        items: [
            {text:"None", value: "p"},
            {text:"Paragraph", value: "h6"},
            {text:"Heading 2", value: "h2"},
            {text:"Heading 3", value: "h3"},
            {text:"Heading 4", value: "h4"},
        ]
    });

    const customTools = createToolsLayout([customFormatBlock, ContentWrapTool]);

    useEffect(() => {
        if (!value || !isClickable)
            setIsEditing(true);
    }, []);

    useEffect(() => {
        if(value !== html)
        {
            setHtml(validateTelerikHtmlConversion(value));
            setRteValue(convertToTelerikHtml(value));
        }
    }, [value]);

    return (
        <DisplayLayout
            error={error}
            label={label}
            medium_columns={medium_columns}
            name={name}
        >
            {
                !disabled &&
                isEditing &&
                <div className={`TelerikRichTextEditor`}>
                    {
                        !!html && isClickable &&
                        <ButtonIconExit
                            inputDescription={`exit for ${label}`}
                            onClick={() => setIsEditing(false)}
                        />
                    }
                    <Editor
                        contentStyle={{ height: 160 }}
                        defaultEditMode={"div"}
                        onChange={handleChange}
                        tools={customTools}
                        value={rteValue}
                    />
                </div>
            }
            {
                (disabled || !isEditing) &&
                <HtmlDisplay
                    disabled={disabled}
                    className={`input__text input__text--wrap`}
                    html={value}
                    onClick={handleClick}
                />
            }
        </DisplayLayout>
    );
};

export const TextAreaEditor = props => <AbstractTextAreaEditor {...props} />;
export const TextAreaEditorClickable = props => <AbstractTextAreaEditor {...props} isClickable/>;

const sharedProps = {
    disabled: PropTypes.bool,
    error: PropTypes.string,
    label: PropTypes.string.isRequired,
    medium_columns: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
};

AbstractTextAreaEditor.propTypes = {
    ...sharedProps,
    isClickable: PropTypes.bool,
};

TextAreaEditorClickable.prototypes = {
    ...sharedProps,
    isClickable: PropTypes.bool,
};

TextAreaEditor.propTypes = {
    ...sharedProps,
};


const AbstractContentWrapTool = (props) => {
    const { settings, view } = props;
    const nodeType = view && view.state.schema.nodes[settings.nodeType];
    const canInsert = view && EditorUtils.canInsert(view.state, nodeType);

    const handleChange = (event) => {
        const classValue = event.target.value.class;
        EditorUtils.insertNode(view, nodeType.create({class: classValue}, view.state.selection.content().content))
    };

    return (
        <DropDownList
            onChange={handleChange}
            disabled={!canInsert}
            {...settings.props}
        />
    );
}