import {getApprovalStatusName} from "../Constants/approvalConstants";

export const signInModel = ({
                                email,
                                password,
                            } = {}) => ({
    email,
    password,
});

export const userModel = ({
                              userId,
                              firstName,
                              middleName,
                              lastName,
                              fullName,
                              districtId,
                              districtName,
                              email,
                              password,
                              confirmPassword,
                              isActive,
                              createDate,
                              updatePasswordToken,
                              updatePasswordExpiration,
                              patronRole,
                              patronRoleName,
                              patronSubType,
                              charterType,
                              organization,
                              approvalStatus,
                              roleId,
                          } = {}) => ({
    userId,
    firstName: firstName || "",
    middleName: middleName || "",
    lastName: lastName || "",
    fullName: fullName || "",
    districtId: districtId,
    districtName: districtName || "",
    email: email,
    password: password,
    confirmPassword: confirmPassword,
    isActive: isActive,
    createDate: createDate || null,
    updatePasswordToken,
    updatePasswordExpiration,
    patronRole,
    patronRoleName,
    patronSubType,
    charterType,
    organization,
    approvalStatus,
    approvalStatusName: getApprovalStatusName(approvalStatus),
    roleId
});

