import React from "react";
import PropTypes from "prop-types";
import {ButtonIconEdit, SelectField} from "../../../Components/Inputs";
import {allow, policyEvents} from "../../../Components/Authorize";
import {HtmlDisplay} from "../../../Components/Display";
import {getApprovalStatusList} from "../../Constants/approvalConstants";
import {locations} from "../../../Utilities/Location";

const UserApprovalRow = ({onApprovalStatusClick, redirect, user}) => {

    const handleOnChangeApprovalStatus = (event, userId) => {
        event.preventDefault();
        onApprovalStatusClick(userId, event.target.value);
    };

    const canApprove = allow(policyEvents.APPROVE_USERS);

    const handleEditUserDetailsClick = (event, userId) => {
        event.preventDefault();
        redirect(locations.USER_PROFILE.getPath(userId));
    };

    return (
        <tr id={user.userId} data-testid={user.userId}>
            <td>
                {user.email}
            </td>
            <td>
                {user.firstName}
            </td>
            <td>
                {user.lastName}
            </td>
            <td>
                {user.districtName}
            </td>
            <td>
                {user.patronRoleName}
            </td>
            <td>
                {user.organization}
            </td>
            <td className={user.approvalStatusName}>
            {
                canApprove ? 
                    user.isActive ? 
                        <SelectField
                            includeDefaultOption={false}
                            label={`Approval Status for ${user.firstName}`}
                            name={`approval-status-${user.userId}`}
                            onChange={(event) => handleOnChangeApprovalStatus(event, user.userId)}
                            options={getApprovalStatusList()}
                            showOnlyForSr={true}
                            value={user.approvalStatus}
                        />
                     : 
                        <HtmlDisplay
                            html={getApprovalStatusList().find(f => f.value === user.approvalStatus)?.text || ``}
                            name={`approval-status-${user.userId}`}
                        />
                    
                 : 
                    <HtmlDisplay
                        html={getApprovalStatusList().find(f => f.value === user.approvalStatus)?.text || ``}
                        name={`approval-status-${user.userId}`}
                    />
                
            }
            </td>
            <td className="align-center">
                <ButtonIconEdit
                    inputDescription={`for ${user.firstName} ${user.lastName}`}
                    onClick={(event) => handleEditUserDetailsClick(event, user.userId)}
                />
            </td>
        </tr>
    );
};

UserApprovalRow.PropType = {
    onApprovalStatusClick: PropTypes.func.isRequired,
    redirect: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
}

export default UserApprovalRow;