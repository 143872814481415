import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {PageTitle} from "../../Components/Landmarks";
import {LayoutFullBleed} from "../../Components/Layout";
import {LibraryCatalogItem} from "./LibraryCatalogItem";
import {catalogQueueModel} from "./libraryFactory";
import {isArrayNullOrEmpty} from "../../Utilities/Types/arrayUtilities";
import libraryQueueApi from "./libraryQueueApi";

export const LibraryQueueContainer = ({
                                          handleApiCall,
                                      }) => {
    const [queue, setQueue] = useState([]);

    const getQueue = () => {
        handleApiCall(libraryQueueApi.getQueue, (results) => setQueue(results));
    }

    const removeFromQueue = (queuedItemId) => {
        handleApiCall(() => libraryQueueApi.removeFromQueue(queuedItemId))
            .then(getQueue);
    }

    useEffect(() => {
        getQueue();
    }, []);

    return <>
        <PageTitle h1={`Cart`}/>

        <LayoutFullBleed className={`catalog`}>
            <header>
                <h2>Pending</h2>
            </header>

            {
                queue &&
                queue.map((queuedItem) => {
                    const item = catalogQueueModel(queuedItem.catalogItem);
                    const queueItemId = queuedItem.queueItemId;
                    const handleDelete = () => removeFromQueue(queueItemId)
                    return <LibraryCatalogItem
                        key={queueItemId}
                        {...item}
                        handleDelete={handleDelete}
                    />;
                })
            }
            {
                isArrayNullOrEmpty(queue) &&
                <p className={`text-center`}>No results.</p>
            }

        </LayoutFullBleed>
    </>;
};

LibraryQueueContainer.propTypes = {
    handleApiCall: PropTypes.func.isRequired,
};