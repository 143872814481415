import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {PageTitle} from "../../Components/Landmarks";
import {LibraryItemView} from "./LibraryItemView";
import {locations} from "../../Utilities/Location";
import libraryCatalogApi from "./libraryCatalogApi";
import {emptyResourceModel} from "./libraryFactory";

export const LibraryItemContainer = ({
                                         handleApiCall,
                                         catalogItemId,
                                         redirect,
                                     }) => {
    const [item, setItem] = useState();

    const handleReturn = () => redirect(locations.LIBRARY_CATALOG.path);

    const handleSaveResource = async (resource) => {
        return handleApiCall(() => libraryCatalogApi.saveResource(resource),
            handleReturn);
    };

    useEffect(() => {
        const getResource = () => {
            handleApiCall(() => libraryCatalogApi.getResource(catalogItemId), (results = emptyResourceModel) => setItem(results));
        }

        if (catalogItemId && !item)
            getResource();

    }, [catalogItemId]);

    if (!item) return <></>;

    return <>
        <PageTitle h1={`Library Item`}/>
        <LibraryItemView
            handleSaveResource={handleSaveResource}
            handleReturn={handleReturn}
            item={item}
        />
    </>;
};

LibraryItemContainer.propTypes = {
    handleApiCall: PropTypes.func.isRequired,
    catalogItemId: PropTypes.string.isRequired,
    redirect: PropTypes.func.isRequired,
};