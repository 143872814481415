
export const approveUserModel = ({
                                     userId,
                                     approvalStatus,
                                     firstName,
                                     lastName
                                 } = {}) => ({
    id: userId,
    approvalStatus,
    firstName,
    lastName
});

export const userApprovalUpdateResultsModel = ({
                                                   errors,
                                                   numberUpdated,
                                                   usersPendingApproval,

                                               } = {}) => ({
    errors,
    numberUpdated,
    usersPendingApproval,
});