export function emptyFunction() {
}

export function returnTrue() {
    return true;
}

export function returnFalse() {
    return false;
}

export function handleApiCall(apiCall, thenCallback = emptyFunction, catchCallback = emptyFunction) {
    apiCall()
        .then((result) => {
            thenCallback(result);
        })
        .catch((error) => {
            catchCallback(error);
        });
}