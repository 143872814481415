import {
    apiConstants,
    config,
    requestOptions,
    requestTypes,
    sendRequest,
} from "../../Utilities/Api";
import {getLoggedInUser} from "../../Utilities/LocalStorage/storageUtilities";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerUserApi {
    static attemptLogin(signInModel) {
        return sendRequest(
            requestOptions(config.URL + "accounts/login/",
                requestTypes.POST,
                JSON.stringify(signInModel),
            ),
        );
    }

    static attemptLogout() {
        return sendRequest(
            requestOptions(config.URL + "accounts/logout/",
                requestTypes.POST,
                null,
                apiConstants.successMessage.user.LOGOUT
            ),
        );
    }

    static attemptRegister(registerModel) {
        let attemptRegisterOptions = requestOptions(config.URL + "accounts/register",
            requestTypes.POST,
            JSON.stringify(registerModel),
            apiConstants.successMessage.user.REGISTER
        );

        return sendRequest(attemptRegisterOptions);
    }

    static getUser()
    {
        const userId = getLoggedInUser();
        return sendRequest(
            requestOptions(config.URL + `users/${userId}`,
                requestTypes.GET,
            ),
        );
    }
}

const AccountApi = config.environment.MODE === config.modes.SERVER ? ServerUserApi : ServerUserApi;
export default AccountApi;