import PropTypes from "prop-types";
import React from "react";
import {GridCell, gridConstants, GridContainer} from "../Grid";
import {LinkButton} from "../Link";
import {locations} from "../../Utilities/Location";
import {getFullName, isLoggedIn} from "../../Utilities/LocalStorage/storageUtilities";
import {createMenuItem, ProfileMenu} from "../ProfileMenu";
import passwordApi from "../../Containers/Password/passwordApi";

export const HeaderNav = ({
                              handleApiCall,
                              showCatalogLink,
                              showDashboardLink,
                              showLandingLink,
                              showSignInLink,
                              showSignOutLink,
                              redirect,
                              user,
                          }) => {

    const isUserLoggedIn = isLoggedIn();

    const handleUpdatePassword = async (email) => {
        return handleApiCall(() => passwordApi.forgotPassword(email));
    };

    const profileLabel = isUserLoggedIn ? getFullName() : null;
    const menuItems = isUserLoggedIn ? [
        createMenuItem(`Update Profile`, () => redirect(locations.USER_PROFILE.getPath(user.userId))),
        createMenuItem(`Update Password`, () => handleUpdatePassword(user.email)),
        createMenuItem(`Sign out`, () => redirect(locations.SIGN_OUT.path)),

    ] : null;

    return (
        <nav className="headerNav">
            <GridContainer hasMarginX>
                <GridCell small_columns={gridConstants.column.SIX}>
                    {
                        showSignOutLink && isUserLoggedIn &&
                        <ProfileMenu menuItems={menuItems} profileLabel={profileLabel}/>
                    }
                </GridCell>
                <GridCell small_columns={gridConstants.column.SIX}>
                    {
                        showSignInLink &&
                        !isUserLoggedIn &&
                        <LinkButton {...locations.SIGN_IN.link} />
                    }
                    {
                        showLandingLink &&
                        <LinkButton {...locations.LANDING.link} />
                    }
                    {
                        showDashboardLink && isUserLoggedIn &&
                        <LinkButton {...locations.DASHBOARD.link} />
                    }
                    {
                        showCatalogLink && isUserLoggedIn &&
                        <LinkButton {...locations.LIBRARY_CATALOG.link} />
                    }
                </GridCell>
            </GridContainer>
        </nav>
    );
};

export const headerNavProps= {
    showCatalogLink: PropTypes.bool,
    showDashboardLink: PropTypes.bool,
    showLandingLink: PropTypes.bool,
    showSignInLink: PropTypes.bool,
    showSignOutLink: PropTypes.bool,
}

HeaderNav.propTypes = {
    ...headerNavProps,
    handleApiCall: PropTypes.func.isRequired,
    redirect: PropTypes.func,
    user: PropTypes.object,
};