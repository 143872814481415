import {
    apiConstants,
    config,
    mockResolveApiCall,
    requestOptions,
    requestTypes,
    sendRequest
} from "../../../Utilities/Api";
import {userData} from "../userMockData";

class ServerUserProfileApi {
    static getUserDetails(patronId, userId) {
        return sendRequest(
            requestOptions(config.URL + `patrons/${patronId}/${userId}`,
                requestTypes.GET,
            ),
        );
    }

    static editUserDetails(userDetailsModel, adminUserId) {
        return sendRequest(
            requestOptions(config.URL + `patrons/${adminUserId}`,
                requestTypes.PUT,
                JSON.stringify(userDetailsModel),
                apiConstants.successMessage.user.UPDATE
            ),
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************

class MockUserProfileApi {
    static getUserDetails() {
        return mockResolveApiCall(userData[0], "");
    }

    static editUserDetails() {
        return mockResolveApiCall("");
    }
}

const UserProfileApi = config.environment.MODE === config.modes.SERVER ? ServerUserProfileApi : MockUserProfileApi;
export default UserProfileApi;