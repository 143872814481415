import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {
    Button,
    checkAndSetError,
    clearInputFormErrorFields,
    convertInputFormToObject,
    InputForm,
    inputFormIsValid,
    reinitializeInputFormFields,
    TextField,
    useInputForm,
} from "../../Components/Inputs";
import {GridCell, gridConstants} from "../../Components/Grid";
import {vendorModel} from "./libraryFactory";

export const LibraryVendorView = ({
                                    handleSaveVendor,
                                    handleReturn,
                                    vendor,
                                }) => {
    const vendorForm = useInputForm(vendorModel(vendor));

    const handleSubmitClick = (event) => {
        event.preventDefault();
        clearInputFormErrorFields(vendorForm);

        checkAndSetError([
            vendorForm.name,
        ]);

        if (inputFormIsValid(vendorForm)) {
            const dirtyVendor = convertInputFormToObject(vendorForm);
            handleSaveVendor(dirtyVendor);
        }
    }

    useEffect(() => {
        if (vendor.vendorId !== vendorForm.vendorId.value)
            reinitializeInputFormFields(vendorForm, vendor);
    }, [vendor]);

    return <>
        <InputForm name={`vendorForm`}>
            <TextField {...vendorForm.name} medium_columns={gridConstants.column.SIX}/>
            <TextField {...vendorForm.email} medium_columns={gridConstants.column.SIX}/>
            <TextField {...vendorForm.contactName} medium_columns={gridConstants.column.SIX}/>
            <TextField {...vendorForm.phoneNumber} medium_columns={gridConstants.column.SIX}/>
            <TextField {...vendorForm.faxNumber} medium_columns={gridConstants.column.SIX}/>
            <TextField {...vendorForm.address} medium_columns={gridConstants.column.SIX}/>
            <TextField {...vendorForm.city} medium_columns={gridConstants.column.SIX}/>
            <TextField {...vendorForm.state} medium_columns={gridConstants.column.SIX}/>
            <TextField {...vendorForm.zipCode} medium_columns={gridConstants.column.SIX}/>
            <TextField {...vendorForm.website} medium_columns={gridConstants.column.SIX}/>

            <hr/>
            <GridCell className={`text-center`}>
                <Button isPrimary isCollapsed label={'Submit'} name={`btnSubmit`} onClick={handleSubmitClick}/>
                <Button isCollapsed label={'Cancel'} name={`btnCancel`} onClick={handleReturn}/>
            </GridCell>
        </InputForm>
    </>;
};

LibraryVendorView.propTypes = {
    handleSaveVendor: PropTypes.func.isRequired,
    handleReturn: PropTypes.func.isRequired,
    vendor: PropTypes.object.isRequired,
};